import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { APIurl, APIusersKey } from '../../variables/utils';

import auth from '../../auth';
import Cookies from 'js-cookie';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Router, Route, Redirect, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

// @material-ui/icons
import PermIdentity from '@material-ui/icons/PermIdentity';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Clearfix from 'components/Clearfix/Clearfix.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardAvatar from 'components/Card/CardAvatar.js';

import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js';

import avatar from 'assets/img/faces/marc.jpg';

const useStyles = makeStyles(styles);

export default function UserProfile() {
	// register form
	const [registerEmail, setregisterEmail] = React.useState('');
	const [registerEmailState, setregisterEmailState] = React.useState('');

	const [registerUserName, setregisterUserName] = React.useState('');
	const [registerUserNameState, setregisterUserNameState] = React.useState('');

	const [registerUserType, setregisterUserType] = React.useState('');
	const [registerUserTypeState, setregisterUserTypeState] = React.useState('');

	const [registerFirstName, setregisterFirstName] = React.useState('');
	const [registerFirstNameState, setregisterFirstNameState] = React.useState(
		''
	);

	const [registerLastName, setregisterLastName] = React.useState('');
	const [registerLastNameState, setregisterLastNameState] = React.useState('');

	const [registerAddress, setregisterAddress] = React.useState('');
	const [registerAddressState, setregisterAddressState] = React.useState('');

	const [registerTelephone, setregisterTelephone] = React.useState('');
	const [registerTelephoneState, setregisterTelephoneState] = React.useState(
		''
	);

	const [registerWeb, setregisterWeb] = React.useState('');
	const [registerWebState, setregisterWebState] = React.useState('');

	const [registerAbout, setregisterAbout] = React.useState('');
	const [registerAboutState, setregisterAboutState] = React.useState('');

	const [registerCompany, setregisterCompany] = React.useState('');
	const [registerCompanyState, setregisterCompanyState] = React.useState('');

	const [registerPosition, setregisterPosition] = React.useState('');
	const [registerPositionState, setregisterPositionState] = React.useState('');

	const [registerCity, setregisterCity] = React.useState('');
	const [registerCityState, setregisterCityState] = React.useState('');

	const [cityData, setcityData] = React.useState([]);

	const [registerAvatar, setregisterAvatar] = React.useState(null);
	const [registerAvatarBig, setregisterAvatarBig] = React.useState(null);
	const [registerOldAvatar, setregisterOldAvatar] = React.useState(null);
	const [previewAvatar, setpreviewAvatar] = React.useState(null);

	const [registerUID, setregisterUID] = React.useState('');
	const [registerUserId, setregisterUserId] = React.useState('');

	const refImage = React.createRef();
	const history = useHistory();

	const classes = useStyles();

	const handleSimple = (event) => {
		setregisterCity(event.target.value);
	};
	const handleAvatar = (event) => {
		event.preventDefault();
		let file = event.target.files[0];
		let previewFile = URL.createObjectURL(file);
		setregisterAvatar(file);
		setpreviewAvatar(previewFile);
		setregisterAvatarBig(previewFile);
	};

	// function that returns true if value is email, false otherwise
	const verifyEmail = (value) => {
		var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (emailRex.test(value)) {
			return true;
		}
		return false;
	};
	// function that verifies if a string has a given length or not
	const verifyLength = (value, length) => {
		if (value.length >= length) {
			return true;
		}
		return false;
	};
	// function that verifies if value contains only numbers
	const verifyNumber = (value) => {
		var numberRex = new RegExp('^[0-9]+$');
		if (numberRex.test(value)) {
			return true;
		}
		return false;
	};
	// verifies if value is a valid URL
	const verifyUrl = (value) => {
		try {
			new URL(value);
			return true;
		} catch (_) {
			return false;
		}
	};

	//## Get some user info here from DB.....
	useEffect(() => {
		const getUserDetails = (userId) => {
			try {
				// set the url
				const myUrl = `${APIurl}users.json?orderBy="userUid"&equalTo="${userId}"`;

				axios.get(myUrl).then((res) => {
					console.log(res.data);
					const myData = res.data;
					for (var key1 in myData) {
						for (var key2 in myData[key1]) {
							//console.log(myData[key1]);
							setregisterEmail(myData[key1].email);
							setregisterUserName(myData[key1].displayName);
							setregisterUserType(myData[key1].userType);
							setregisterFirstName(myData[key1].firstName);
							setregisterLastName(myData[key1].lastName);
							setregisterAddress(myData[key1].address);
							setregisterTelephone(myData[key1].telephone);
							setregisterWeb(myData[key1].web);
							setregisterAbout(myData[key1].about);
							setregisterCompany(myData[key1].company);
							setregisterPosition(myData[key1].position);
							setregisterCity(myData[key1].city);
							setregisterAvatar(myData[key1].photoUrl);
							setregisterAvatarBig(myData[key1].photoUrl);
							setregisterOldAvatar(myData[key1].photoUrl);
							setpreviewAvatar(myData[key1].photoUrl);
							setregisterUID(myData[key1].userUid);
							setregisterUserId([key1]);
						}
					}
				});
			} catch (err) {
				console.log(err);
				alert('something went wrong...');
			}
		};

		//#### get user from token...
		const GetUserId = () => {
			const myToken = Cookies.get('access_token');
			const myID = Cookies.get('access_id');
			if (myToken === undefined) {
				history.push('/auth/login-page');
			} else {
				getUserDetails(myID);
				/*
				try {
					// set the url
					const url =
						'https://identitytoolkit.googleapis.com/v1/accounts:lookup?key=AIzaSyA7l2dD4nbZTKQ8xaq7iho4nhVPYXMqbQA';
					// request data object
					const data = {
						idToken: myToken,
					};

					// set the headers
					const config = {
						headers: {
							'Content-Type': 'application/json',
						},
					};

					axios.post(url, data, config).then((res) => {
						console.log(res.data.users);
						getUserDetails(res.data.users[0].localId);
					});
				} catch (err) {
					console.log(err);
					alert('something went wrong...');
				}
				*/
			}
		};

		const GetCities = () => {
			const myToken = Cookies.get('access_token');
			if (myToken === undefined) {
				history.push('/auth/login-page');
			} else {
				try {
					// set the url
					const myUrl = `${APIurl}tabs.json`;
					const arrayData = [];

					axios.get(myUrl).then((res) => {
						//console.log(res.data);
						const myData = res.data;
						for (var key1 in myData) {
							for (var key2 in myData[key1]) {
								if ([key1] == 'categories') {
									//console.log(myData[key1][key2]);
									arrayData.push(
										<MenuItem
											key={key2}
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected,
											}}
											value={myData[key1][key2].title}
										>
											{myData[key1][key2].title}
										</MenuItem>
									);
								}
							}
						}
						setcityData(arrayData);
					});
				} catch (err) {
					console.log(err);
					alert('something went wrong...');
				}
			}
		};

		GetUserId();
		GetCities();
	}, []);
	const getUserDetails2 = (userId) => {
		try {
			// set the url
			const myUrl = `${APIurl}users.json?orderBy="userUid"&equalTo="${userId}"`;

			axios.get(myUrl).then((res) => {
				console.log(res.data);
				const myData = res.data;
				for (var key1 in myData) {
					for (var key2 in myData[key1]) {
						//console.log(myData[key1]);
						setregisterEmail(myData[key1].email);
						setregisterUserName(myData[key1].displayName);
						setregisterUserType(myData[key1].userType);
						setregisterFirstName(myData[key1].firstName);
						setregisterLastName(myData[key1].lastName);
						setregisterAddress(myData[key1].address);
						setregisterTelephone(myData[key1].telephone);
						setregisterWeb(myData[key1].web);
						setregisterAbout(myData[key1].about);
						setregisterCompany(myData[key1].company);
						setregisterPosition(myData[key1].position);
						setregisterCity(myData[key1].city);
						setregisterAvatar(myData[key1].photoUrl);
						setregisterAvatarBig(myData[key1].photoUrl);
						setregisterOldAvatar(myData[key1].photoUrl);
						setpreviewAvatar(myData[key1].photoUrl);
						setregisterUID(myData[key1].userUid);
						setregisterUserId([key1]);
					}
				}
			});
		} catch (err) {
			console.log(err);
			alert('something went wrong...');
		}
	};
	function handleSubmit(event) {
		event.preventDefault();
		const myToken = Cookies.get('access_token');
		if (myToken === undefined) {
			history.push('/auth/login-page');
		} else {
			var valid = false;
			var submittedFirstname = registerFirstName;
			var submittedLastname = registerLastName;
			var submittedAddress = registerAddress;
			var submittedCity = registerCity;
			var submittedCompany = registerCompany;
			var submittedPosition = registerPosition;
			var submittedTelephone = registerTelephone;
			var submittedWeb = registerWeb;
			var submittedAvatar = registerAvatar;
			var submittedOldAvatar = registerOldAvatar;
			var submittedAbout = registerAbout;
			var submittedUserId = registerUserId;
			var myAvatar =
				'https://solelylocal.imgix.net/avatar.jpg?fit=crop&w=840&q=80';

			if (submittedFirstname !== '' && submittedLastname !== '') {
				valid = true;
			}
			if (valid) {
				// try to Update user....
				console.log('Try to Update....');
				const updateUser = async () => {
					try {
						const myToken = Cookies.get('access_token');
						// set the url
						const url = `${APIurl}users/${submittedUserId}.json?auth=${myToken}`;
						// request data object
						const data = {
							photoUrl: myAvatar,
							about: submittedAbout,
							modifyDate: new Date(),
							firstName: submittedFirstname,
							lastName: submittedLastname,
							company: submittedCompany,
							position: submittedPosition,
							address: submittedAddress,
							city: submittedCity,
							telephone: submittedTelephone,
							web: submittedWeb,
						};

						// set the headers
						const config = {
							headers: {
								'Content-Type': 'application/x-www-form-urlencoded',
							},
						};
						const res = await axios.patch(url, data, config);
						console.log('result: ' + JSON.stringify(res));
						//alert('.....PASSSS....');
						//history.push('/admin/dashboard');
						//window.location.reload(false);
						getUserDetails2(submittedUserId);
					} catch (err) {
						console.log('error From create user.....' + err);
						//alert('.....EROR....');
						//history.push('/admin/dashboard');
						//window.location.reload(false);
						getUserDetails2(submittedUserId);
					}
				};
				//First check if avatar changed.... Upload Image....
				if (submittedAvatar !== submittedOldAvatar) {
					//##### UPLOADING IMAGE ###########
					try {
						var base_url = 'https://www.solelylocal.com/uploadImage.php';
						var fd = new FormData();
						fd.append('avatar', submittedAvatar, 'avatar.jpg');
						axios.post(base_url, fd).then((res) => {
							console.log(res);
							if (res.data.status === 'success') {
								// finally image uploaded.....
								let fileConstruct =
									'https://solelylocal.imgix.net/' +
									res.data.fileName +
									'?fit=crop&w=840&q=80';

								myAvatar = fileConstruct;
								console.log('to upload....' + myAvatar);
								updateUser();
							} else {
								// there was an error with file upload... revert to default...
								myAvatar = submittedOldAvatar;
								console.log('No error but no image either......');
								updateUser();
							}
						});
					} catch (err) {
						//console.error(err);
						console.log(
							'There was an error uploading file to the web server: ' + err
						);
						// default image.... proceed
						myAvatar = submittedOldAvatar;
						updateUser();
					}
					//###### END UPLOADING IMAGE ############################
				} else {
					//##### NO IMAGE TO UPLOAD#####//#endregionmyAvatar =
					myAvatar = submittedOldAvatar;
					updateUser();
				}
			}
		}
	}

	return (
		<div>
			<form className={classes.form} onSubmit={handleSubmit}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={8}>
						<Card>
							<CardHeader color="rose" icon>
								<CardIcon color="rose">
									<PermIdentity />
								</CardIcon>
								<h4 className={classes.cardIconTitle}>
									Edit Profile - <small>Complete your profile</small>
								</h4>
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={12} sm={12} md={5}>
										<CustomInput
											labelText="Email"
											id="email-disabled"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												disabled: true,
												value: registerEmail,
											}}
										/>
									</GridItem>
									<GridItem xs={12} sm={12} md={3}>
										<CustomInput
											labelText="Username"
											id="registerusername"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												disabled: true,
												value: registerUserName,
											}}
										/>
									</GridItem>
									<GridItem xs={12} sm={12} md={4}>
										<CustomInput
											labelText="User Type"
											id="registerusertype"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												disabled: true,
												value: registerUserType,
											}}
										/>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={12} sm={12} md={6}>
										<CustomInput
											labelText="First Name"
											success={registerFirstNameState === 'success'}
											error={registerFirstNameState === 'error'}
											id="registerfirstname"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: (event) => {
													if (verifyLength(event.target.value, 2)) {
														setregisterFirstNameState('success');
													} else {
														setregisterFirstNameState('error');
													}
													setregisterFirstName(event.target.value);
												},
												value: registerFirstName,
											}}
										/>
									</GridItem>
									<GridItem xs={12} sm={12} md={6}>
										<CustomInput
											labelText="Last Name"
											success={registerLastNameState === 'success'}
											error={registerLastNameState === 'error'}
											id="registerlastname"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: (event) => {
													if (verifyLength(event.target.value, 2)) {
														setregisterLastNameState('success');
													} else {
														setregisterLastNameState('error');
													}
													setregisterLastName(event.target.value);
												},
												value: registerLastName,
											}}
										/>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={12} sm={12} md={6}>
										<CustomInput
											labelText="Address"
											success={registerAddressState === 'success'}
											error={registerAddressState === 'error'}
											id="registeraddress"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: (event) => {
													if (verifyLength(event.target.value, 2)) {
														setregisterAddressState('success');
													} else {
														setregisterAddressState('error');
													}
													setregisterAddress(event.target.value);
												},
												value: registerAddress,
											}}
										/>
									</GridItem>
									<GridItem xs={12} sm={12} md={6}>
										<FormControl
											fullWidth
											style={{
												backgroundColor: '#f9f9f9',
												marginLeft: 0,
												marginTop: 12,
											}}
											className={classes.selectFormControl}
										>
											<InputLabel
												htmlFor="registerCity"
												className={classes.selectLabel}
											>
												Choose City
											</InputLabel>

											<Select
												MenuProps={{
													className: classes.selectMenu,
												}}
												classes={{
													select: classes.select,
												}}
												value={registerCity}
												onChange={handleSimple}
												inputProps={{
													name: 'registerCity',
													id: 'registerCity',
												}}
											>
												<MenuItem
													disabled
													classes={{
														root: classes.selectMenuItem,
													}}
												>
													Choose City
												</MenuItem>
												{cityData}
											</Select>
										</FormControl>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={12} sm={12} md={6}>
										<CustomInput
											labelText="Company"
											success={registerCompanyState === 'success'}
											error={registerCompanyState === 'error'}
											id="registercompany"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: (event) => {
													if (verifyLength(event.target.value, 0)) {
														setregisterCompanyState('success');
													} else {
														setregisterCompanyState('error');
													}
													setregisterCompany(event.target.value);
												},
												value: registerCompany,
											}}
										/>
									</GridItem>
									<GridItem xs={12} sm={12} md={6}>
										<CustomInput
											labelText="Position"
											success={registerPositionState === 'success'}
											error={registerPositionState === 'error'}
											id="registerposition"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: (event) => {
													if (verifyLength(event.target.value, 0)) {
														setregisterPositionState('success');
													} else {
														setregisterPositionState('error');
													}
													setregisterPosition(event.target.value);
												},
												value: registerPosition,
											}}
										/>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={12} sm={12} md={4}>
										<CustomInput
											labelText="Telephone"
											success={registerTelephoneState === 'success'}
											error={registerTelephoneState === 'error'}
											id="registertelephone"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: (event) => {
													if (verifyLength(event.target.value, 0)) {
														setregisterTelephoneState('success');
													} else {
														setregisterTelephoneState('error');
													}
													setregisterTelephone(event.target.value);
												},
												value: registerTelephone,
											}}
										/>
									</GridItem>
									<GridItem xs={12} sm={12} md={4}>
										<CustomInput
											labelText="Web"
											success={registerWebState === 'success'}
											error={registerWebState === 'error'}
											id="registerweb"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: (event) => {
													if (verifyLength(event.target.value, 0)) {
														setregisterWebState('success');
													} else {
														setregisterWebState('error');
													}
													setregisterWeb(event.target.value);
												},
												value: registerWeb,
											}}
										/>
									</GridItem>
									<GridItem xs={12} sm={12} md={4}>
										<div className={classes.center}>
											<img src={previewAvatar} width="45" height="45" />
											<input type="file" onChange={handleAvatar} />
										</div>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={12} sm={12} md={12}>
										<InputLabel style={{ color: '#AAAAAA' }}>
											About me
										</InputLabel>
										<CustomInput
											id="registerabout"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: (event) => {
													if (verifyLength(event.target.value, 0)) {
														setregisterAboutState('success');
													} else {
														setregisterAboutState('error');
													}
													setregisterAbout(event.target.value);
												},
												value: registerAbout,
												multiline: true,
												rows: 5,
											}}
										/>
									</GridItem>
								</GridContainer>
								<Button
									type="submit"
									color="rose"
									className={classes.updateProfileButton}
								>
									Update Profile
								</Button>
								<Clearfix />
							</CardBody>
						</Card>
					</GridItem>

					<GridItem xs={12} sm={12} md={4}>
						<Card profile>
							<CardAvatar profile>
								<a href="#pablo" onClick={(e) => e.preventDefault()}>
									<img src={registerAvatarBig} alt="..." />
								</a>
							</CardAvatar>
							<CardBody profile>
								<h6 className={classes.cardCategory}>{registerPosition}</h6>
								<h6 className={classes.cardCategory}>{registerCompany}</h6>
								<h4 className={classes.cardTitle}>
									{registerFirstName}
									{'  '}
									{registerLastName}
								</h4>
								<p className={classes.description}>{registerAbout}</p>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</form>
		</div>
	);
}
