import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { APIurl, APIusersKey } from '../../variables/utils';

import auth from '../../auth';
import Cookies from 'js-cookie';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Router, Route, Redirect, useHistory } from 'react-router-dom';

// react plugin for creating charts
import ChartistGraph from 'react-chartist';
// react plugin for creating vector maps
import { VectorMap } from 'react-jvectormap';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from '@material-ui/icons/Store';
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from '@material-ui/icons/Warning';
import DateRange from '@material-ui/icons/DateRange';
import LocalOffer from '@material-ui/icons/LocalOffer';
import Update from '@material-ui/icons/Update';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import AccessTime from '@material-ui/icons/AccessTime';
import Refresh from '@material-ui/icons/Refresh';
import Edit from '@material-ui/icons/Edit';
import Place from '@material-ui/icons/Place';
import ArtTrack from '@material-ui/icons/ArtTrack';
import Language from '@material-ui/icons/Language';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Table from 'components/Table/Table.js';
import Button from 'components/CustomButtons/Button.js';
import Danger from 'components/Typography/Danger.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import {
	dailySalesChart,
	emailsSubscriptionChart,
	completedTasksChart,
} from 'variables/charts';

import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js';

import priceImage1 from 'assets/img/card-2.jpeg';
import priceImage2 from 'assets/img/card-3.jpeg';
import priceImage3 from 'assets/img/card-1.jpeg';

const useStyles = makeStyles(styles);

export default function Dashboard() {
	const [registerFirstName, setregisterFirstName] = React.useState('');
	const [registerLastName, setregisterLastName] = React.useState('');
	const [registerUserType, setregisterUserType] = React.useState('');
	const [registerUID, setregisterUID] = React.useState('');
	const [totalApproved, settotalApproved] = React.useState('');
	const [totalNotApproved, settotalNotApproved] = React.useState('');
	const [renderPosts, setrenderPosts] = React.useState([]);
	const [slatkiAlert, setslatkiAlert] = React.useState(null);
	const history = useHistory();
	const classes = useStyles();
	var sortBy = require('array-sort-by');

	const warningWithConfirmMessage = (MyId, myImage) => {
		setslatkiAlert(
			<SweetAlert
				warning
				style={{ display: 'block', marginTop: '-100px' }}
				title="Are you sure?"
				onConfirm={() => successDelete(MyId, myImage)}
				onCancel={() => hideAlert()}
				confirmBtnCssClass={classes.button + ' ' + classes.success}
				cancelBtnCssClass={classes.button + ' ' + classes.danger}
				confirmBtnText="Yes, delete it!"
				cancelBtnText="Cancel"
				showCancel
			>
				You will not be able to recover this record!
			</SweetAlert>
		);
	};
	const successDelete = (delId, delImage) => {
		const myToken = Cookies.get('access_token');
		if (myToken === undefined) {
			history.push('/auth/login-page');
		} else {
			//delete here/////
			console.log('To delete...' + delId + '...Image...' + delImage);
			//GET images that need to be deleted...
			const getImages = (UID, img) => {
				var imageToCheck = '';
				if (img !== '' && img !== undefined) {
					var res1 = img.replace('https://solelylocal.imgix.net/', '');
					var res2 = res1.replace('?fit=crop&w=840&q=80', '');
					if (res2 !== 'PortAlberni840.jpg') {
						imageToCheck = res2;
					}
				}
				if (imageToCheck !== '') {
					//##### DELETING IMAGE ###########
					try {
						var base_url = 'https://www.solelylocal.com/deleteImage.php';
						var fd = new FormData();
						fd.append('delete_file', imageToCheck);
						axios.post(base_url, fd).then((res) => {
							console.log(res);
							console.log('IMA STATUS....' + res.data.status);
							deletePost(UID);
						});
					} catch (err) {
						//console.error(err);
						console.log(
							'There was an error deleting file from web server: ' + err
						);
						deletePost(UID);
					}
					//###### END DELETING IMAGE ############################
				} else {
					deletePost(UID);
				}
			};

			const deletePost = (recId) => {
				//alert('delete post....');
				const myToken = Cookies.get('access_token');
				if (myToken === undefined) {
					history.push('/auth/login-page');
				} else {
					try {
						// set the url
						const myUrl = `${APIurl}regions/${recId}.json?auth=${myToken}`;
						// request data object
						axios.delete(myUrl).then((res) => {
							console.log('Deleted Post finaly!!!!' + res.data);
						});
					} catch (err) {
						console.log('Error Adding Post!!!!' + err);
					}
				}
			};

			getImages(delId, delImage);

			setslatkiAlert(
				<SweetAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title="Deleted!"
					onConfirm={() => hideAlert2()}
					onCancel={() => hideAlert2()}
					confirmBtnCssClass={classes.button + ' ' + classes.success}
				>
					Your Post has been deleted.
				</SweetAlert>
			);
		}
	};
	const navigateToView = (e, prcId) => {
		e.preventDefault();
		history.push(`/admin/post?item=${prcId}`);
	};

	const navigateToEdit = (e, prcId) => {
		e.preventDefault();
		history.push(`/admin/add-region?item=${prcId}`);
	};

	const hideAlert = () => {
		setslatkiAlert(null);
	};
	const hideAlert2 = () => {
		setslatkiAlert(null);
		history.push('/admin/');
	};
	//## Get some user info here from DB.....
	useEffect(() => {
		const getUserRegions = () => {
			const myToken = Cookies.get('access_token');
			if (myToken === undefined) {
				history.push('/auth/login-page');
			} else {
				try {
					var sortBy = require('array-sort-by');
					var approvedCount = 0;
					var NotApprovedCount = 0;
					var myPostsUnsorted = [];
					const months = [
						'JAN',
						'FEB',
						'MAR',
						'APR',
						'MAY',
						'JUN',
						'JUL',
						'AUG',
						'SEP',
						'OCT',
						'NOV',
						'DEC',
					];
					// set the url
					const myUrl = `${APIurl}regions.json?orderBy="tabId"`;
					//alert(myUrl);
					axios.get(myUrl).then((res) => {
						console.log(res.data);
						const myData = res.data;
						for (var key1 in myData) {
							if (myData[key1].active == 0) {
								NotApprovedCount = NotApprovedCount + 1;
							} else {
								approvedCount = approvedCount + 1;
							}
							var current_datetime = new Date(myData[key1].createDate);
							var formatted_date =
								current_datetime.getDate() +
								'-' +
								months[current_datetime.getMonth()] +
								'-' +
								current_datetime.getFullYear();

							var filteredPosts = new Object();
							filteredPosts.postId = key1;
							filteredPosts.createDate = formatted_date;
							filteredPosts.image = myData[key1].image;
							filteredPosts.id = myData[key1].id;
							filteredPosts.city = myData[key1].tab;
							filteredPosts.cityId = myData[key1].tabId;
							filteredPosts.title = myData[key1].title;
							filteredPosts.active = myData[key1].active;
							myPostsUnsorted.push(filteredPosts);
						}
						settotalApproved(approvedCount);
						settotalNotApproved(NotApprovedCount);

						const myPostsSorted = sortBy(myPostsUnsorted, (item) => [
							item.tab,
							-item.title,
						]);
						setrenderPosts(myPostsSorted);
						//console.log(JSON.stringify(myPostsSorted));
					});
				} catch (err) {
					console.log(err);
					alert('something went wrong...');
				}
			}
		};
		getUserRegions();
	}, []);

	return (
		<div>
			{slatkiAlert}
			<GridContainer>
				<GridItem xs={12} sm={6} md={6} lg={3}>
					<Card>
						<CardHeader color="success" stats icon>
							<CardIcon color="success">
								<Store />
							</CardIcon>
							<p className={classes.cardCategory}>Total Active Regions</p>
							<h3 className={classes.cardTitle}>{totalApproved}</h3>
						</CardHeader>
						<CardFooter stats>
							<div className={classes.stats}>
								<DateRange />
								Last 24 Hours
							</div>
						</CardFooter>
					</Card>
				</GridItem>
				<GridItem xs={12} sm={6} md={6} lg={3}>
					<Card>
						<CardHeader color="danger" stats icon>
							<CardIcon color="danger">
								<Icon>info_outline</Icon>
							</CardIcon>
							<p className={classes.cardCategory}>Total Non Active Regions</p>
							<h3 className={classes.cardTitle}>{totalNotApproved}</h3>
						</CardHeader>
						<CardFooter stats>
							<div className={classes.stats}>
								<Update />
								Just Updated
							</div>
						</CardFooter>
					</Card>
				</GridItem>
			</GridContainer>

			<h3>Manage Regions</h3>
			<br />
			<GridContainer>
				{renderPosts.map((item, index) => (
					<GridItem key={index} xs={12} sm={12} md={4}>
						<Card product className={classes.cardHover}>
							<CardHeader image className={classes.cardHeaderHover}>
								<a
									href="#admin"
									onClick={(e) => navigateToEdit(e, item.postId)}
								>
									<img src={item.image} alt="..." />
								</a>
							</CardHeader>
							<CardBody>
								<div className={classes.cardHoverUnder}>
									<Tooltip
										id="tooltip-top"
										title="Edit"
										placement="bottom"
										classes={{ tooltip: classes.tooltip }}
									>
										<Button
											color="success"
											simple
											justIcon
											onClick={(e) => navigateToEdit(e, item.postId)}
										>
											<Refresh className={classes.underChartIcons} />
										</Button>
									</Tooltip>
									<Tooltip
										id="tooltip-top"
										title="Remove"
										placement="bottom"
										classes={{ tooltip: classes.tooltip }}
									>
										<Button
											color="danger"
											simple
											justIcon
											onClick={() =>
												warningWithConfirmMessage(item.postId, item.image)
											}
										>
											<Edit className={classes.underChartIcons} />
										</Button>
									</Tooltip>
								</div>
								<h4 className={classes.cardProductTitle}>
									<a
										href="#admin"
										onClick={(e) => navigateToEdit(e, item.postId)}
									>
										{item.title}
									</a>
								</h4>
								<p className={classes.cardProductDesciprion}>{item.city}</p>
								<hr></hr>
								<p className={classes.cardProductDesciprion}>
									Posted on: <strong>{item.createDate}</strong>
								</p>
								{!item.active ? (
									<p className={classes.cardProductDesciprion2}>
										Not Active Yet
									</p>
								) : null}
							</CardBody>
						</Card>
					</GridItem>
				))}
			</GridContainer>
		</div>
	);
}
