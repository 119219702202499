import React from 'react';
import axios from 'axios';
import { APIurl, APIusersKey } from '../../../variables/utils';
import PropTypes from 'prop-types';
// @material-ui/icons
import Face from '@material-ui/icons/Face';
import Receipt from '@material-ui/icons/Receipt';
import Room from '@material-ui/icons/Room';
import Phone from '@material-ui/icons/Phone';
import LocationOn from '@material-ui/icons/LocationOn';
import RssFeed from '@material-ui/icons/RssFeed';
import RecordVoiceOver from '@material-ui/icons/RecordVoiceOver';
import Email from '@material-ui/icons/Email';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import PictureUpload from 'components/CustomUpload/PictureUpload.js';
import CustomInput from 'components/CustomInput/CustomInput.js';

const style = {
	infoText: {
		fontWeight: '300',
		margin: '10px 0 30px',
		textAlign: 'center',
	},
	inputAdornmentIcon: {
		color: '#555',
	},
	inputAdornment: {
		position: 'relative',
	},
};

class Step1 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pageMode: 'insert',
			updateId: null,
			businessname: '',
			businessnameState: 'success',
			address: '',
			addressState: '',
			title: '',
			titleState: 'success',
			phone: '',
			phoneState: '',
			web: '',
			webState: '',
			email: '',
			emailState: '',
			registerCity: '',
			cityData: '',
			cityState: '',
			regionsData: '',
			registerRegion: '',
		};
	}

	//### Is it insert or Update...
	getParameterByName = (name, url) => {
		if (!url) url = window.location.href;
		name = name.replace(/[\[\]]/g, '\\$&');
		var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	};

	//TRY TO GET REGIONS......
	componentDidMount() {
		const foo = this.getParameterByName('item');
		if (foo !== '' && foo !== null && foo !== undefined) {
			this.setState({
				pageMode: 'edit',
				updateId: foo,
			});
			this.getPost(foo);
		}
		this.GetCities();
	}

	getPost = (postId) => {
		try {
			// set the url
			const myUrl = `${APIurl}products/${postId}.json`;

			axios.get(myUrl).then((res) => {
				console.log(res.data);
				const myData = res.data;
				this.setState({
					businessname: myData.businessName,
					businessnameState: 'success',
					address: myData.address,
					addressState: 'success',
					title: myData.listingPersonTitle,
					titleState: 'success',
					phone: myData.phone,
					phoneState: 'success',
					web: myData.webSite,
					email: myData.email,
					emailState: 'success',
					registerCity: myData.city,
					registerRegion: myData.region,
				});
				this.renderRegions(myData.city);
			});
		} catch (err) {
			console.log(err);
			alert('something went wrong...');
		}
	};

	renderRegions = (city) => {
		const myUrl = `${APIurl}regions.json?orderBy="tab"&equalTo="${city}"`;
		//alert(myUrl);
		const regionsArray = [];
		axios.get(myUrl).then((res) => {
			console.log(res.data);
			const thisData = res.data;
			for (var key1 in thisData) {
				//console.log('new loop1....' + thisData[key1].title);
				regionsArray.push(
					<MenuItem key={key1} value={thisData[key1].title}>
						{thisData[key1].title}
					</MenuItem>
				);
			}
			this.setState({
				regionsData: regionsArray,
			});
			//console.log('and regions...' + this.state.regionsData);
		});
	};
	GetCities = () => {
		try {
			// set the url
			const myUrl = `${APIurl}tabs.json`;
			const arrayData = [];

			axios.get(myUrl).then((res) => {
				//console.log(res.data);
				const myData = res.data;
				for (var key1 in myData) {
					for (var key2 in myData[key1]) {
						if ([key1] == 'categories') {
							//console.log(myData[key1][key2]);
							arrayData.push(
								<MenuItem key={key2} value={myData[key1][key2].title}>
									{myData[key1][key2].title}
								</MenuItem>
							);
						}
					}
				}
				this.setState({
					cityData: arrayData,
				});
			});
		} catch (err) {
			console.log(err);
			alert('something went wrong...');
		}
	};

	sendState() {
		return this.state;
	}
	// function that returns true if value is email, false otherwise
	verifyEmail(value) {
		var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (emailRex.test(value)) {
			return true;
		}
		return false;
	}
	// function that verifies if a string has a given length or not
	verifyLength(value, length) {
		if (value.length >= length) {
			return true;
		}
		return false;
	}
	// function that verifies if value contains only numbers
	verifyNumber = (value) => {
		var numberRex = new RegExp('^[0-9]+$');
		if (numberRex.test(value)) {
			return true;
		}
		return false;
	};
	// verifies if value is a valid URL
	verifyUrl = (value) => {
		try {
			new URL(value);
			return true;
		} catch (_) {
			return false;
		}
	};
	change(event, stateName, type, stateNameEqualTo) {
		switch (type) {
			case 'email':
				if (this.verifyEmail(event.target.value)) {
					this.setState({ [stateName + 'State']: 'success' });
				} else {
					this.setState({ [stateName + 'State']: 'error' });
				}
				break;
			case 'phone':
				if (this.verifyNumber(event.target.value)) {
					this.setState({ [stateName + 'State']: 'success' });
				} else {
					this.setState({ [stateName + 'State']: 'error' });
				}
				break;
			case 'web':
				if (this.verifyUrl(event.target.value)) {
					this.setState({ [stateName + 'State']: 'success' });
				} else {
					this.setState({ [stateName + 'State']: 'error' });
				}
				break;
			case 'length':
				if (this.verifyLength(event.target.value, stateNameEqualTo)) {
					this.setState({ [stateName + 'State']: 'success' });
				} else {
					this.setState({ [stateName + 'State']: 'error' });
				}
				break;
			default:
				break;
		}
		this.setState({ [stateName]: event.target.value });
	}
	handleSimple = (event) => {
		this.setState({
			registerCity: event.target.value,
			registerRegion: '',
		});
		this.renderRegions(event.target.value);
	};
	handleSimple2 = (event) => {
		this.setState({
			registerRegion: event.target.value,
		});
	};
	isValidated() {
		if (
			this.state.businessnameState === 'success' &&
			this.state.addressState === 'success' &&
			this.state.titleState === 'success' &&
			this.state.phoneState === 'success' &&
			this.state.registerCity != '' &&
			this.state.registerRegion != ''
		) {
			return true;
		} else {
			if (this.state.businessnameState !== 'success') {
				this.setState({ businessnameState: 'error' });
			}
			if (this.state.addressState !== 'success') {
				this.setState({ addressState: 'error' });
			}
			if (this.state.titleState !== 'success') {
				this.setState({ titleState: 'error' });
			}
			if (this.state.phoneState !== 'success') {
				this.setState({ phoneState: 'error' });
			}
		}
		return false;
	}
	render() {
		const { classes } = this.props;
		return (
			<GridContainer justify="center">
				<GridItem xs={12} sm={12}>
					<h4 className={classes.infoText}>
						Let{"'"}s start with the post information
					</h4>
				</GridItem>

				<GridItem xs={12} sm={12}>
					<CustomInput
						success={this.state.addressState === 'success'}
						error={this.state.addressState === 'error'}
						labelText={
							<span>
								Post Address <small>(required)</small>
							</span>
						}
						id="address"
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							onChange: (event) => this.change(event, 'address', 'length', 3),
							endAdornment: (
								<InputAdornment
									position="end"
									className={classes.inputAdornment}
								>
									<LocationOn className={classes.inputAdornmentIcon} />
								</InputAdornment>
							),
							value: this.state.address,
						}}
					/>

					<FormControl
						fullWidth
						style={{ backgroundColor: '#f9f9f9', marginLeft: 0 }}
						className={classes.selectFormControl}
					>
						<InputLabel htmlFor="registerCity" className={classes.selectLabel}>
							Choose City
						</InputLabel>

						<Select
							MenuProps={{
								className: classes.selectMenu,
							}}
							classes={{
								select: classes.select,
							}}
							value={this.state.registerCity}
							onChange={this.handleSimple}
							inputProps={{
								name: 'registerCity',
								id: 'registerCity',
							}}
						>
							<MenuItem
								disabled
								classes={{
									root: classes.selectMenuItem,
								}}
							>
								Choose City
							</MenuItem>
							{this.state.cityData}
						</Select>
					</FormControl>
					<FormControl
						fullWidth
						style={{ backgroundColor: '#f9f9f9', marginLeft: 0 }}
						className={classes.selectFormControl}
					>
						<InputLabel
							htmlFor="registerRegion"
							className={classes.selectLabel}
						>
							Choose Region
						</InputLabel>

						<Select
							MenuProps={{
								className: classes.selectMenu,
							}}
							classes={{
								select: classes.select,
							}}
							value={this.state.registerRegion}
							onChange={this.handleSimple2}
							inputProps={{
								name: 'registerRegion',
								id: 'registerRegion',
							}}
						>
							<MenuItem
								disabled
								classes={{
									root: classes.selectMenuItem,
								}}
							>
								Choose Region
							</MenuItem>
							{this.state.regionsData}
						</Select>
					</FormControl>

					<CustomInput
						success={this.state.phoneState === 'success'}
						error={this.state.phoneState === 'error'}
						labelText={
							<span>
								Post Phone <small>(required)</small>
							</span>
						}
						id="phone"
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							onChange: (event) => this.change(event, 'phone', 'length', 3),
							endAdornment: (
								<InputAdornment
									position="end"
									className={classes.inputAdornment}
								>
									<Phone className={classes.inputAdornmentIcon} />
								</InputAdornment>
							),
							value: this.state.phone,
						}}
					/>
					<CustomInput
						success={this.state.emailState === 'success'}
						error={this.state.emailState === 'error'}
						labelText={<span>Post Email</span>}
						id="email"
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							onChange: (event) => this.change(event, 'email', 'length', 0),
							endAdornment: (
								<InputAdornment
									position="end"
									className={classes.inputAdornment}
								>
									<Email className={classes.inputAdornmentIcon} />
								</InputAdornment>
							),
							value: this.state.email,
						}}
					/>
					<CustomInput
						success={this.state.webState === 'success'}
						error={this.state.webState === 'error'}
						labelText={<span>Website</span>}
						id="web"
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							onChange: (event) => this.change(event, 'web', 'length', 0),
							endAdornment: (
								<InputAdornment
									position="end"
									className={classes.inputAdornment}
								>
									<RssFeed className={classes.inputAdornmentIcon} />
								</InputAdornment>
							),
							value: this.state.web,
						}}
					/>
				</GridItem>
			</GridContainer>
		);
	}
}

Step1.propTypes = {
	classes: PropTypes.object,
};

export default withStyles(style)(Step1);
