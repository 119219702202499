/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Router, Route, Redirect, useHistory } from 'react-router-dom';
import { subTitle } from '../../variables/utils';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import styles from 'assets/jss/material-dashboard-pro-react/components/footerStyle.js';

const useStyles = makeStyles(styles);

export default function Footer(props) {
	const history = useHistory();
	const classes = useStyles();
	const { fluid, white, rtlActive } = props;
	var container = cx({
		[classes.container]: !fluid,
		[classes.containerFluid]: fluid,
		[classes.whiteColor]: white,
	});
	var anchor =
		classes.a +
		cx({
			[' ' + classes.whiteColor]: white,
		});
	var block = cx({
		[classes.block]: true,
		[classes.whiteColor]: white,
	});
	const handlePrivacy = (e) => {
		e.preventDefault();
		history.push('/auth/privacy-page');
	};
	const handleTerms = (e) => {
		e.preventDefault();
		history.push('/auth/terms-page');
	};
	return (
		<footer className={classes.footer}>
			<div className={container}>
				<div className={classes.left}>
					<List className={classes.list}>
						<a
							href="https://play.google.com/store/apps/details?id=com.solelylocal.covid&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
							target="_blank"
						>
							<img
								alt="Get it on Google Play"
								width="130"
								src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
							/>
						</a>
						<a
							href="https://apps.apple.com/ca/app/solelylocal-post/id1523883593?mt=8"
							target="_blank"
						>
							<img
								alt="Download on the App Store"
								height="33"
								src="https://linkmaker.itunes.apple.com/assets/shared/badges/en-us/appstore-lrg-25178aeef6eb6b83b96f5f2d004eda3bffbb37122de64afbaef7107b384a4132.svg"
							/>
						</a>
						{'   '}
						{subTitle}
					</List>
				</div>
				<p className={classes.right}>
					&copy; {1900 + new Date().getYear()}{' '}
					<a
						href="https://www.solelylocal.com"
						className={anchor}
						target="_blank"
					>
						{rtlActive ? 'توقيت الإبداعية' : 'Solely Local Inc. '}
					</a>
					<a href="#one" className={anchor} onClick={(e) => handlePrivacy(e)}>
						{rtlActive ? 'توقيت الإبداعية' : ' Privacy Policy '}
					</a>
					<a href="#two" className={anchor} onClick={(e) => handleTerms(e)}>
						{rtlActive ? 'توقيت الإبداعية' : ' Terms of Use '}
					</a>
					{rtlActive
						? ', مصنوعة مع الحب لشبكة الإنترنت أفضل'
						: ', made with love for a better web'}
				</p>
			</div>
		</footer>
	);
}

Footer.propTypes = {
	fluid: PropTypes.bool,
	white: PropTypes.bool,
	rtlActive: PropTypes.bool,
};
