import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';

// @material-ui/icons
// import Weekend from "@material-ui/icons/Weekend";
import Home from '@material-ui/icons/Home';
import Business from '@material-ui/icons/Business';
import AccountBalance from '@material-ui/icons/AccountBalance';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';

import styles from 'assets/jss/material-dashboard-pro-react/views/pricingPageStyle.js';

const useStyles = makeStyles(styles);

export default function TermsPage() {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<GridContainer justify="center">
				<GridItem xs={12} sm={12} md={6}>
					<h2 className={classes.title}>Terms of Service</h2>
					<h5 className={classes.description}>Solely Local Networks Inc</h5>
				</GridItem>
			</GridContainer>
			<GridContainer justify="center">
				<GridItem xs={12} sm={12} md={8}>
					<Card pricing raised>
						<CardBody pricing>
							<p className={classes.cardDescription}>
								The following applies to Individuals, Businesses and Non-Profits
								[“members”] using the Solely Local Networks Inc. [“SolelyLocal”]
								services. These services provided through the “SolelyLocal”
								mobile app or the “SolelyLocal” website
								[www.solelylocal.com/post] for the purpose of communicating with
								other using the system.
								<br />
								<br />
								Please read these terms and conditions (the “Terms”) carefully
								before using the services offered by Solely Local Networks Inc
								as by using these services you are agreeing to be bound by these
								terms. If you do not want to be bound by these terms, please
								discontinue use of the service being provided by Solely Local
								Networks Inc.
							</p>
							<h4 className={classes.cardCategory}>The Service</h4>
							<p className={classes.cardDescription}>
								The Solely Local provides a mobile bulletin service to allow
								members to reach out to other users for the purpose supporting
								Local Non-Profits, Local Businesses and Individuals requiring
								assistance from members located in their community.
								<br />
								<br />
								Postings on SolelyLocal services are made directly by the users
								of this service. Solely Local is not responsible errors,
								omissions or misleading statements that may be made with
								postings on the SolelyLocal Service.
							</p>
							<h4 className={classes.cardCategory}>Eligibility to Post</h4>
							<p className={classes.cardDescription}>
								Individuals are restricted from posting until their full
								registration is make. The information required to register
								includes but not limited to name, mobile phone number, email
								address. Individuals registering to place posts for Businesses
								or Non-Profits will be required to supply additional information
								related to that group and the contact details of the individual
								having authority to give approve posting by that individual.
								<br />
								<br />
								Individuals are required to warrant that they are Canadian
								Residents.
								<br />
								<br />
								SolelyLocal reserves the right to terminate posting rights or
								membership at any time without notice.
								<br />
								<br />
								Management of the responses made to any posts by a member is the
								sole responsibility of the member whether the response is via
								text, email or phone. Members have exclusive responsibility on
								determining the validity of any posting made or on validity of
								responses made. <br />
								<br />
								Members agree to indemnify SolelyLocal its officers, directors,
								staff harmless from any and all third-party claims, proceedings,
								damages, costs and expenses (including reasonable attorneys’
								fees and litigation expenses), arising out of or relating to
								your use of the Services.
							</p>
							<h4 className={classes.cardCategory}>Third-Party Content</h4>
							<p className={classes.cardDescription}>
								Postings may contain links to web pages and content of third
								parties. SolelyLocal does not have control over this content and
								will not monitor or endorse this third-party content.
							</p>
							<h4 className={classes.cardCategory}>Copyright and Trademarks</h4>
							<p className={classes.cardDescription}>
								SolelyLocal is a trademark of Solely Local Networks Inc. All
								rights in respect of this trademark are hereby expressly
								reserved. Unless otherwise indicated, all other trademarks
								appearing on the Service are the property of their respective
								owners.
							</p>
							<h4 className={classes.cardCategory}>Privacy</h4>
							<p className={classes.cardDescription}>
								Solely Local’s privacy agreement is an integral part of these
								Terms of Service.
							</p>
							<h4 className={classes.cardCategory}>Warranty Disclaimer</h4>
							<p className={classes.cardDescription}>
								This Service is made accessible on an “as is” and “as available”
								basis.
							</p>
							<h4 className={classes.cardCategory}>Limitation of Liability</h4>
							<p className={classes.cardDescription}>
								SolelyLocal shall not be liable for any loss or damages arising
								out of any use of the Service.
							</p>
							<h4 className={classes.cardCategory}>Governing Law</h4>
							<p className={classes.cardDescription}>
								These Terms shall be governed by the laws of the province of
								British Columbia, and Canada.
							</p>
							<h4 className={classes.cardCategory}>Modification of Terms</h4>
							<p className={classes.cardDescription}>
								SolelyLocal reserves the right, to modify or replace any of
								these Terms, or change, suspend, or discontinue the Service at
								any time by posting a notice on the Service
							</p>
							<h4 className={classes.cardCategory}>Contact</h4>
							<p className={classes.cardDescription}>
								Gary Gilmour
								<br />
								20-21 Dallas road Rd
								<br />
								Victoria BC
								<br />
								ggilmour@solelylocal.com
								<br />
								Effective Date July 1, 2020
							</p>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		</div>
	);
}
