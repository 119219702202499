import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { APIurl, APIusersKey } from '../../variables/utils';
import auth from '../../auth';
import Cookies from 'js-cookie';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Router, Route, Redirect, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';

// @material-ui/icons
import Face from '@material-ui/icons/Face';
import Email from '@material-ui/icons/Email';
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardFooter from 'components/Card/CardFooter.js';

import styles from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.js';
import { blue } from '@material-ui/core/colors';
import { decodedTextSpanIntersectsWith } from 'typescript';

const useStyles = makeStyles(styles);
export default function LoginPage() {
	const classes = useStyles();
	const [cardAnimaton, setCardAnimation] = React.useState('cardHidden');
	const [alert, setAlert] = React.useState(null);
	// Login form
	const [loginEmail, setloginEmail] = React.useState('');
	const [loginEmailState, setloginEmailState] = React.useState('');
	const [loginPassword, setloginPassword] = React.useState('');
	const [loginPasswordState, setloginPasswordState] = React.useState('');
	const history = useHistory();

	//#### IF User is coming from app....
	useEffect(() => {
		const myToken = Cookies.get('access_token');
		if (myToken !== undefined && myToken !== '') {
			history.push('/admin/dashboard');
		}

		/*
		const getParameterByName = (name, url) => {
			if (!url) url = window.location.href;
			name = name.replace(/[\[\]]/g, '\\$&');
			var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
				results = regex.exec(url);
			if (!results) return null;
			if (!results[2]) return '';
			return decodeURIComponent(results[2].replace(/\+/g, ' '));
		};
		const foo = getParameterByName('myToken');
		if (foo !== '' && foo !== null && foo !== undefined) {
			// OK User is comming from web page... log them in....
			try {
				const expires = 60 * 60 * 1000;
				//const expires = 10000;
				const inOneHour = new Date(new Date().getTime() + expires);

				// you will have the exact same setters in your Login page/app too
				Cookies.set('access_token', foo, {
					expires: inOneHour,
				});
				Cookies.set('refresh_token', foo);
				auth.login();
				history.push('/admin/dashboard');
			} catch (error) {
				warningAlert();
				setloginPassword('');
			}
		}
		*/
	}, []);

	// function that returns true if value is email, false otherwise
	const verifyEmail = (value) => {
		var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (emailRex.test(value)) {
			return true;
		}
		return false;
	};
	// function that verifies if a string has a given length or not
	const verifyLength = (value, length) => {
		if (value.length >= length) {
			return true;
		}
		return false;
	};
	// function that verifies if value contains only numbers
	const verifyNumber = (value) => {
		var numberRex = new RegExp('^[0-9]+$');
		if (numberRex.test(value)) {
			return true;
		}
		return false;
	};
	// verifies if value is a valid URL
	const verifyUrl = (value) => {
		try {
			new URL(value);
			return true;
		} catch (_) {
			return false;
		}
	};
	const loginClick = () => {
		if (loginEmailState === '') {
			setloginEmailState('error');
			return false;
		} else if (loginPasswordState === '') {
			setloginPasswordState('error');
			return false;
		} else {
			return true;
		}
	};

	setTimeout(function() {
		setCardAnimation('');
	}, 700);

	// this is alert for validation....
	const warningAlert = () => {
		setAlert(
			<SweetAlert
				warning
				style={{ backgroundColor: '#e91e63' }}
				confirmBtnText="Try Again!"
				confirmBtnBsStyle="warning"
				btnSize="lg"
				title="Please Verify your Email and Password!"
				onConfirm={() => hideAlert()}
				onCancel={() => hideAlert()}
			>
				Something went wrong....
			</SweetAlert>
		);
	};
	const hideAlert = () => {
		setAlert(null);
	};

	// END this is alert for validation....
	function handleSubmit(event) {
		event.preventDefault();

		console.log('submitted....' + loginEmail);
		var emailtest = /\S+@\S+\.\S+/;
		var valid = false;
		var submittedEmail = loginEmail;
		var submittedPassword = loginPassword;
		if (
			submittedEmail !== '' &&
			submittedPassword !== '' &&
			emailtest.test(submittedEmail)
		) {
			valid = true;
		}
		if (valid) {
			// try to login user....
			console.log('Try to login....');
			const signInUser = async () => {
				try {
					// set the url
					const url = `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${APIusersKey}`;
					// request data object
					const data = {
						email: submittedEmail,
						password: submittedPassword,
						returnSecureToken: true,
					};

					// set the headers
					const config = {
						headers: {
							'Content-Type': 'application/json',
						},
					};

					const res = await axios.post(url, data, config);
					console.log(res.data);

					if (
						res.data.idToken !== '' &&
						res.data.refreshToken !== '' &&
						res.data.localId !== '' &&
						res.data.expiresIn !== ''
					) {
						//##### Check if user is active....
						const setUser = (isItActive) => {
							if (isItActive) {
								try {
									const expires = (res.data.expiresIn || 60 * 60) * 1000;
									//const expires = 10000;
									const inOneHour = new Date(new Date().getTime() + expires);

									// you will have the exact same setters in your Login page/app too
									Cookies.set('access_token', res.data.idToken, {
										expires: inOneHour,
									});
									Cookies.set('access_id', res.data.localId, {
										expires: inOneHour,
									});
									Cookies.set('refresh_token', res.data.refreshToken);
									auth.login();
									history.push('/admin/dashboard');
								} catch (error) {
									warningAlert();
									setloginPassword('');
								}
							} else {
								warningAlert();
								setloginPassword('');
							}
						};
						const getUserDetails = (userId) => {
							var myActive = '';
							try {
								// set the url
								const myUrl = `${APIurl}users.json?orderBy="userUid"&equalTo="${userId}"`;
								axios.get(myUrl).then((res) => {
									console.log(res.data);
									const myData = res.data;
									for (var key1 in myData) {
										for (var key2 in myData[key1]) {
											//console.log('display....' + [key1]);
											myActive = myData[key1].active;
										}
									}
									setUser(myActive);
								});
							} catch (err) {
								console.log(err);
								alert('something went wrong...');
							}
						};
						getUserDetails(res.data.localId);
					}
				} catch (err) {
					//console.error(err);
					warningAlert();
					setloginPassword('');
					console.log(err);
				}
			};
			signInUser();
		} else {
			warningAlert();
			setloginPassword('');
		}
		console.log(valid);
	}
	return (
		<div className={classes.container}>
			{alert}
			<GridContainer justify="center">
				<GridItem xs={12} sm={6} md={4}>
					<form onSubmit={handleSubmit}>
						<Card login className={classes[cardAnimaton]}>
							<CardHeader
								className={`${classes.cardHeader} ${classes.textCenter}`}
								color="rose"
							>
								<h4 className={classes.cardTitle}>Log in</h4>
								<div className={classes.socialLine}>
									{[
										'fab fa-facebook-square',
										'fab fa-twitter',
										'fab fa-google-plus',
									].map((prop, key) => {
										return (
											<Button
												color="transparent"
												justIcon
												key={key}
												className={classes.customButtonClass}
											>
												<i className={prop} />
											</Button>
										);
									})}
								</div>
							</CardHeader>
							<CardBody>
								<CustomInput
									success={loginEmailState === 'success'}
									error={loginEmailState === 'error'}
									id="loginemail"
									formControlProps={{
										fullWidth: true,
										className: classes.customFormControlClasses,
									}}
									inputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<Email className={classes.inputAdornmentIcon} />
											</InputAdornment>
										),
										onChange: (event) => {
											if (verifyEmail(event.target.value)) {
												setloginEmailState('success');
											} else {
												setloginEmailState('error');
											}
											setloginEmail(event.target.value);
										},
										type: 'email',
										placeholder: '* Email...',
									}}
								/>
								<CustomInput
									success={loginPasswordState === 'success'}
									error={loginPasswordState === 'error'}
									id="loginpassword"
									formControlProps={{
										fullWidth: true,
										className: classes.customFormControlClasses,
									}}
									inputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<Icon className={classes.inputAdornmentIcon}>
													lock_outline
												</Icon>
											</InputAdornment>
										),
										onChange: (event) => {
											if (verifyLength(event.target.value, 7)) {
												setloginPasswordState('success');
											} else {
												setloginPasswordState('error');
											}
											setloginPassword(event.target.value);
										},
										type: 'password',
										autoComplete: 'off',
										placeholder: '* Password...',
									}}
								/>
							</CardBody>
							<CardFooter className={classes.justifyContentCenter}>
								<Button
									type="submit"
									color="rose"
									simple
									size="lg"
									block
									onClick={loginClick}
								>
									Let{"'"}s Go
								</Button>
							</CardFooter>
						</Card>
					</form>
				</GridItem>
			</GridContainer>
		</div>
	);
}
