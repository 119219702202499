import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { APIurl, APIusersKey } from '../../variables/utils';

import auth from '../../auth';
import Cookies from 'js-cookie';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Router, Route, Redirect, useHistory } from 'react-router-dom';

// react plugin for creating charts
import ChartistGraph from 'react-chartist';
// react plugin for creating vector maps
import { VectorMap } from 'react-jvectormap';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from '@material-ui/icons/Store';
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from '@material-ui/icons/Warning';
import DateRange from '@material-ui/icons/DateRange';
import LocalOffer from '@material-ui/icons/LocalOffer';
import Update from '@material-ui/icons/Update';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import AccessTime from '@material-ui/icons/AccessTime';
import Refresh from '@material-ui/icons/Refresh';
import Edit from '@material-ui/icons/Edit';
import Place from '@material-ui/icons/Place';
import ArtTrack from '@material-ui/icons/ArtTrack';
import Language from '@material-ui/icons/Language';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Table from 'components/Table/Table.js';
import Button from 'components/CustomButtons/Button.js';
import Danger from 'components/Typography/Danger.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import {
	dailySalesChart,
	emailsSubscriptionChart,
	completedTasksChart,
} from 'variables/charts';

import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js';

import priceImage1 from 'assets/img/card-2.jpeg';
import priceImage2 from 'assets/img/card-3.jpeg';
import priceImage3 from 'assets/img/card-1.jpeg';

const useStyles = makeStyles(styles);

export default function Dashboard() {
	const [registerFirstName, setregisterFirstName] = React.useState('');
	const [registerLastName, setregisterLastName] = React.useState('');
	const [registerUserType, setregisterUserType] = React.useState('');
	const [registerUID, setregisterUID] = React.useState('');
	const [totalApproved, settotalApproved] = React.useState('');
	const [totalNotApproved, settotalNotApproved] = React.useState('');
	const [totalDraft, settotalDraft] = React.useState('');
	const [renderPosts, setrenderPosts] = React.useState([]);
	const [slatkiAlert, setslatkiAlert] = React.useState(null);
	const history = useHistory();
	const classes = useStyles();
	var sortBy = require('array-sort-by');

	const warningWithConfirmMessage = (MyId) => {
		setslatkiAlert(
			<SweetAlert
				warning
				style={{ display: 'block', marginTop: '-100px' }}
				title="Are you sure?"
				onConfirm={() => successDelete(MyId)}
				onCancel={() => hideAlert()}
				confirmBtnCssClass={classes.button + ' ' + classes.success}
				cancelBtnCssClass={classes.button + ' ' + classes.danger}
				confirmBtnText="Yes, delete it!"
				cancelBtnText="Cancel"
				showCancel
			>
				You will not be able to recover this record!
			</SweetAlert>
		);
	};
	const successDelete = (delId) => {
		//delete here/////
		const myToken = Cookies.get('access_token');
		if (myToken === undefined) {
			history.push('/auth/login-page');
		}
		console.log('To delete...' + delId);
		//GET images that need to be deleted...
		const getImages = (UID) => {
			var image1 = '';
			var image2 = '';
			var image3 = '';
			var image4 = '';
			var parsedImages = [];
			try {
				// set the url
				const myUrl = `${APIurl}products/${UID}.json`;
				axios.get(myUrl).then((res) => {
					console.log(res.data);
					const myData = res.data;
					image1 = myData.image;
					image2 = myData.image2;
					image3 = myData.image3;
					image4 = myData.image4;
					//construct array....
					if (image1 !== '' && image1 !== undefined) {
						var res1 = image1.replace('https://solelylocal.imgix.net/', '');
						var res2 = res1.replace('?fit=crop&w=840&q=80', '');
						if (
							res2 !== 'help1.jpg' &&
							res2 !== 'nonProfit1.jpg' &&
							res2 !== 'student1.jpg' &&
							res2 !== 'thankyou1.jpg' &&
							res2 !== 'Volunteers1.jpg'
						) {
							parsedImages.push(res2);
						}
					}
					if (image2 !== '' && image2 !== undefined) {
						var res1 = image2.replace('https://solelylocal.imgix.net/', '');
						var res2 = res1.replace('?fit=crop&w=840&q=80', '');
						if (
							res2 !== 'help1.jpg' &&
							res2 !== 'nonProfit1.jpg' &&
							res2 !== 'student1.jpg' &&
							res2 !== 'thankyou1.jpg' &&
							res2 !== 'Volunteers1.jpg'
						) {
							parsedImages.push(res2);
						}
					}
					if (image3 !== '' && image3 !== undefined) {
						var res1 = image3.replace('https://solelylocal.imgix.net/', '');
						var res2 = res1.replace('?fit=crop&w=840&q=80', '');
						if (
							res2 !== 'help1.jpg' &&
							res2 !== 'nonProfit1.jpg' &&
							res2 !== 'student1.jpg' &&
							res2 !== 'thankyou1.jpg' &&
							res2 !== 'Volunteers1.jpg'
						) {
							parsedImages.push(res2);
						}
					}
					if (image4 !== '' && image4 !== undefined) {
						var res1 = image4.replace('https://solelylocal.imgix.net/', '');
						var res2 = res1.replace('?fit=crop&w=840&q=80', '');
						if (
							res2 !== 'help1.jpg' &&
							res2 !== 'nonProfit1.jpg' &&
							res2 !== 'student1.jpg' &&
							res2 !== 'thankyou1.jpg' &&
							res2 !== 'Volunteers1.jpg'
						) {
							parsedImages.push(res2);
						}
					}

					if (parsedImages.length > 0) {
						// something to delete....
						deleteImages(parsedImages, UID);
					} else {
						// nothing to delete... delete record straight....
						deletePost(UID);
					}
				});
			} catch (err) {
				console.log(err);
				alert('something went wrong...');
			}
		};
		const deleteImages = (images, idRecord) => {
			console.log('deleting images....');
			for (var i = 0; i < images.length; i++) {
				//##### DELETING IMAGE ###########
				try {
					var base_url = 'https://www.solelylocal.com/deleteImage.php';
					var fd = new FormData();
					fd.append('delete_file', images[i]);
					axios.post(base_url, fd).then((res) => {
						console.log(res);
						console.log('IMA STATUS....' + res.data.status);
					});
				} catch (err) {
					//console.error(err);
					console.log(
						'There was an error deleting file from web server: ' + err
					);
				}
				//###### END DELETING IMAGE ############################
			}
			deletePost(idRecord);
		};
		const deletePost = (recId) => {
			//alert('delete post....');
			const myToken = Cookies.get('access_token');
			if (myToken === undefined) {
				history.push('/auth/login-page');
			}
			try {
				// set the url
				const myUrl = `${APIurl}products/${recId}.json?auth=${myToken}`;
				// request data object
				axios.delete(myUrl).then((res) => {
					console.log('Deleted Post finaly!!!!' + res.data);
					reDoRegions();
				});
			} catch (err) {
				console.log('Error Deleting Post!!!!' + err);
			}
		};
		/////############### This is marking regions to inactive... ###############
		const reDoRegions = () => {
			var myPostsApproved = [];
			var uniqMyPostsApproved = [];
			try {
				// set the url
				const myUrl = `${APIurl}products.json?orderBy="approved"&equalTo=1`;
				//alert(myUrl);
				axios.get(myUrl).then((res) => {
					//console.log(res.data);
					const myData = res.data;
					for (var key1 in myData) {
						myPostsApproved.push(myData[key1].region);
					}
					uniqMyPostsApproved = [...new Set(myPostsApproved)];
					console.log(uniqMyPostsApproved);
					reDoRegions2(uniqMyPostsApproved);
				});
			} catch (err) {
				console.log(err);
				alert('something went wrong...');
			}
		};

		const reDoRegions2 = (posts) => {
			const myToken = Cookies.get('access_token');
			if (myToken === undefined) {
				history.push('/auth/login-page');
			}
			try {
				// set the url
				const myUrl = `${APIurl}regions.json`;
				//alert(myUrl);
				axios.get(myUrl).then((res) => {
					//console.log(res.data);
					const myData = res.data;
					for (var key1 in myData) {
						var currRec = key1;
						if (posts.includes(myData[key1].title)) {
							try {
								// set the url
								const myUrl = `${APIurl}regions/${currRec}.json?auth=${myToken}`;
								// request data object
								const upData = {};
								upData.active = 1;
								// set the headers
								const config = {
									headers: {
										'Content-Type': 'application/x-www-form-urlencoded',
									},
								};
								axios.patch(myUrl, upData, config).then((res) => {
									console.log('Updated Region to TRUE ...');
								});
							} catch (err) {
								console.log('Error Updating Post!!!!' + err);
							}
						} else {
							try {
								// set the url
								const myUrl = `${APIurl}regions/${currRec}.json?auth=${myToken}`;
								// request data object
								const upData = {};
								upData.active = 0;
								// set the headers
								const config = {
									headers: {
										'Content-Type': 'application/x-www-form-urlencoded',
									},
								};
								axios.patch(myUrl, upData, config).then((res) => {
									console.log('Updated Region to FALSE...');
								});
							} catch (err) {
								console.log('Error Updating Post!!!!' + err);
							}
						}
					}
					setTimeout(showConfirm, 3000);

					//showConfirm();
				});
			} catch (err) {
				console.log(err);
				alert('something went wrong...');
			}
		};
		/////############### END???? This is marking regions to inactive... ###############

		getImages(delId);
		const showConfirm = () => {
			setslatkiAlert(
				<SweetAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title="Deleted!"
					onConfirm={() => hideAlert2()}
					onCancel={() => hideAlert2()}
					confirmBtnCssClass={classes.button + ' ' + classes.success}
				>
					Your Post has been deleted.
				</SweetAlert>
			);
		};
	};
	const navigateToView = (e, prcId) => {
		e.preventDefault();
		history.push(`/admin/post?item=${prcId}`);
	};

	const navigateToEdit = (prcId, prcCat) => {
		console.log(prcCat);
		switch (prcCat) {
			case 'Community Partner':
				var url = `/admin/community-partner?item=${prcId}`;
				break;
			case 'Local Business':
				var url = `/admin/local-business?item=${prcId}`;
				break;
			case 'Association':
				var url = `/admin/association?item=${prcId}`;
				break;
			case 'Non Profit':
				var url = `/admin/non-profit?item=${prcId}`;
				break;
			case 'Help':
				var url = `/admin/help?item=${prcId}`;
				break;
			case 'Volunteer':
				var url = `/admin/volunteer?item=${prcId}`;
				break;
			case 'Student':
				var url = `/admin/student?item=${prcId}`;
				break;
			case 'Thank You':
				var url = `/admin/thank-you?item=${prcId}`;
				break;
			default:
				return false;
		}
		history.push(url);
	};

	const hideAlert = () => {
		setslatkiAlert(null);
	};
	const hideAlert2 = () => {
		setslatkiAlert(null);
		history.push('/admin/');
	};
	//## Get some user info here from DB.....
	useEffect(() => {
		const getUserPosts = (UID) => {
			try {
				var sortBy = require('array-sort-by');
				var approvedCount = 0;
				var NotApprovedCount = 0;
				var draftCount = 0;
				var myPostsUnsorted = [];
				const months = [
					'JAN',
					'FEB',
					'MAR',
					'APR',
					'MAY',
					'JUN',
					'JUL',
					'AUG',
					'SEP',
					'OCT',
					'NOV',
					'DEC',
				];
				// set the url
				const myUrl = `${APIurl}products.json?orderBy="listingPersonId"&equalTo="${UID}"`;
				//alert(myUrl);
				axios.get(myUrl).then((res) => {
					console.log(res.data);
					const myData = res.data;
					for (var key1 in myData) {
						if (myData[key1].approved == 0) {
							NotApprovedCount = NotApprovedCount + 1;
						} else if (myData[key1].approved == 1) {
							approvedCount = approvedCount + 1;
						} else {
							draftCount = draftCount + 1;
						}
						var current_datetime = new Date(myData[key1].modifyDate);
						var formatted_date =
							current_datetime.getDate() +
							'-' +
							months[current_datetime.getMonth()] +
							'-' +
							current_datetime.getFullYear();

						var filteredPosts = new Object();
						filteredPosts.postId = key1;
						filteredPosts.createDate = formatted_date;
						filteredPosts.image = myData[key1].image;
						filteredPosts.price = myData[key1].price;
						filteredPosts.category = myData[key1].category;
						filteredPosts.region = myData[key1].region;
						filteredPosts.city = myData[key1].city;
						filteredPosts.title = myData[key1].title;
						filteredPosts.approved = myData[key1].approved;
						myPostsUnsorted.push(filteredPosts);
					}
					settotalApproved(approvedCount);
					settotalNotApproved(NotApprovedCount);
					settotalDraft(draftCount);
					const myPostsSorted = sortBy(
						myPostsUnsorted,
						(s) => -new Date(s.createDate)
					);
					setrenderPosts(myPostsSorted);
					//console.log(JSON.stringify(myPostsSorted));
				});
			} catch (err) {
				console.log(err);
				alert('something went wrong...');
			}
		};

		const getUserDetails = (userId) => {
			try {
				// set the url
				const myUrl = `${APIurl}users.json?orderBy="userUid"&equalTo="${userId}"`;

				axios.get(myUrl).then((res) => {
					console.log(res.data);
					const myData = res.data;
					for (var key1 in myData) {
						//for (var key2 in myData[key1]) {
						//console.log(myData[key1].userUid);
						setregisterUserType(myData[key1].userType);
						setregisterFirstName(myData[key1].firstName);
						setregisterLastName(myData[key1].lastName);
						setregisterUID(myData[key1].userUid);
						//}
						getUserPosts(myData[key1].userUid);
					}
				});
			} catch (err) {
				console.log(err);
				alert('something went wrong...');
			}
		};

		//#### get user from token...
		const GetUserId = () => {
			const myToken = Cookies.get('access_token');
			const myID = Cookies.get('access_id');
			if (myToken === undefined) {
				history.push('/auth/login-page');
			} else {
				getUserDetails(myID);
				/*
				try {
					// set the url
					const url =
						'https://identitytoolkit.googleapis.com/v1/accounts:lookup?key=AIzaSyA7l2dD4nbZTKQ8xaq7iho4nhVPYXMqbQA';
					// request data object
					const data = {
						idToken: myToken,
					};

					// set the headers
					const config = {
						headers: {
							'Content-Type': 'application/json',
						},
					};

					axios.post(url, data, config).then((res) => {
						console.log(res.data.users);
						getUserDetails(res.data.users[0].localId);
					});
				} catch (err) {
					console.log(err);
					alert('something went wrong...');
				}
				*/
			}
		};

		GetUserId();
	}, []);

	return (
		<div>
			{slatkiAlert}
			<GridContainer>
				<GridItem xs={12} sm={6} md={6} lg={3}>
					<Card>
						<CardHeader color="success" stats icon>
							<CardIcon color="success">
								<Store />
							</CardIcon>
							<p className={classes.cardCategory}>Total Approved Posts</p>
							<h3 className={classes.cardTitle}>{totalApproved}</h3>
						</CardHeader>
						<CardFooter stats>
							<div className={classes.stats}>
								<DateRange />
								Last 24 Hours
							</div>
						</CardFooter>
					</Card>
				</GridItem>
				<GridItem xs={12} sm={6} md={6} lg={3}>
					<Card>
						<CardHeader color="danger" stats icon>
							<CardIcon color="danger">
								<Icon>info_outline</Icon>
							</CardIcon>
							<p className={classes.cardCategory}>Waiting for Approvals</p>
							<h3 className={classes.cardTitle}>{totalNotApproved}</h3>
						</CardHeader>
						<CardFooter stats>
							<div className={classes.stats}>
								<Update />
								Just Updated
							</div>
						</CardFooter>
					</Card>
				</GridItem>
				<GridItem xs={12} sm={6} md={6} lg={3}>
					<Card>
						<CardHeader color="info" stats icon>
							<CardIcon color="info">
								<Language />
							</CardIcon>
							<p className={classes.cardCategory}>Drafts</p>
							<h3 className={classes.cardTitle}>{totalDraft}</h3>
						</CardHeader>
						<CardFooter stats>
							<div className={classes.stats}>
								<Update />
								Just Updated
							</div>
						</CardFooter>
					</Card>
				</GridItem>
			</GridContainer>

			<h3>Manage Posts</h3>
			<br />
			<GridContainer>
				{renderPosts.map((item, index) => (
					<GridItem key={index} xs={12} sm={12} md={4}>
						<Card product className={classes.cardHover}>
							<CardHeader image className={classes.cardHeaderHover}>
								<a
									href="#admin"
									onClick={(e) => navigateToView(e, item.postId)}
								>
									<img src={item.image} alt="..." />
								</a>
							</CardHeader>
							<CardBody>
								<div className={classes.cardHoverUnder}>
									<Tooltip
										id="tooltip-top"
										title="View"
										placement="bottom"
										classes={{ tooltip: classes.tooltip }}
									>
										<Button
											color="transparent"
											simple
											justIcon
											onClick={(e) => navigateToView(e, item.postId)}
										>
											<ArtTrack className={classes.underChartIcons} />
										</Button>
									</Tooltip>
									<Tooltip
										id="tooltip-top"
										title="Edit"
										placement="bottom"
										classes={{ tooltip: classes.tooltip }}
									>
										<Button
											color="success"
											simple
											justIcon
											onClick={() => navigateToEdit(item.postId, item.category)}
										>
											<Refresh className={classes.underChartIcons} />
										</Button>
									</Tooltip>
									<Tooltip
										id="tooltip-top"
										title="Remove"
										placement="bottom"
										classes={{ tooltip: classes.tooltip }}
									>
										<Button
											color="danger"
											simple
											justIcon
											onClick={() => warningWithConfirmMessage(item.postId)}
										>
											<Edit className={classes.underChartIcons} />
										</Button>
									</Tooltip>
								</div>
								<h4 className={classes.cardProductTitle}>
									<a
										href="#admin"
										onClick={(e) => navigateToView(e, item.postId)}
									>
										{item.title}
									</a>
								</h4>
								<p className={classes.cardProductDesciprion}>{item.price}</p>
								<hr></hr>
								<p className={classes.cardProductDesciprion}>
									Posted on: <strong>{item.createDate}</strong>
								</p>
								{item.approved == 0 ? (
									<p className={classes.cardProductDesciprion2}>
										Not Approved Yet
									</p>
								) : null}
								{item.approved == 2 ? (
									<p className={classes.cardProductDesciprion3}>Draft</p>
								) : null}
							</CardBody>
							<CardFooter product>
								<div className={classes.price}>
									<h5>{item.category}</h5>
								</div>
								<div className={`${classes.stats} ${classes.productStats}`}>
									<Place /> {item.city}, {item.region}
								</div>
							</CardFooter>
						</Card>
					</GridItem>
				))}
			</GridContainer>
		</div>
	);
}
