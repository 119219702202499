import Buttons from 'views/Components/Buttons.js';
import Calendar from 'views/Calendar/Calendar.js';
import Charts from 'views/Charts/Charts.js';
import Dashboard from 'views/Dashboard/Dashboard.js';
import Regions from 'views/Dashboard/Regions.js';
import Region from 'views/Pages/Region.js';
import ErrorPage from 'views/Pages/ErrorPage.js';
import ExtendedForms from 'views/Forms/ExtendedForms.js';
import ExtendedTables from 'views/Tables/ExtendedTables.js';
import FullScreenMap from 'views/Maps/FullScreenMap.js';
import GoogleMaps from 'views/Maps/GoogleMaps.js';
import GridSystem from 'views/Components/GridSystem.js';
import Icons from 'views/Components/Icons.js';
import LockScreenPage from 'views/Pages/LockScreenPage.js';
import LoginPage from 'views/Pages/LoginPage.js';
import Notifications from 'views/Components/Notifications.js';
import Panels from 'views/Components/Panels.js';
import Post from 'views/Components/Post.js';
import PricingPage from 'views/Pages/PricingPage.js';
import TermsPage from 'views/Pages/Terms.js';
import PrivacyPage from 'views/Pages/Privacy.js';
import RTLSupport from 'views/Pages/RTLSupport.js';
import ReactTables from 'views/Tables/ReactTables.js';
import ReactTables2 from 'views/Tables/ReactTables2.js';
import Users from 'views/Tables/Users.js';
import RegisterPage from 'views/Pages/RegisterPage.js';
import RegularForms from 'views/Forms/RegularForms.js';
import RegularTables from 'views/Tables/RegularTables.js';
import SweetAlert from 'views/Components/SweetAlert.js';
import TimelinePage from 'views/Pages/Timeline.js';
import Typography from 'views/Components/Typography.js';
import UserProfile from 'views/Pages/UserProfile.js';
import UpdateCredentials from 'views/Pages/UpdateUserCredentials.js';
import ValidationForms from 'views/Forms/ValidationForms.js';
import VectorMap from 'views/Maps/VectorMap.js';
import Widgets from 'views/Widgets/Widgets.js';
import Wizard from 'views/Forms/Wizard.js';
import CommunityPartner from 'views/Forms/CommunityPartner.js';
import LocalBusiness from 'views/Forms/LocalBusiness.js';
import Association from 'views/Forms/Association.js';
import NonProfit from 'views/Forms/NonProfit.js';
import Help from 'views/Forms/Help.js';
import Volunteer from 'views/Forms/Volunteer.js';
import Student from 'views/Forms/Student.js';
import ThankYou from 'views/Forms/ThankYou.js';

// @material-ui/icons
import Apps from '@material-ui/icons/Apps';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DateRange from '@material-ui/icons/DateRange';
import GridOn from '@material-ui/icons/GridOn';
import Image from '@material-ui/icons/Image';
import Place from '@material-ui/icons/Place';
import Timeline from '@material-ui/icons/Timeline';
import WidgetsIcon from '@material-ui/icons/Widgets';

var dashRoutes = [
	{
		protected: false,
		path: '/dashboard',
		name: 'Dashboard',
		rtlName: 'لوحة القيادة',
		icon: DashboardIcon,
		component: Dashboard,
		layout: '/admin',
	},
	{
		redirect: true,
		protected: false,
		path: '/user-page',
		name: 'User Profile',
		rtlName: 'ملف تعريفي للمستخدم',
		icon: Image,
		rtlMini: 'شع',
		component: UserProfile,
		layout: '/admin',
	},
	{
		redirect: true,
		protected: false,
		path: '/user-credentials',
		name: 'Update Credentials',
		rtlName: 'ملف تعريفي للمستخدم',
		icon: Image,
		rtlMini: 'شع',
		component: UpdateCredentials,
		layout: '/admin',
	},

	{
		protected: false,
		collapse: true,
		name: 'Add Post',
		rtlName: 'صفحات',
		icon: 'content_paste',
		state: 'pageCollapse',
		views: [
			{
				path: '/community-partner',
				name: 'Community Partners',
				rtlName: 'عالتسعير',
				mini: 'CP',
				rtlMini: 'ع',
				component: CommunityPartner,
				layout: '/admin',
			},
			{
				path: '/local-business',
				name: 'Local Business',
				rtlName: 'عالتسعير',
				mini: 'LB',
				rtlMini: 'ع',
				component: LocalBusiness,
				layout: '/admin',
			},
			{
				path: '/association',
				name: 'Association',
				rtlName: 'عالتسعير',
				mini: 'AS',
				rtlMini: 'ع',
				component: Association,
				layout: '/admin',
			},
			{
				path: '/non-profit',
				name: 'Non Profit',
				rtlName: 'عالتسعير',
				mini: 'NP',
				rtlMini: 'ع',
				component: NonProfit,
				layout: '/admin',
			},
			{
				path: '/help',
				name: 'Help Needed',
				rtlName: 'عالتسعير',
				mini: 'HP',
				rtlMini: 'ع',
				component: Help,
				layout: '/admin',
			},
			{
				path: '/volunteer',
				name: 'Volunteer',
				rtlName: 'عالتسعير',
				mini: 'VL',
				rtlMini: 'ع',
				component: Volunteer,
				layout: '/admin',
			},
			{
				path: '/student',
				name: 'Student',
				rtlName: 'عالتسعير',
				mini: 'ST',
				rtlMini: 'ع',
				component: Student,
				layout: '/admin',
			},
			{
				path: '/thank-you',
				name: 'Thank You',
				rtlName: 'عالتسعير',
				mini: 'TY',
				rtlMini: 'ع',
				component: ThankYou,
				layout: '/admin',
			},
		],
	},
	{
		protected: true,
		collapse: true,
		name: 'Admin',
		rtlName: 'صفحات',
		icon: GridOn,
		state: 'adminCollapse',
		views: [
			{
				path: '/unapproved-posts',
				name: 'Posts Waiting Approval',
				rtlName: 'عالتسعير',
				mini: 'WA',
				rtlMini: 'ع',
				component: ReactTables,
				layout: '/admin',
			},
			{
				path: '/approved-posts',
				name: 'Approved Posts',
				rtlName: 'عالتسعير',
				mini: 'AP',
				rtlMini: 'ع',
				component: ReactTables2,
				layout: '/admin',
			},
			{
				path: '/users',
				name: 'Users',
				rtlName: 'عالتسعير',
				mini: 'US',
				rtlMini: 'ع',
				component: Users,
				layout: '/admin',
			},
			{
				collapse: true,
				name: 'Regions',
				rtlName: 'انهيار متعدد المستويات',
				mini: 'RG',
				rtlMini: 'ر',
				state: 'multiCollapse',
				views: [
					{
						path: '/regions',
						name: 'Regions',
						rtlName: 'وصفت',
						mini: 'R',
						rtlMini: 'ب',
						component: Regions,
						layout: '/admin',
					},
					{
						path: '/add-region',
						name: 'Add-Region',
						rtlName: 'وصفت',
						mini: 'AR',
						rtlMini: 'ب',
						component: Region,
						layout: '/admin',
					},
				],
			},
		],
	},

	{
		redirect: true,
		collapse: true,
		name: 'Pages',
		rtlName: 'صفحات',
		icon: Image,
		state: 'pageCollapse',
		views: [
			{
				path: '/pricing-page',
				name: 'Pricing Page',
				rtlName: 'عالتسعير',
				mini: 'PP',
				rtlMini: 'ع',
				component: PricingPage,
				layout: '/auth',
			},
			{
				path: '/privacy-page',
				name: 'Privacy Policy',
				rtlName: 'عالتسعير',
				mini: 'PP',
				rtlMini: 'ع',
				component: PrivacyPage,
				layout: '/auth',
			},
			{
				path: '/terms-page',
				name: 'Terms of Service',
				rtlName: 'عالتسعير',
				mini: 'PP',
				rtlMini: 'ع',
				component: TermsPage,
				layout: '/auth',
			},
			{
				path: '/rtl-support-page',
				name: 'RTL Support',
				rtlName: 'صودعم رتل',
				mini: 'RS',
				rtlMini: 'صو',
				component: RTLSupport,
				layout: '/rtl',
			},
			{
				path: '/timeline-page',
				name: 'Timeline Page',
				rtlName: 'تيالجدول الزمني',
				mini: 'T',
				rtlMini: 'تي',
				component: TimelinePage,
				layout: '/admin',
			},
			{
				path: '/login-page',
				name: 'Login Page',
				rtlName: 'هعذاتسجيل الدخول',
				mini: 'L',
				rtlMini: 'هعذا',
				component: LoginPage,
				layout: '/auth',
			},
			{
				path: '/register-page',
				name: 'Register Page',
				rtlName: 'تسجيل',
				mini: 'R',
				rtlMini: 'صع',
				component: RegisterPage,
				layout: '/auth',
			},
			{
				path: '/lock-screen-page',
				name: 'Lock Screen Page',
				rtlName: 'اقفل الشاشة',
				mini: 'LS',
				rtlMini: 'هذاع',
				component: LockScreenPage,
				layout: '/auth',
			},
			{
				path: '/user-page',
				name: 'User Profile',
				rtlName: 'ملف تعريفي للمستخدم',
				mini: 'UP',
				rtlMini: 'شع',
				component: UserProfile,
				layout: '/admin',
			},
			{
				path: '/user-credentials',
				name: 'Update Credentials',
				rtlName: 'ملف تعريفي للمستخدم',
				mini: 'UP',
				rtlMini: 'شع',
				component: UpdateCredentials,
				layout: '/admin',
			},
			{
				path: '/error-page',
				name: 'Error Page',
				rtlName: 'صفحة الخطأ',
				mini: 'E',
				rtlMini: 'البريد',
				component: ErrorPage,
				layout: '/auth',
			},
		],
	},
	{
		redirect: true,
		collapse: true,
		name: 'Components',
		rtlName: 'المكونات',
		icon: Apps,
		state: 'componentsCollapse',
		views: [
			{
				collapse: true,
				name: 'Multi Level Collapse',
				rtlName: 'انهيار متعدد المستويات',
				mini: 'MC',
				rtlMini: 'ر',
				state: 'multiCollapse',
				views: [
					{
						path: '/buttons',
						name: 'Buttons',
						rtlName: 'وصفت',
						mini: 'B',
						rtlMini: 'ب',
						component: Buttons,
						layout: '/admin',
					},
				],
			},
			{
				path: '/buttons',
				name: 'Buttons',
				rtlName: 'وصفت',
				mini: 'B',
				rtlMini: 'ب',
				component: Buttons,
				layout: '/admin',
			},
			{
				path: '/grid-system',
				name: 'Grid System',
				rtlName: 'نظام الشبكة',
				mini: 'GS',
				rtlMini: 'زو',
				component: GridSystem,
				layout: '/admin',
			},
			{
				path: '/panels',
				name: 'Panels',
				rtlName: 'لوحات',
				mini: 'P',
				rtlMini: 'ع',
				component: Panels,
				layout: '/admin',
			},
			{
				path: '/post',
				name: 'Post',
				rtlName: 'لوحات',
				mini: 'P',
				rtlMini: 'ع',
				component: Post,
				layout: '/admin',
			},
			{
				path: '/sweet-alert',
				name: 'Sweet Alert',
				rtlName: 'الحلو تنبيه',
				mini: 'SA',
				rtlMini: 'ومن',
				component: SweetAlert,
				layout: '/admin',
			},
			{
				path: '/notifications',
				name: 'Notifications',
				rtlName: 'إخطارات',
				mini: 'N',
				rtlMini: 'ن',
				component: Notifications,
				layout: '/admin',
			},
			{
				path: '/icons',
				name: 'Icons',
				rtlName: 'الرموز',
				mini: 'I',
				rtlMini: 'و',
				component: Icons,
				layout: '/admin',
			},
			{
				path: '/typography',
				name: 'Typography',
				rtlName: 'طباعة',
				mini: 'T',
				rtlMini: 'ر',
				component: Typography,
				layout: '/admin',
			},
		],
	},
	{
		redirect: true,
		collapse: true,
		name: 'Forms',
		rtlName: 'إستمارات',
		icon: 'content_paste',
		state: 'formsCollapse',
		views: [
			{
				path: '/regular-forms',
				name: 'Regular Forms',
				rtlName: 'أشكال عادية',
				mini: 'RF',
				rtlMini: 'صو',
				component: RegularForms,
				layout: '/admin',
			},
			{
				path: '/extended-forms',
				name: 'Extended Forms',
				rtlName: 'نماذج موسعة',
				mini: 'EF',
				rtlMini: 'هوو',
				component: ExtendedForms,
				layout: '/admin',
			},
			{
				path: '/validation-forms',
				name: 'Validation Forms',
				rtlName: 'نماذج التحقق من الصحة',
				mini: 'VF',
				rtlMini: 'تو',
				component: ValidationForms,
				layout: '/admin',
			},
			{
				path: '/wizard',
				name: 'Wizard',
				rtlName: 'ساحر',
				mini: 'W',
				rtlMini: 'ث',
				component: Wizard,
				layout: '/admin',
			},
		],
	},
	{
		redirect: true,
		collapse: true,
		name: 'Tables',
		rtlName: 'الجداول',
		icon: GridOn,
		state: 'tablesCollapse',
		views: [
			{
				path: '/regular-tables',
				name: 'Regular Tables',
				rtlName: 'طاولات عادية',
				mini: 'RT',
				rtlMini: 'صر',
				component: RegularTables,
				layout: '/admin',
			},
			{
				path: '/extended-tables',
				name: 'Extended Tables',
				rtlName: 'جداول ممتدة',
				mini: 'ET',
				rtlMini: 'هور',
				component: ExtendedTables,
				layout: '/admin',
			},
			{
				path: '/react-tables',
				name: 'React Tables',
				rtlName: 'رد فعل الطاولة',
				mini: 'RT',
				rtlMini: 'در',
				component: ReactTables,
				layout: '/admin',
			},
		],
	},
];
export default dashRoutes;
