/*!

=========================================================
* Material Dashboard PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

import axios from 'axios';
import { APIurl, APIusersKey } from 'variables/utils';
import auth from './auth';
import Cookies from 'js-cookie';

import AuthLayout from 'layouts/Auth.js';
import RtlLayout from 'layouts/RTL.js';
import AdminLayout from 'layouts/Admin.js';
import { ProtectedRoute } from './protected-route';

import 'assets/scss/material-dashboard-pro-react.scss?v=1.8.0';

const hist = createBrowserHistory();

const reSignInUser = async (refToken) => {
	try {
		// set the url
		const url = `https://securetoken.googleapis.com/v1/token?key=${APIusersKey}`;
		// request data object
		const data = {
			grant_type: 'refresh_token',
			refresh_token: refToken,
		};

		// set the headers
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};

		const res = await axios.post(url, data, config);
		console.log(res.data);
		if (
			res.data.access_token !== '' &&
			res.data.refresh_token !== '' &&
			res.data.user_id !== '' &&
			res.data.expires_in !== ''
		) {
			try {
				const expires = (res.data.expiresIn || 60 * 60) * 1000;
				//const expires = 10000;
				const inOneHour = new Date(new Date().getTime() + expires);

				// you will have the exact same setters in your Login page/app too
				Cookies.set('access_token', res.data.access_token, {
					expires: inOneHour,
				});
				Cookies.set('access_id', res.data.user_id, {
					expires: inOneHour,
				});
				Cookies.set('refresh_token', res.data.refresh_token);
				auth.login();
				hist.push('/admin/dashboard');
			} catch (error) {
				console.log(error);
			}
		}
	} catch (err) {
		console.log(err);
	}
};

const getParameterByName = (name, url) => {
	if (!url) url = window.location.href;
	name = name.replace(/[\[\]]/g, '\\$&');
	var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
		results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return '';
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
};
const foo = getParameterByName('myToken');
if (foo !== '' && foo !== null && foo !== undefined) {
	// OK User is comming from web page... log them in....
	reSignInUser(foo);
}
ReactDOM.render(
	<Router history={hist}>
		<Switch>
			<Route path="/auth" component={AuthLayout} />
			<ProtectedRoute path="/admin" component={AdminLayout} />
			<Redirect from="/" to="/auth/login" />
		</Switch>
	</Router>,
	document.getElementById('root')
);

/*
ReactDOM.render(
	<Router history={hist}>
		<Switch>
			<Route path="/rtl" component={RtlLayout} />
			<Route path="/auth" component={AuthLayout} />
			<Route path="/admin" component={AdminLayout} />
			<Redirect from="/" to="/admin/dashboard" />
		</Switch>
	</Router>,
	document.getElementById('root')
);
*/
