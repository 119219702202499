import {
	cardTitle,
	grayColor,
	dangerColor,
	successColor,
} from 'assets/jss/material-dashboard-pro-react.js';

const userProfileStyles = {
	cardTitle,
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
		'& small': {
			fontSize: '80%',
			fontWeight: '400',
		},
	},
	cardCategory: {
		marginTop: '10px',
		color: grayColor[0] + ' !important',
		textAlign: 'center',
	},
	description: {
		color: grayColor[0],
	},
	myError: {
		color: dangerColor[0] + ' !important',
	},
	mySuccess: {
		color: successColor[0] + ' !important',
	},
	updateProfileButton: {
		float: 'right',
	},
	center: {
		textAlign: 'center',
	},
	justifyContentCenter: {
		justifyContent: 'center',
	},
	registerButton: {
		float: 'right',
	},
	danger: {
		color: dangerColor[0] + '!important',
	},
};
export default userProfileStyles;
