import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { APIurl, APIusersKey } from '../../variables/utils';

import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Cookies from 'js-cookie';
import { Router, Route, Redirect, useHistory } from 'react-router-dom';

// core components
import Wizard from 'components/Wizard/Wizard.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import auth from 'auth';

import Step1 from './WizardSteps/StepThankYou1.js';
import Step2 from './WizardSteps/StepThankYou2.js';
import Step3 from './WizardSteps/StepThankYou3.js';

export default function WizardView() {
	const history = useHistory();
	const [loading, setloading] = React.useState('false');
	const [gridOppacity, setgridOppacity] = React.useState('1');
	const [title, settitle] = React.useState('Build Your `Thank You` Post');

	useEffect(() => {
		//### Is it insert or Update...
		const getParameterByName = (name, url) => {
			const myToken = Cookies.get('access_token');
			if (myToken === undefined) {
				history.push('/auth/login-page');
			} else {
				if (!url) url = window.location.href;
				name = name.replace(/[\[\]]/g, '\\$&');
				var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
					results = regex.exec(url);
				if (!results) return null;
				if (!results[2]) return '';
				return decodeURIComponent(results[2].replace(/\+/g, ' '));
			}
		};
		const foo = getParameterByName('item');
		if (foo !== '' && foo !== null && foo !== undefined) {
			settitle('Edit Your `Thank You` Post');
		}
	}, []);
	const getCityId = (myCity) => {
		console.log('GET CITY ID....');
		//#############   CITY DATA from DB #########################
		try {
			// set the url
			const myUrl = `${APIurl}tabs/categories.json?orderBy="title"&equalTo="${myCity}"`;
			return axios.get(myUrl).then((res) => {
				//console.log(res.data);
				const myData = res.data;
				for (var key1 in myData) {
					for (var key2 in myData[key1]) {
						//console.log('display....' + myData[key1].id);
						var myCityId = myData[key1].id;
					}
				}
				return myCityId;
			});
		} catch (err) {
			console.log(err);
			return false;
		}
	};
	const getRegionId = (myRegion) => {
		console.log('GET REGION ID....');
		var myRegionId = '';
		//#############   REGION DATA from DB #########################
		try {
			// set the url
			const myUrl = `${APIurl}regions.json?orderBy="title"&equalTo="${myRegion}"`;
			return axios.get(myUrl).then((res) => {
				//console.log(res.data);
				const myData = res.data;
				for (var key1 in myData) {
					for (var key2 in myData[key1]) {
						//console.log('display....' + myData[key1].id);
						myRegionId = myData[key1].id;
					}
				}
				return myRegionId;
			});
		} catch (err) {
			console.log(err);
			return false;
		}
	};

	const insertPost = (event) => {
		const myToken = Cookies.get('access_token');
		if (myToken === undefined) {
			history.push('/auth/login-page');
		} else {
			var action = '';
			for (var key1 in event) {
				if (event[key1].pageMode !== undefined && event[key1].pageMode !== '') {
					action = event[key1].pageMode;
				}
			}
			if (action === 'insert') {
				doInsert(event);
			} else {
				doUpdate(event);
			}
		}
	};

	const doUpdate = (event) => {
		//////@@@@ UPDATE RECORD @@@@@@@@@@@@@@@@@@
		setgridOppacity('0.2');
		// set some defaults....
		var recToUpdate = '';
		var valid = false;
		var imagesConstructed = false;
		var approved = 0;
		var modifyDate = new Date();
		// get some new fields.....
		var newRegionId = '';
		var newBusinessName = '';
		var newAddress = '';
		var newCity = '';
		var newCityId = '';
		var newRegion = '';
		var newRegionId = '';
		var newTitle = '';
		var newPhone = '';
		var newEmail = '';
		var newWebSite = '';
		var newPostTitle = '';
		var newPostIntro = '';
		var newPostBody = '';
		var newPostImage1 = '';
		var newPostImage2 = '';
		var newPostImage3 = '';
		var newPostImage4 = '';
		var newPostImagesArray = [];
		var newUploadImagesArray = [];
		var newUpladedImage1 = '';
		var newUpladedImage2 = '';
		var newUpladedImage3 = '';
		var newUpladedImage4 = '';
		var oldImage1 = '';
		var oldImage2 = '';
		var oldImage3 = '';
		var oldImage4 = '';

		/// Get Existing.....
		const getPost = (postId) => {
			try {
				// set the url
				const myUrl = `${APIurl}products/${postId}.json`;
				axios.get(myUrl).then((res) => {
					console.log(res.data);
					const myData = res.data;
					oldImage1 = myData.image;
					oldImage2 = myData.image2;
					oldImage3 = myData.image3;
					oldImage4 = myData.image4;
					if (
						event.business.businessname !== undefined &&
						event.business.businessname !== myData.businessName
					) {
						newBusinessName = event.business.businessname;
					}
					if (
						event.business.address !== undefined &&
						event.business.address !== myData.address
					) {
						newAddress = event.business.address;
					}
					if (
						event.business.registerCity !== undefined &&
						event.business.registerCity !== myData.city
					) {
						newCity = event.business.registerCity;
					}
					if (
						event.business.registerRegion !== undefined &&
						event.business.registerRegion !== myData.region
					) {
						newRegion = event.business.registerRegion;
					}
					if (
						event.business.title !== undefined &&
						event.business.title !== myData.listingPersonTitle
					) {
						newTitle = event.business.title;
					}
					if (
						event.business.phone !== undefined &&
						event.business.phone !== myData.phone
					) {
						newPhone = event.business.phone;
					}
					if (
						event.business.email !== undefined &&
						event.business.email !== myData.email
					) {
						newEmail = event.business.email;
					}
					if (
						event.business.web !== undefined &&
						event.business.web !== myData.webSite
					) {
						newWebSite = event.business.web;
					}
					if (
						event.post.posttitle !== undefined &&
						event.post.posttitle !== myData.title
					) {
						newPostTitle = event.post.posttitle;
					}
					if (
						event.post.postintro !== undefined &&
						event.post.postintro !== myData.price
					) {
						newPostIntro = event.post.postintro;
					}
					if (
						event.post.editorState !== undefined &&
						event.post.editorState !== ''
					) {
						//postBodyRaw = event[key1].editorState;
						var tmpBody = draftToHtml(
							convertToRaw(event.post.editorState.getCurrentContent())
						);
						if (tmpBody !== myData.body) {
							newPostBody = draftToHtml(
								convertToRaw(event.post.editorState.getCurrentContent())
							);
						}
					}
					if (
						event.gallery.uploadPhoto1 !== undefined &&
						event.gallery.uploadPhoto1 !== '' &&
						event.gallery.uploadPhoto1 !== null
					) {
						newPostImage1 = event.gallery.uploadPhoto1;
					}
					if (
						event.gallery.uploadPhoto2 !== undefined &&
						event.gallery.uploadPhoto2 !== '' &&
						event.gallery.uploadPhoto2 !== null
					) {
						newPostImage2 = event.gallery.uploadPhoto2;
					}
					if (
						event.gallery.uploadPhoto3 !== undefined &&
						event.gallery.uploadPhoto3 !== '' &&
						event.gallery.uploadPhoto3 !== null
					) {
						newPostImage3 = event.gallery.uploadPhoto3;
					}
					if (
						event.gallery.uploadPhoto4 !== undefined &&
						event.gallery.uploadPhoto4 !== '' &&
						event.gallery.uploadPhoto4 !== null
					) {
						newPostImage4 = event.gallery.uploadPhoto4;
					}

					if (
						newBusinessName !== '' &&
						newAddress !== '' &&
						newCity !== '' &&
						newRegion !== '' &&
						newTitle !== '' &&
						newPhone !== '' &&
						newEmail !== '' &&
						newWebSite !== '' &&
						newPostTitle !== '' &&
						newPostIntro !== '' &&
						newPostBody !== '' &&
						newPostImage1 !== '' &&
						newPostImage2 !== '' &&
						newPostImage3 !== '' &&
						newPostImage4 !== ''
					) {
						//Nothing updated.... do something
						console.log('Nothing to update really..... ');
						history.push('/admin/dashboard');
					} else {
						//Wellll .... now update really begins....
						console.log('Something to update really..... ');
						// check to see if city has been changed... to get city ID
						const doSomeInsert = () => {
							///DO SOME UPDATE HERE.......
							const myToken = Cookies.get('access_token');
							try {
								// set the url
								const myUrl = `${APIurl}products/${recToUpdate}.json?auth=${myToken}`;
								// request data object
								const upData = {};
								upData.approved = approved;
								upData.modifyDate = modifyDate;

								if (newBusinessName !== '') {
									upData.businessName = newBusinessName;
								}
								if (newAddress !== '') {
									upData.address = newAddress;
								}
								if (newCity !== '') {
									upData.city = newCity;
								}
								if (newCityId !== '') {
									upData.cityId = newCityId;
								}
								if (newRegion !== '') {
									upData.region = newRegion;
								}
								if (newRegionId !== '') {
									upData.regionId = newRegionId;
								}
								if (newTitle !== '') {
									upData.listingPersonTitle = newTitle;
								}
								if (newPhone !== '') {
									upData.phone = newPhone;
								}
								if (newEmail !== '') {
									upData.email = newEmail;
								}
								if (newWebSite !== '') {
									upData.webSite = newWebSite;
								}
								if (newPostTitle !== '') {
									upData.title = newPostTitle;
								}
								if (newPostIntro !== '') {
									upData.price = newPostIntro;
								}
								if (newPostBody !== '') {
									upData.body = newPostBody;
								}
								if (newUpladedImage1 !== '') {
									upData.image = newUpladedImage1;
									upData.image2 = '';
									upData.image3 = '';
									upData.image4 = '';
								}
								if (newUpladedImage2 !== '') {
									upData.image2 = newUpladedImage2;
								}
								if (newUpladedImage3 !== '') {
									upData.image3 = newUpladedImage3;
								}
								if (newUpladedImage4 !== '') {
									upData.image4 = newUpladedImage4;
								}

								// set the headers
								const config = {
									headers: {
										'Content-Type': 'application/x-www-form-urlencoded',
									},
								};

								axios.patch(myUrl, upData, config).then((res) => {
									console.log('Updated Post finaly!!!!' + res.data);
									history.push('/admin/dashboard');
								});
							} catch (err) {
								console.log('Error Updating Post!!!!' + err);
								history.push('/admin/dashboard');
							}

							///TESTING###//###
							console.log(
								'...newBusinessName...' +
									newBusinessName +
									'...newAddress...' +
									newAddress +
									'...newCity...' +
									newCity +
									'...newCityID...' +
									newCityId +
									'...newRegion...' +
									newRegion +
									'...newRegionId...' +
									newRegionId +
									'...newTitle...' +
									newTitle +
									'...newPhone...' +
									newPhone +
									'...newEmail...' +
									newEmail +
									'...newWebSite...' +
									newWebSite +
									'...newPostTitle...' +
									newPostTitle +
									'...newPostIntro...' +
									newPostIntro +
									'...newPostBody...' +
									newPostBody +
									'...newPostImage1...' +
									newUpladedImage1 +
									'...newUpladedImage2...' +
									newUpladedImage2 +
									'...newUpladedImage3...' +
									newUpladedImage3 +
									'...newUpladedImage4...' +
									newUpladedImage4
							);
						};

						const doPhotos = () => {
							console.log('UPLOAD IMAGES....');

							// ### Upload images
							if (newPostImage1 !== '') {
								newPostImagesArray.push(newPostImage1);
							}
							if (newPostImage2 !== '') {
								newPostImagesArray.push(newPostImage2);
							}
							if (newPostImage3 !== '') {
								newPostImagesArray.push(newPostImage3);
							}
							if (newPostImage4 !== '') {
								newPostImagesArray.push(newPostImage4);
							}
							//Loop Array and make upload......
							var startCount = 1;
							var endCount = newPostImagesArray.length;

							if (newPostImagesArray.length) {
								//User is trying to upload images... first delete old ones....
								var parsedImages = [];
								//construct array....
								if (oldImage1 !== '' && oldImage1 !== undefined) {
									var res1 = oldImage1.replace(
										'https://solelylocal.imgix.net/',
										''
									);
									var res2 = res1.replace('?fit=crop&w=840&q=80', '');
									if (
										res2 !== 'help1.jpg' &&
										res2 !== 'nonProfit1.jpg' &&
										res2 !== 'student1.jpg' &&
										res2 !== 'thankyou1.jpg' &&
										res2 !== 'Volunteers1.jpg'
									) {
										parsedImages.push(res2);
									}
								}
								if (oldImage2 !== '' && oldImage2 !== undefined) {
									var res1 = oldImage2.replace(
										'https://solelylocal.imgix.net/',
										''
									);
									var res2 = res1.replace('?fit=crop&w=840&q=80', '');
									if (
										res2 !== 'help1.jpg' &&
										res2 !== 'nonProfit1.jpg' &&
										res2 !== 'student1.jpg' &&
										res2 !== 'thankyou1.jpg' &&
										res2 !== 'Volunteers1.jpg'
									) {
										parsedImages.push(res2);
									}
								}
								if (oldImage3 !== '' && oldImage3 !== undefined) {
									var res1 = oldImage3.replace(
										'https://solelylocal.imgix.net/',
										''
									);
									var res2 = res1.replace('?fit=crop&w=840&q=80', '');
									if (
										res2 !== 'help1.jpg' &&
										res2 !== 'nonProfit1.jpg' &&
										res2 !== 'student1.jpg' &&
										res2 !== 'thankyou1.jpg' &&
										res2 !== 'Volunteers1.jpg'
									) {
										parsedImages.push(res2);
									}
								}
								if (oldImage4 !== '' && oldImage4 !== undefined) {
									var res1 = oldImage4.replace(
										'https://solelylocal.imgix.net/',
										''
									);
									var res2 = res1.replace('?fit=crop&w=840&q=80', '');
									if (
										res2 !== 'help1.jpg' &&
										res2 !== 'nonProfit1.jpg' &&
										res2 !== 'student1.jpg' &&
										res2 !== 'thankyou1.jpg' &&
										res2 !== 'Volunteers1.jpg'
									) {
										parsedImages.push(res2);
									}
								}
								if (parsedImages.length > 0) {
									// something to delete....
									for (var i = 0; i < parsedImages.length; i++) {
										//##### DELETING IMAGE ###########
										try {
											var base_url =
												'https://www.solelylocal.com/deleteImage.php';
											var fd = new FormData();
											fd.append('delete_file', parsedImages[i]);
											axios.post(base_url, fd).then((res) => {
												console.log(res);
												console.log('IMA STATUS....' + res.data.status);
											});
										} catch (err) {
											//console.error(err);
											console.log(
												'There was an error deleting file from web server: ' +
													err
											);
										}
										//###### END DELETING IMAGE ############################
									}
								}

								for (var i = 0; i < newPostImagesArray.length; i++) {
									//console.log(postImagesArray[i]);
									var currentImage = newPostImagesArray[i];
									//##### UPLOADING IMAGE ###########
									try {
										var base_url =
											'https://www.solelylocal.com/uploadImage.php';
										var fd = new FormData();
										fd.append('avatar', newPostImagesArray[i], `post_${i}.jpg`);
										axios.post(base_url, fd).then((res) => {
											console.log(res);
											if (res.data.status === 'success') {
												// finally image uploaded.....
												let fileConstruct =
													'https://solelylocal.imgix.net/' +
													res.data.fileName +
													'?fit=crop&w=840&q=80';
												newUploadImagesArray.push(fileConstruct);
												console.log('to upload....' + fileConstruct);
											} else {
												// there was an error with file upload... revert to default...
												console.log('No error but no image either......');
											}
											console.log(
												'START:...' + startCount + '...END:...' + endCount
											);
											if (startCount == endCount) {
												uploadImagesConstruct();
											}
											startCount++;
										});
									} catch (err) {
										//console.error(err);
										console.log(
											'There was an error uploading file to the web server: ' +
												err
										);
										if (startCount == endCount) {
											uploadImagesConstruct();
										}
									}
									//###### END UPLOADING IMAGE ############################
								}
							} else {
								doSomeInsert();
							}
						};
						const uploadImagesConstruct = () => {
							console.log('CONSTRUCT UPLOADED IMAGES....');

							newUploadImagesArray.sort(function(x, y) {
								var xp = x.substring(
									x.lastIndexOf('_') + 1,
									x.lastIndexOf('.jpg')
								);
								var yp = y.substring(
									y.lastIndexOf('_') + 1,
									y.lastIndexOf('.jpg')
								);
								return xp == yp ? 0 : xp < yp ? -1 : 1;
							});

							//##### Construct Images......
							if (newUploadImagesArray.length > 0) {
								console.log('Construct Uploaded Images...');
								if (newUploadImagesArray.length == 1) {
									newUpladedImage1 = newUploadImagesArray[0];
								}
								if (newUploadImagesArray.length == 2) {
									newUpladedImage1 = newUploadImagesArray[0];
									newUpladedImage2 = newUploadImagesArray[1];
								}
								if (newUploadImagesArray.length == 3) {
									newUpladedImage1 = newUploadImagesArray[0];
									newUpladedImage2 = newUploadImagesArray[1];
									newUpladedImage3 = newUploadImagesArray[2];
								}
								if (newUploadImagesArray.length == 4) {
									newUpladedImage1 = newUploadImagesArray[0];
									newUpladedImage2 = newUploadImagesArray[1];
									newUpladedImage3 = newUploadImagesArray[2];
									newUpladedImage4 = newUploadImagesArray[3];
								}
								imagesConstructed = true;
							} else {
								console.log('No Images to upload... send default...');
								imagesConstructed = true;
							}
							if (imagesConstructed) {
								doSomeInsert();
							}
						};

						if (newCity !== '') {
							const findCityId = (newCity) => {
								getCityId(newCity).then(
									(response) => (
										(newCityId = response), findRegionId(newRegion)
									)
								);
							};
							const findRegionId = (newRegion) => {
								getRegionId(newRegion).then(
									(response) => ((newRegionId = response), doPhotos())
								);
							};
							findCityId(newCity);
						} else if (newRegion !== '') {
							const findRegionId = (newRegion) => {
								getRegionId(newRegion).then(
									(response) => ((newRegionId = response), doPhotos())
								);
							};
							findRegionId(newRegion);
						} else {
							doPhotos();
						}
					}
				});
			} catch (err) {
				console.log(err);
				alert('something went wrong...');
			}
		};
		for (var key1 in event) {
			if (
				event[key1].updateId !== undefined &&
				event[key1].updateId !== '' &&
				event[key1].updateId !== null
			) {
				recToUpdate = event[key1].updateId;
				getPost(recToUpdate);
			}
		}
	};

	const doInsert = (event) => {
		setgridOppacity('0.2');
		//Some empty holders for external data.....
		var valid = false;
		var imagesConstructed = false;
		var approved = 0;
		var att = '';
		var category = 'Thank You'; ////// @@@@@@@ PROMENITI!!!!!!
		var categoryId = '';
		var cityId = '';
		var createDate = new Date();
		var modifyDate = new Date();
		var listingPerson = '';
		var listingPersonId = '';
		var listingPersonAvatar = '';
		var regionId = '';
		var regionToUpdate = '';
		//### set from Forms.....
		var businessName = '';
		var address = '';
		var city = '';
		var region = '';
		var title = 'Thank You';
		var phone = '';
		var email = '';
		var webSite = '';
		var postTitle = '';
		var postIntro = '';
		var postBody = '';
		var postBodyRaw = null;
		var postImage1 = null;
		var postImage2 = null;
		var postImage3 = null;
		var postImage4 = null;
		var uploadedImage1 = '';
		var uploadedImage2 = '';
		var uploadedImage3 = '';
		var uploadedImage4 = '';
		var postImagesArray = [];
		var uploadImagesArray = [];
		for (var key1 in event) {
			//console.log('To Insert..screens....' + event[key1]);
			if (
				event[key1].businessname !== undefined &&
				event[key1].businessname !== ''
			) {
				businessName = event[key1].businessname;
			}
			if (event[key1].address !== undefined && event[key1].address !== '') {
				address = event[key1].address;
			}
			if (event[key1].title !== undefined && event[key1].title !== '') {
				title = event[key1].title;
			}
			if (event[key1].phone !== undefined && event[key1].phone !== '') {
				phone = event[key1].phone;
			}
			if (event[key1].email !== undefined && event[key1].email !== '') {
				email = event[key1].email;
			}
			if (
				event[key1].registerCity !== undefined &&
				event[key1].registerCity !== ''
			) {
				city = event[key1].registerCity;
			}
			if (
				event[key1].registerRegion !== undefined &&
				event[key1].registerRegion !== ''
			) {
				region = event[key1].registerRegion;
			}
			if (event[key1].web !== undefined && event[key1].web !== '') {
				webSite = event[key1].web;
			}
			if (event[key1].posttitle !== undefined && event[key1].posttitle !== '') {
				postTitle = event[key1].posttitle;
			}
			if (event[key1].postintro !== undefined && event[key1].postintro !== '') {
				postIntro = event[key1].postintro;
			}
			if (
				event[key1].editorState !== undefined &&
				event[key1].editorState !== ''
			) {
				//postBodyRaw = event[key1].editorState;
				postBody = draftToHtml(
					convertToRaw(event[key1].editorState.getCurrentContent())
				);
			}
			if (
				event[key1].uploadPhoto1 !== undefined &&
				event[key1].uploadPhoto1 !== '' &&
				event[key1].uploadPhoto1 !== null
			) {
				postImage1 = event[key1].uploadPhoto1;
			}
			if (
				event[key1].uploadPhoto2 !== undefined &&
				event[key1].uploadPhoto2 !== '' &&
				event[key1].uploadPhoto2 !== null
			) {
				postImage2 = event[key1].uploadPhoto2;
			}
			if (
				event[key1].uploadPhoto3 !== undefined &&
				event[key1].uploadPhoto3 !== '' &&
				event[key1].uploadPhoto3 !== null
			) {
				postImage3 = event[key1].uploadPhoto3;
			}
			if (
				event[key1].uploadPhoto4 !== undefined &&
				event[key1].uploadPhoto4 !== '' &&
				event[key1].uploadPhoto4 !== null
			) {
				postImage4 = event[key1].uploadPhoto4;
			}
		}
		//### up to now we have all data from fields...
		//#############   USER DATA from DB #########################
		//## Get some user info here from DB.....
		const getUserDetails = (userId) => {
			console.log('GET USER DETAILS....');
			try {
				// set the url
				const myUrl = `${APIurl}users.json?orderBy="userUid"&equalTo="${userId}"`;

				axios.get(myUrl).then((res) => {
					//console.log(res.data);
					const myData = res.data;
					for (var key1 in myData) {
						for (var key2 in myData[key1]) {
							//console.log('display....' + [key1]);
							//listingPerson = `${myData[key1].firstName} ${myData[key1].lastName}`;
							listingPerson =
								myData[key1].firstName + ' ' + myData[key1].lastName;
							listingPersonId = myData[key1].userUid;
							listingPersonAvatar = myData[key1].photoUrl;
						}
					}
					getCategoryId();
				});
			} catch (err) {
				console.log(err);
			}
		};
		const getAuthUser = () => {
			console.log('GET AUTH USER FROM TOKEN....');
			// ### get User Info.... from auth user.....
			const myToken = Cookies.get('access_token');
			const myID = Cookies.get('access_id');
			if (myToken === undefined) {
				history.push('/auth/login-page');
			} else {
				getUserDetails(myID);
				/*
				try {
					// set the url
					const url =
						'https://identitytoolkit.googleapis.com/v1/accounts:lookup?key=AIzaSyA7l2dD4nbZTKQ8xaq7iho4nhVPYXMqbQA';
					// request data object
					const data = {
						idToken: myToken,
					};

					// set the headers
					const config = {
						headers: {
							'Content-Type': 'application/json',
						},
					};

					axios.post(url, data, config).then((res) => {
						//console.log(res.data.users);
						getUserDetails(res.data.users[0].localId);
					});
				} catch (err) {
					console.log(err);
				}
				*/
			}
		};

		const getCategoryId = () => {
			console.log('GET CATEGORY ID....');
			//#############   CATEGORY DATA from DB #########################
			try {
				// set the url
				const myUrl = `${APIurl}tabs/deals.json?orderBy="title"&equalTo="${category}"`;
				axios.get(myUrl).then((res) => {
					//console.log(res.data);
					const myData = res.data;
					for (var key1 in myData) {
						for (var key2 in myData[key1]) {
							//console.log('display....' + myData[key1].id);
							categoryId = myData[key1].id;
						}
					}
					getCityId();
				});
			} catch (err) {
				console.log(err);
			}
		};
		const getCityId = () => {
			console.log('GET CITY ID....');
			//#############   CITY DATA from DB #########################
			try {
				// set the url
				const myUrl = `${APIurl}tabs/categories.json?orderBy="title"&equalTo="${city}"`;
				axios.get(myUrl).then((res) => {
					//console.log(res.data);
					const myData = res.data;
					for (var key1 in myData) {
						for (var key2 in myData[key1]) {
							//console.log('display....' + myData[key1].id);
							cityId = myData[key1].id;
						}
					}
					getRegionId();
				});
			} catch (err) {
				console.log(err);
			}
		};
		const getRegionId = () => {
			console.log('GET REGION ID....');
			//#############   REGION DATA from DB #########################
			try {
				// set the url
				const myUrl = `${APIurl}regions.json?orderBy="title"&equalTo="${region}"`;
				axios.get(myUrl).then((res) => {
					//console.log(res.data);
					const myData = res.data;
					for (var key1 in myData) {
						console.log('Region to update active....' + key1);
						regionToUpdate = key1;
						for (var key2 in myData[key1]) {
							//console.log('display....' + myData[key1].id);
							regionId = myData[key1].id;
						}
					}
					uploadImages();
				});
			} catch (err) {
				console.log(err);
			}
		};
		const uploadImages = () => {
			console.log('UPLOAD IMAGES....');
			// ### Upload images
			if (postImage1 !== null) {
				postImagesArray.push(postImage1);
			}
			if (postImage2 !== null) {
				postImagesArray.push(postImage2);
			}
			if (postImage3 !== null) {
				postImagesArray.push(postImage3);
			}
			if (postImage4 !== null) {
				postImagesArray.push(postImage4);
			}
			if (postImagesArray.length > 0) {
				//Loop Array and make upload......
				var startCount = 1;
				var endCount = postImagesArray.length;
				for (var i = 0; i < postImagesArray.length; i++) {
					//console.log(postImagesArray[i]);
					var currentImage = postImagesArray[i];
					//##### UPLOADING IMAGE ###########
					try {
						var base_url = 'https://www.solelylocal.com/uploadImage.php';
						var fd = new FormData();
						fd.append('avatar', currentImage, `post_${i}.jpg`);
						axios.post(base_url, fd).then((res) => {
							console.log(res);
							if (res.data.status === 'success') {
								// finally image uploaded.....
								let fileConstruct =
									'https://solelylocal.imgix.net/' +
									res.data.fileName +
									'?fit=crop&w=840&q=80';
								uploadImagesArray.push(fileConstruct);
								console.log('to upload....' + fileConstruct);
							} else {
								// there was an error with file upload... revert to default...
								console.log('No error but no image either......');
							}
							console.log('START:...' + startCount + '...END:...' + endCount);
							if (startCount == endCount) {
								uploadImagesConstruct();
							}
							startCount++;
						});
					} catch (err) {
						//console.error(err);
						console.log(
							'There was an error uploading file to the web server: ' + err
						);
						if (startCount == endCount) {
							uploadImagesConstruct();
						}
					}
					//###### END UPLOADING IMAGE ############################
				}
			} else {
				uploadImagesConstruct();
			}
		};
		const uploadImagesConstruct = () => {
			console.log('CONSTRUCT UPLOADED IMAGES....');

			uploadImagesArray.sort(function(x, y) {
				var xp = x.substring(x.lastIndexOf('_') + 1, x.lastIndexOf('.jpg'));
				var yp = y.substring(y.lastIndexOf('_') + 1, y.lastIndexOf('.jpg'));
				return xp == yp ? 0 : xp < yp ? -1 : 1;
			});

			//##### Construct Images......
			if (uploadImagesArray.length > 0) {
				console.log('Construct Uploaded Images...');
				if (uploadImagesArray.length == 1) {
					uploadedImage1 = uploadImagesArray[0];
				}
				if (uploadImagesArray.length == 2) {
					uploadedImage1 = uploadImagesArray[0];
					uploadedImage2 = uploadImagesArray[1];
				}
				if (uploadImagesArray.length == 3) {
					uploadedImage1 = uploadImagesArray[0];
					uploadedImage2 = uploadImagesArray[1];
					uploadedImage3 = uploadImagesArray[2];
				}
				if (uploadImagesArray.length == 4) {
					uploadedImage1 = uploadImagesArray[0];
					uploadedImage2 = uploadImagesArray[1];
					uploadedImage3 = uploadImagesArray[2];
					uploadedImage4 = uploadImagesArray[3];
				}
				imagesConstructed = true;
			} else {
				console.log('No Images to upload... send default...');
				uploadedImage1 =
					'https://solelylocal.imgix.net/thankyou1.jpg?fit=crop&w=840&q=80';
				uploadedImage2 =
					'https://solelylocal.imgix.net/thankyou1.jpg?fit=crop&w=840&q=80';
				imagesConstructed = true;
			}
			if (imagesConstructed) {
				uploadPost();
			}
		};

		const uploadPost = () => {
			//#############   WE HAVE ALL DATA LET'S INSERT POST!!!!  #########################
			console.log('DO INSERT.....');
			if (
				categoryId !== '' &&
				listingPerson !== '' &&
				listingPersonId !== '' &&
				regionId !== '' &&
				postTitle !== '' &&
				postIntro !== ''
			) {
				valid = true;
			}
			if (valid) {
				if (postBody == '') {
					postBody = postIntro;
				}

				//Make Post....
				const myToken = Cookies.get('access_token');
				console.log('Posting Post....');
				try {
					// set the url
					const myUrl = `${APIurl}products.json?auth=${myToken}`;
					// request data object
					const data = {
						address: address,
						businessName: businessName,
						webSite: webSite,
						att: att,
						body: postBody,
						category: category,
						categoryId: categoryId,
						city: city,
						cityId: cityId,
						email: email,
						image: uploadedImage1,
						image2: uploadedImage2,
						image3: uploadedImage3,
						image4: uploadedImage4,
						listingPersonId: listingPersonId,
						listingPerson: listingPerson,
						listingPersonTitle: title,
						listingPersonAvatar: listingPersonAvatar,
						phone: phone,
						price: postIntro,
						region: region,
						regionId: regionId,
						title: postTitle,
						bodyRaw: postBodyRaw,
						createDate: createDate,
						modifyDate: modifyDate,
						approved: approved,
					};
					// set the headers
					const config = {
						headers: {
							'Content-Type': 'application/x-www-form-urlencoded',
						},
					};
					axios.post(myUrl, data, config).then((res) => {
						console.log('Added Post finaly!!!!' + res.data);
						//Make region active.....fieldset-reset
						//updateRegion();
						history.push('/admin/dashboard');
					});
				} catch (err) {
					console.log('Error Adding Post!!!!' + err);
					history.push('/admin/dashboard');
				}
			} else {
				history.push('/admin/dashboard');
			}
		};
		/*
		const updateRegion = () => {
			console.log('UPDATE REGIN TO ACTIVE....');
			//#############   REGION DATA from DB #########################
			const myToken = Cookies.get('access_token');
			try {
				// set the url
				const myUrl = `${APIurl}regions/${regionToUpdate}.json?auth=${myToken}`;
				const data = {
					active: '1',
				};
				// set the headers
				const config = {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				};
				axios.patch(myUrl, data, config).then((res) => {
					console.log('Updated Region to Active' + res.data);
					//history.push('/admin/dashboard');
				});
			} catch (err) {
				console.log(err);
			}
		};
		*/
		getAuthUser();
		//history.push('/admin/dashboard');
	};

	return (
		<GridContainer justify="center" style={{ opacity: gridOppacity }}>
			<GridItem xs={12} sm={8}>
				<Wizard
					validate
					steps={[
						{
							stepName: 'About Your Post',
							stepComponent: Step1,
							stepId: 'business',
						},
						{ stepName: 'Post Details', stepComponent: Step2, stepId: 'post' },
						{ stepName: 'Gallery', stepComponent: Step3, stepId: 'gallery' },
					]}
					title={title}
					subtitle="This information will let us know more about you."
					finishButtonClick={(e) => insertPost(e)}
				/>
			</GridItem>
		</GridContainer>
	);
}
