import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';

// @material-ui/icons
// import Weekend from "@material-ui/icons/Weekend";
import Home from '@material-ui/icons/Home';
import Business from '@material-ui/icons/Business';
import AccountBalance from '@material-ui/icons/AccountBalance';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';

import styles from 'assets/jss/material-dashboard-pro-react/views/pricingPageStyle.js';

const useStyles = makeStyles(styles);

export default function PricingPage() {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<GridContainer justify="center">
				<GridItem xs={12} sm={12} md={6}>
					<h2 className={classes.title}>Privacy Policy</h2>
					<h5 className={classes.description}>Solely Local Networks Inc</h5>
				</GridItem>
			</GridContainer>
			<GridContainer justify="center">
				<GridItem xs={12} sm={12} md={8}>
					<Card pricing raised>
						<CardBody pricing>
							<h4 className={classes.cardCategory}>The Service</h4>
							<p className={classes.cardDescription}>
								The Solely Local Networks Inc. [“SolelyLocal”] provides a mobile
								bulletin service to allow individuals to reach out to other
								purposes for the purpose supporting Local Non-Profits, Local
								Businesses and Individuals requiring assistance from members
								located in their community.
							</p>
							<h4 className={classes.cardCategory}>
								Scope of the Solely Local Privacy Policy
							</h4>
							<p className={classes.cardDescription}>
								The Solely Local Privacy Policy is designed to ensure that your
								personal information required to manage the service complies
								with the Canadian Government Freedom of Information and
								Protection of Privacy Act (PIPEDA). This policy may change from
								time to time so please check the policy periodically for
								updates. The effective date for this policy is the 1 July 2020.
							</p>
							<h4 className={classes.cardCategory}>Company Details. </h4>
							<p className={classes.cardDescription}>
								Solely Local Networks Inc
								<br />
								21-21 Dallas Rd
								<br />
								Victoria BC
								<br />
								V8V 4Z9
								<br />
								Privacy Officer: Gary Gilmour
								<br />
								ggilmour@solelylocal.com
							</p>
							<h4 className={classes.cardCategory}>
								Personal Information Collection:
							</h4>
							<p className={classes.cardDescription}>
								Personal information collected at the time of registration
								includes 10-digit mobile phone number, first name, last name,
								email, city in which the individual lives. This information is
								used to confirm that the individual is a registered user of the
								SolelyLocal service.
								<br />
								<br />
								Individuals using the service to post for businesses and
								non-profits are required to supply additional information needed
								to confirm that the individual has authority to make these
								posts. Information linked to a business or non-profit includes
								organisation name, address, telephone number, name of
								authorising officer, title and direct information.
							</p>
							<h4 className={classes.cardCategory}>
								Automatic Information Collection:
							</h4>
							<p className={classes.cardDescription}>
								In addition to personal information supplied in the normal
								course of registration as discussed above, Solely Local will
								record the device ID to assist in identifying individual making
								fraudulent or misleading statements in breach of the Solely
								Local Terms of Use.
								<br />
								<br />
								When you access and use the SolelyLocal app, Solely Local may
								record automatically certain details of your access and use of
								the app including history of posts made and history of post
								searches made.
							</p>
							<h4 className={classes.cardCategory}>
								Use of Personal Information
							</h4>
							<p className={classes.cardDescription}>
								Personal information is only collected for the following
								purposes,
								<br />
								<br />
								• To manage the Solely Local mobile bulletin service.
								<br />
								<br />
								• To respond to requests or questions regarding the service
								(e.g. to help you reset your password).
								<br />
								<br />
								• To communicate with you (e.g. send notifications about your
								account, changes to our sites, applications, or policies).
								<br />
								<br />• As otherwise required by law.
							</p>
							<h4 className={classes.cardCategory}>
								Disclosure of Your Information:
							</h4>
							<p className={classes.cardDescription}>
								Your personal information is only disclosed for the following
								reasons:
								<br />
								<br />
								• To Solely Local staff can fulfill their responsibilities to
								manage the Solely Local mobile bulletin board service.
								<br />
								<br />
								• To expert advisers contributing to the design and continuous
								improvement of Solely Local service; auditors and; fraud
								consultants. Expert advisers will be required not to disclose
								any personal information sourced from Solely Local to any other
								party. In addition, any and all personal information sourced
								from Solely Local must be destroyed or returned to Solely Local
								when demanded by Solely Local. The adviser must confirm at that
								time that no copies of this data have been retained.
								<br />
								<br />
								• To comply with any court order, law or legal process,
								including responding to any government or regulatory request.
								<br />
								<br />
								• With the members prior and informed consent.
								<br />
								<br />
								Your personal information will not be shared or sold for any
								reason other than identified above.
							</p>
							<h4 className={classes.cardCategory}>
								Accessing and Correcting Your Personal Information:
							</h4>
							<p className={classes.cardDescription}>
								You can review and change your personal information by logging
								in and visiting your account profile page.
							</p>
							<h4 className={classes.cardCategory}>Retention:</h4>
							<p className={classes.cardDescription}>
								You may de-register your account at any time and your personal
								information is deleted.
							</p>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		</div>
	);
}
