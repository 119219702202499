import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { APIurl, APIusersKey } from '../../variables/utils';

import auth from '../../auth';
import Cookies from 'js-cookie';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Router, Route, Redirect, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import Button from 'components/CustomButtons/Button.js';

// @material-ui/icons
import Dashboard from '@material-ui/icons/Dashboard';
import Schedule from '@material-ui/icons/Schedule';
import Info from '@material-ui/icons/Info';
import LocationOn from '@material-ui/icons/LocationOn';
import InsertPhoto from '@material-ui/icons/InsertPhoto';
import HelpOutline from '@material-ui/icons/HelpOutline';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import NavPills from 'components/NavPills/NavPills.js';
import Accordion from 'components/Accordion/Accordion.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';

import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js';

const styles = {
	cardTitle,
	pageSubcategoriesTitle: {
		color: '#3C4858',
		textDecoration: 'none',
		textAlign: 'center',
	},
	cardCategory: {
		margin: '0',
		color: '#999999',
	},
	notApproved: {
		color: 'red',
		textAlign: 'center',
	},
	fab: {
		position: 'absolute',
		margin: 16,
		right: 60,
		bottom: 10,
	},
};

const useStyles = makeStyles(styles);

export default function Panels() {
	const [address, setAddress] = React.useState('');
	const [approved, setApproved] = React.useState('');
	const [body, setBody] = React.useState('');
	const [bodyFormatted, setBodyFormatted] = React.useState('');
	const [businessName, setBusinessName] = React.useState('');
	const [category, setCategory] = React.useState('');
	const [city, setCity] = React.useState('');
	const [createDate, setCreateDate] = React.useState('');
	const [formattedDate, setFormattedDate] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [image, setImage] = React.useState('');
	const [image2, setImage2] = React.useState('');
	const [image3, setImage3] = React.useState('');
	const [image4, setImage4] = React.useState('');
	const [listingPerson, setListingPerson] = React.useState('');
	const [listingPersonAvatar, setListingPersonAvatar] = React.useState('');
	const [listingPersonTitle, setListingPersonTitle] = React.useState('');
	const [phone, setPhone] = React.useState('');
	const [price, setPrice] = React.useState('');
	const [region, setRegion] = React.useState('');
	const [title, setTitle] = React.useState('');
	const [webSite, setWebSite] = React.useState('');
	const [postId, setpostId] = React.useState('');
	const classes = useStyles();
	const history = useHistory();

	const navigateToEdit = (prcId, prcCat) => {
		console.log(prcCat);
		switch (prcCat) {
			case 'Community Partner':
				var url = `/admin/community-partner?item=${prcId}`;
				break;
			case 'Local Business':
				var url = `/admin/local-business?item=${prcId}`;
				break;
			case 'Non Profit':
				var url = `/admin/non-profit?item=${prcId}`;
				break;
			case 'Help':
				var url = `/admin/help?item=${prcId}`;
				break;
			case 'Volunteer':
				var url = `/admin/volunteer?item=${prcId}`;
				break;
			case 'Student':
				var url = `/admin/student?item=${prcId}`;
				break;
			case 'Thank You':
				var url = `/admin/thank-you?item=${prcId}`;
				break;
			default:
				return false;
		}
		history.push(url);
	};

	useEffect(() => {
		const months = [
			'JAN',
			'FEB',
			'MAR',
			'APR',
			'MAY',
			'JUN',
			'JUL',
			'AUG',
			'SEP',
			'OCT',
			'NOV',
			'DEC',
		];
		const getParameterByName = (name, url) => {
			if (!url) url = window.location.href;
			name = name.replace(/[\[\]]/g, '\\$&');
			var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
				results = regex.exec(url);
			if (!results) return null;
			if (!results[2]) return '';
			return decodeURIComponent(results[2].replace(/\+/g, ' '));
		};
		const foo = getParameterByName('item');

		const getPost = (postId) => {
			const myToken = Cookies.get('access_token');
			if (myToken === undefined) {
				history.push('/auth/login-page');
			} else {
				try {
					// set the url
					const myUrl = `${APIurl}products/${postId}.json`;

					axios.get(myUrl).then((res) => {
						console.log(res.data);
						const myData = res.data;
						console.log('Da li treba loop????....' + myData.address);
						setAddress(myData.address);
						setApproved(myData.approved);
						setBody(myData.body);
						setBusinessName(myData.businessName);
						setCategory(myData.category);
						setCity(myData.city);
						setCreateDate(myData.createDate);
						setEmail(myData.email);
						setImage(myData.image);
						setImage2(myData.image2);
						setImage3(myData.image3);
						setImage4(myData.image4);
						setListingPerson(myData.listingPerson);
						setListingPersonAvatar(myData.listingPersonAvatar);
						setListingPersonTitle(myData.listingPersonTitle);
						setPhone(myData.phone);
						setPrice(myData.price);
						setRegion(myData.region);
						setTitle(myData.title);
						setWebSite(myData.webSite);
						setpostId(postId);

						var current_datetime = new Date(myData.createDate);
						setFormattedDate(
							current_datetime.getDate() +
								'-' +
								months[current_datetime.getMonth()] +
								'-' +
								current_datetime.getFullYear()
						);
						function createMarkup() {
							return {
								__html: myData.body,
							};
						}
						setBodyFormatted(<div dangerouslySetInnerHTML={createMarkup()} />);
					});
				} catch (err) {
					console.log(err);
					alert('something went wrong...');
				}
			}
		};
		if (foo !== '' && foo !== null && foo !== undefined) {
			getPost(foo);
		}
		console.log(foo);
	}, []);

	return (
		<div>
			<GridContainer justify="center">
				<GridItem xs={12} sm={12} md={8}>
					<Button
						className={classes.fab}
						color="success"
						simple
						justIcon
						onClick={() => navigateToEdit(postId, category)}
					>
						Edit Post
					</Button>
					<h3 className={classes.pageSubcategoriesTitle}>{title}</h3>
					{!approved ? (
						<p className={classes.notApproved}>Not Approved Yet</p>
					) : null}
					<br />

					<NavPills
						color="warning"
						alignCenter
						tabs={[
							{
								tabButton: 'Description',
								tabIcon: Info,
								tabContent: (
									<Card>
										<CardHeader>
											<h4 className={classes.cardTitle}>Description</h4>
											<h5 className={classes.cardTitle}>{category}</h5>
											<p className={classes.cardCategory}>
												{listingPerson} {listingPersonTitle}
											</p>
										</CardHeader>
										<CardBody>
											on: {formattedDate}
											<br />
											<strong>{businessName}</strong>
											<br />
											<br />
											Address: {address}
											<br />
											City: {city}
											<br />
											Region: {region}
											<br />
											Telephone: {phone}
											<br />
											{email}
											<br />
											{webSite}
										</CardBody>
									</Card>
								),
							},
							{
								tabButton: 'Post',
								tabIcon: LocationOn,
								tabContent: (
									<Card>
										<CardHeader>
											<h4 className={classes.cardTitle}>Post</h4>
											<h5 className={classes.cardTitle}>{title}</h5>
											<p className={classes.cardCategory}>{price}</p>
										</CardHeader>
										<CardBody>{bodyFormatted}</CardBody>
									</Card>
								),
							},
							{
								tabButton: 'Gallery',
								tabIcon: InsertPhoto,
								tabContent: (
									<Card>
										<CardHeader>
											<h4 className={classes.cardTitle}>Photo Gallery</h4>
											<p className={classes.cardCategory}>&nbsp;</p>
										</CardHeader>
										<CardBody>
											{image !== '' ? <img src={image} width="500" /> : null}
											{image !== '' ? <br /> : null}
											{image !== '' ? <hr /> : null}
											{image2 !== '' ? <img src={image2} width="500" /> : null}
											{image2 !== '' ? <br /> : null}
											{image2 !== '' ? <hr /> : null}
											{image3 !== '' ? <img src={image3} width="500" /> : null}
											{image3 !== '' ? <br /> : null}
											{image3 !== '' ? <hr /> : null}
											{image4 !== '' ? <img src={image4} width="500" /> : null}
											{image4 !== '' ? <br /> : null}
											{image4 !== '' ? <hr /> : null}
										</CardBody>
									</Card>
								),
							},
						]}
					/>
				</GridItem>
			</GridContainer>
		</div>
	);
}
