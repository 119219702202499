import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { APIurl, APIusersKey } from '../../variables/utils';

import auth from '../../auth';
import Cookies from 'js-cookie';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Router, Route, Redirect, useHistory } from 'react-router-dom';
// react component for creating dynamic tables
import ReactTable from 'react-table';
import MaterialTable from 'material-table';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
import Assignment from '@material-ui/icons/Assignment';
import Dvr from '@material-ui/icons/Dvr';
import Favorite from '@material-ui/icons/Favorite';
import Close from '@material-ui/icons/Close';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardPreview from 'components/Card/CardPreview.js';

import { dataTable } from 'variables/general.js';

import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js';

const styles = {
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
	},
};

const useStyles = makeStyles(styles);

export default function ReactTables() {
	const [myData, setmyData] = React.useState([]);
	const [slatkiAlert, setslatkiAlert] = React.useState(null);
	const history = useHistory();
	const classes = useStyles();

	useEffect(() => {
		const getPosts = () => {
			const myToken = Cookies.get('access_token');
			if (myToken === undefined) {
				history.push('/auth/login-page');
			} else {
				try {
					var sortBy = require('array-sort-by');
					var myPostsUnsorted = [];
					const months = [
						'JAN',
						'FEB',
						'MAR',
						'APR',
						'MAY',
						'JUN',
						'JUL',
						'AUG',
						'SEP',
						'OCT',
						'NOV',
						'DEC',
					];
					// set the url
					const myUrl = `${APIurl}users.json`;
					//alert(myUrl);
					axios.get(myUrl).then((res) => {
						console.log(res.data);
						const myData = res.data;

						for (var key1 in myData) {
							var current_datetime = new Date(myData[key1].createdOn);
							var formatted_date =
								current_datetime.getDate() +
								'-' +
								months[current_datetime.getMonth()] +
								'-' +
								current_datetime.getFullYear();
							var filteredPosts = new Object();
							filteredPosts.active = myData[key1].active;
							filteredPosts.city = myData[key1].city;
							filteredPosts.company = myData[key1].company;
							filteredPosts.firstName = myData[key1].firstName;
							filteredPosts.lastName = myData[key1].lastName;
							filteredPosts.createDate = formatted_date;
							filteredPosts.position = myData[key1].position;
							filteredPosts.userType = myData[key1].userType;
							filteredPosts.userLevel = myData[key1].userLevel;
							filteredPosts.postId = key1;
							filteredPosts.about = myData[key1].about;
							filteredPosts.address = myData[key1].address;
							filteredPosts.displayName = myData[key1].displayName;
							filteredPosts.email = myData[key1].email;
							filteredPosts.photoUrl = myData[key1].photoUrl;
							filteredPosts.telephone = myData[key1].telephone;
							filteredPosts.web = myData[key1].web;
							myPostsUnsorted.push(filteredPosts);
						}
						const myPostsSorted = sortBy(
							myPostsUnsorted,
							(s) => -new Date(s.createDate)
						);
						setmyData(myPostsSorted);
						//console.log('ima....' + JSON.stringify(myPostsSorted));
					});
				} catch (err) {
					console.log(err);
				}
			}
		};
		getPosts();
	}, []);
	const reGetPosts = () => {
		try {
			var sortBy = require('array-sort-by');
			var myPostsUnsorted = [];
			const months = [
				'JAN',
				'FEB',
				'MAR',
				'APR',
				'MAY',
				'JUN',
				'JUL',
				'AUG',
				'SEP',
				'OCT',
				'NOV',
				'DEC',
			];
			// set the url
			const myUrl = `${APIurl}users.json`;
			//alert(myUrl);
			axios.get(myUrl).then((res) => {
				console.log(res.data);
				const myData = res.data;

				for (var key1 in myData) {
					var current_datetime = new Date(myData[key1].createdOn);
					var formatted_date =
						current_datetime.getDate() +
						'-' +
						months[current_datetime.getMonth()] +
						'-' +
						current_datetime.getFullYear();
					var filteredPosts = new Object();
					filteredPosts.active = myData[key1].active;
					filteredPosts.city = myData[key1].city;
					filteredPosts.company = myData[key1].company;
					filteredPosts.firstName = myData[key1].firstName;
					filteredPosts.lastName = myData[key1].lastName;
					filteredPosts.createDate = formatted_date;
					filteredPosts.position = myData[key1].position;
					filteredPosts.userType = myData[key1].userType;
					filteredPosts.userLevel = myData[key1].userLevel;
					filteredPosts.postId = key1;
					filteredPosts.about = myData[key1].about;
					filteredPosts.address = myData[key1].address;
					filteredPosts.displayName = myData[key1].displayName;
					filteredPosts.email = myData[key1].email;
					filteredPosts.photoUrl = myData[key1].photoUrl;
					filteredPosts.telephone = myData[key1].telephone;
					filteredPosts.web = myData[key1].web;
					myPostsUnsorted.push(filteredPosts);
				}
				const myPostsSorted = sortBy(
					myPostsUnsorted,
					(s) => -new Date(s.createDate)
				);
				setmyData(myPostsSorted);
				//console.log('ima....' + JSON.stringify(myPostsSorted));
			});
		} catch (err) {
			console.log(err);
		}
	};

	const [columns, setColums] = useState([
		{ title: 'First Name', field: 'firstName', editable: 'onUpdate' },
		{ title: 'Last Name', field: 'lastName', editable: 'onUpdate' },
		{ title: 'Company', field: 'company', editable: 'onUpdate' },
		{ title: 'Position', field: 'position', editable: 'onUpdate' },
		{ title: 'City', field: 'city', editable: 'never' },
		{ title: 'Created', field: 'createDate', editable: 'never' },
		{
			title: 'User Type',
			field: 'userLevel',
			lookup: { 1: 'Admin', 2: 'Regular User', 3: 'Approved User' },
		},
		{ title: 'Active', field: 'active', type: 'boolean' },
	]);

	const doUpdate = (newData, oldData) => {
		const myToken = Cookies.get('access_token');
		console.log('inside');
		console.log(newData);
		//console.log(oldData);
		if (newData.active) {
			var newActive = 1;
		} else {
			var newActive = 0;
		}
		var newFirstName = newData.firstName;
		var newLastName = newData.lastName;
		var newCompany = newData.company;
		var newPosition = newData.position;
		if (newData.userLevel == 1) {
			var newUserLevel = 1;
			var newUserType = 'Admin User';
		} else if (newData.userLevel == 2) {
			var newUserLevel = 2;
			var newUserType = 'Regular User';
		} else {
			var newUserLevel = 3;
			var newUserType = 'Approved User';
		}
		var recToUpdate = newData.postId;
		var modifyDate = new Date();

		if (myToken === undefined) {
			history.push('/auth/login-page');
		} else {
			try {
				// set the url
				const myUrl = `${APIurl}users/${recToUpdate}.json?auth=${myToken}`;
				// request data object
				const upData = {};
				upData.active = newActive;
				upData.firstName = newFirstName;
				upData.lastName = newLastName;
				upData.company = newCompany;
				upData.position = newPosition;
				upData.userLevel = newUserLevel;
				upData.userType = newUserType;
				upData.modifyDate = modifyDate;

				// set the headers
				const config = {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				};

				axios.patch(myUrl, upData, config).then((res) => {
					console.log('Updated Post finaly!!!!' + res.data);
					reGetPosts();
				});
			} catch (err) {
				console.log('Error Updating Post!!!!' + err);
				reGetPosts();
			}
		}
	};
	const doDelete = (delData) => {
		const myToken = Cookies.get('access_token');
		var recToUpdate = delData.postId;
		if (myToken === undefined) {
			history.push('/auth/login-page');
		} else {
			try {
				// set the url
				const myUrl = `${APIurl}users/${recToUpdate}.json?auth=${myToken}`;
				// request data object
				axios.delete(myUrl).then((res) => {
					console.log('Deleted Post finaly!!!!' + res.data);
					reGetPosts();
				});
			} catch (err) {
				console.log('Error Adding Post!!!!' + err);
				reGetPosts();
			}
		}
	};

	const openSesame = (myData) => {
		setslatkiAlert(
			<SweetAlert
				style={{ display: 'block', marginTop: '-350px' }}
				title=""
				onConfirm={() => hideAlert()}
				onCancel={() => hideAlert()}
				confirmBtnCssClass={classes.button + ' ' + classes.success}
			>
				<Card profile>
					<CardPreview profile>
						<img src={myData.photoUrl} alt="..." />
					</CardPreview>
				</Card>
				<Card>
					<CardBody>
						{myData.displayName},
						<br />
						<br />
						<strong>User Type: {myData.userType}</strong>
						<br />
						{myData.firstName}, {myData.lastName}
						<br />
						<br />
						Address: {myData.address}, {myData.city}
						<br />
						Telephone: {myData.telephone}
						<br />
						Email: {myData.email}
						<br />
						Web: {myData.web}
						<br />
					</CardBody>
				</Card>
				<Card>
					<CardBody>
						<strong>Company:{myData.company}</strong>
						<br />
						Position: {myData.position}
						<br />
						<br />
						About:
						<br />
						{myData.about}
					</CardBody>
				</Card>
			</SweetAlert>
		);
	};
	const hideAlert = () => {
		setslatkiAlert(null);
	};
	return (
		<div>
			{slatkiAlert}
			<GridContainer>
				<GridItem xs={12}>
					<Card>
						<CardHeader color="primary" icon>
							<CardIcon color="primary">
								<Assignment />
							</CardIcon>
							<h4 className={classes.cardIconTitle}>Users</h4>
						</CardHeader>
						<CardBody>
							<MaterialTable
								title="Please Edit Users"
								columns={columns}
								data={myData}
								editable={{
									onRowUpdate: (newData, oldData) =>
										new Promise((resolve, reject) => {
											setTimeout(() => {
												//const dataUpdate = [...data];
												//const index = oldData.tableData.id;
												//dataUpdate[index] = newData;
												//setData([...dataUpdate]);
												doUpdate(newData, oldData);
												resolve();
											}, 1500);
										}),
									onRowDelete: (oldData) =>
										new Promise((resolve, reject) => {
											setTimeout(() => {
												//const dataDelete = [...data];
												//const index = oldData.tableData.id;
												//dataDelete.splice(index, 1);
												//setData([...dataDelete]);
												doDelete(oldData);
												resolve();
											}, 1000);
										}),
								}}
								options={{
									headerStyle: {
										backgroundColor: '#01579b',
										color: '#FFF',
									},
									rowStyle: {
										fontSize: -2,
									},
								}}
								onRowClick={(event, rowData, togglePanel) =>
									openSesame(rowData)
								}
							/>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		</div>
	);
}
