import React from 'react';
import axios from 'axios';
import { APIurl, APIusersKey } from '../../../variables/utils';
import PropTypes from 'prop-types';

import { Router, Route, Redirect, useHistory } from 'react-router-dom';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

// core components
import CustomInput from 'components/CustomInput/CustomInput.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle.js';

const style = {
	infoText: {
		fontWeight: '300',
		margin: '10px 0 30px',
		textAlign: 'center',
	},
	imagePreview: {
		textAlign: 'right',
	},

	...customSelectStyle,
};

class Step3 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			previewAvatar1: null,
			previewAvatar2: null,
			previewAvatar3: null,
			previewAvatar4: null,
			uploadPhoto1: null,
			uploadPhoto2: null,
			uploadPhoto3: null,
			uploadPhoto4: null,
			galerryMode: 'insert',
			ImagesWarning: '',
		};
	}
	//### Is it insert or Update...
	getParameterByName = (name, url) => {
		if (!url) url = window.location.href;
		name = name.replace(/[\[\]]/g, '\\$&');
		var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	};
	componentDidMount() {
		const foo = this.getParameterByName('item');
		if (foo !== '' && foo !== null && foo !== undefined) {
			this.getPost(foo);
		}
	}

	getPost = (postId) => {
		try {
			// set the url
			const myUrl = `${APIurl}products/${postId}.json`;

			axios.get(myUrl).then((res) => {
				console.log(res.data);
				const myData = res.data;
				this.setState({
					galerryMode: 'update',
					ImagesWarning:
						'If you want to change gallery, all photos would be reflected!',
				});
				if (
					myData.image !== '' &&
					myData.image !== null &&
					myData.image !== undefined
				) {
					this.setState({
						previewAvatar1: myData.image,
					});
				}
				if (
					myData.image2 !== '' &&
					myData.image2 !== null &&
					myData.image2 !== undefined
				) {
					this.setState({
						previewAvatar2: myData.image2,
					});
				}
				if (
					myData.image3 !== '' &&
					myData.image3 !== null &&
					myData.image3 !== undefined
				) {
					this.setState({
						previewAvatar3: myData.image3,
					});
				}
				if (
					myData.image4 !== '' &&
					myData.image4 !== null &&
					myData.image4 !== undefined
				) {
					this.setState({
						previewAvatar4: myData.image4,
					});
				}
			});
		} catch (err) {
			console.log(err);
			alert('something went wrong...');
		}
	};

	sendState() {
		return this.state;
	}
	handleSimple = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};
	isValidated() {
		if (this.state.galerryMode === 'update') {
			return true;
		} else {
			if (
				this.state.uploadPhoto1 !== null ||
				this.state.uploadPhoto2 !== null ||
				this.state.uploadPhoto3 !== null ||
				this.state.uploadPhoto4 !== null
			) {
				return true;
			} else {
				alert('Please add at least one image!');
			}
		}
		return false;
	}

	handlePhoto1 = (event) => {
		event.preventDefault();
		let file = event.target.files[0];
		let previewFile = URL.createObjectURL(file);
		this.setState({
			previewAvatar1: previewFile,
			uploadPhoto1: file,
		});
	};
	handlePhoto2 = (event) => {
		event.preventDefault();
		let file = event.target.files[0];
		let previewFile = URL.createObjectURL(file);
		this.setState({
			previewAvatar2: previewFile,
			uploadPhoto2: file,
		});
	};
	handlePhoto3 = (event) => {
		event.preventDefault();
		let file = event.target.files[0];
		let previewFile = URL.createObjectURL(file);
		this.setState({
			previewAvatar3: previewFile,
			uploadPhoto3: file,
		});
	};
	handlePhoto4 = (event) => {
		event.preventDefault();
		let file = event.target.files[0];
		let previewFile = URL.createObjectURL(file);
		this.setState({
			previewAvatar4: previewFile,
			uploadPhoto4: file,
		});
	};

	render() {
		const { classes } = this.props;
		return (
			<GridContainer justify="center">
				<GridItem xs={12} sm={12}>
					<h4 className={classes.infoText}>
						Please upload up to two images for your post!
					</h4>
					<h5 className={classes.infoText}>{this.state.ImagesWarning}</h5>
				</GridItem>
				<GridItem xs={12} sm={12}>
					<h5 className={classes.infoText}>Image 1:</h5>
				</GridItem>
				<GridItem xs={12} sm={5}>
					<div className={classes.center}>
						<input type="file" onChange={this.handlePhoto1} />
					</div>
				</GridItem>
				<GridItem xs={12} sm={5}>
					<div className={classes.imagePreview}>
						<img src={this.state.previewAvatar1} width="80" height="80" />
					</div>
				</GridItem>
				<GridItem xs={12} sm={12}>
					<h5 className={classes.infoText}>Image 2:</h5>
				</GridItem>
				<GridItem xs={12} sm={5}>
					<div className={classes.center}>
						<input type="file" onChange={this.handlePhoto2} />
					</div>
				</GridItem>
				<GridItem xs={12} sm={5}>
					<div className={classes.imagePreview}>
						<img src={this.state.previewAvatar2} width="80" height="80" />
					</div>
				</GridItem>
				{/* 
				<GridItem xs={12} sm={12}>
					<h5 className={classes.infoText}>Image 3:</h5>
				</GridItem>
				<GridItem xs={12} sm={5}>
					<div className={classes.center}>
						<input type="file" onChange={this.handlePhoto3} />
					</div>
				</GridItem>
				<GridItem xs={12} sm={5}>
					<div className={classes.imagePreview}>
						<img src={this.state.previewAvatar3} width="80" height="80" />
					</div>
				</GridItem>
				<GridItem xs={12} sm={12}>
					<h5 className={classes.infoText}>Image 4:</h5>
				</GridItem>
				<GridItem xs={12} sm={5}>
					<div className={classes.center}>
						<input type="file" onChange={this.handlePhoto4} />
					</div>
				</GridItem>
				<GridItem xs={12} sm={5}>
					<div className={classes.imagePreview}>
						<img src={this.state.previewAvatar4} width="80" height="80" />
					</div>
				</GridItem>
				*/}
			</GridContainer>
		);
	}
}

Step3.propTypes = {
	classes: PropTypes.object,
};

export default withStyles(style)(Step3);
