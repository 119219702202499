import React from 'react';
import auth from '../../auth';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardAvatar from 'components/Card/CardAvatar.js';
import CardFooter from 'components/Card/CardFooter.js';

import avatar from 'assets/img/faces/avatar.jpg';

import styles from 'assets/jss/material-dashboard-pro-react/views/lockScreenPageStyle.js';

const useStyles = makeStyles(styles);

export default function LockScreenPage() {
	const [cardAnimaton, setCardAnimation] = React.useState('cardHidden');
	setTimeout(function() {
		setCardAnimation('');
	}, 700);
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<form>
				<Card
					profile
					className={classes.customCardClass + ' ' + classes[cardAnimaton]}
				>
					<CardAvatar profile className={classes.cardAvatar}>
						<a href="#pablo" onClick={(e) => e.preventDefault()}>
							<img src={avatar} alt="..." />
						</a>
					</CardAvatar>
					<CardBody profile>
						<h4 className={classes.cardTitle}>Tania Andrew</h4>

						<h5 className={classes.cardTitle}>Are you sure!</h5>
					</CardBody>
					<CardFooter className={classes.justifyContentCenter}>
						<Button
							color="rose"
							round
							onClick={() => {
								auth.logout(() => {
									//props.history.push('/');
								});
							}}
						>
							Logout
						</Button>
					</CardFooter>
				</Card>
			</form>
		</div>
	);
}
