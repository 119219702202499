import React from 'react';
import axios from 'axios';
import { APIurl, APIusersKey } from '../../variables/utils';
import Cookies from 'js-cookie';
import {
	Router,
	Route,
	Redirect,
	withRouter,
	useHistory,
} from 'react-router-dom';

import PropTypes from 'prop-types';
// @material-ui/icons
import Face from '@material-ui/icons/Face';
import Receipt from '@material-ui/icons/Receipt';
import Room from '@material-ui/icons/Room';
import Phone from '@material-ui/icons/Phone';
import LocationOn from '@material-ui/icons/LocationOn';
import RssFeed from '@material-ui/icons/RssFeed';
import RecordVoiceOver from '@material-ui/icons/RecordVoiceOver';
import Email from '@material-ui/icons/Email';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import PictureUpload from 'components/CustomUpload/PictureUpload.js';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Clearfix from 'components/Clearfix/Clearfix.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardText from 'components/Card/CardText.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import MailOutline from '@material-ui/icons/MailOutline';

const style = {
	infoText: {
		fontWeight: '300',
		margin: '10px 0 30px',
		textAlign: 'center',
	},
	inputAdornmentIcon: {
		color: '#555',
	},
	inputAdornment: {
		position: 'relative',
	},
	imagePreview: {
		textAlign: 'right',
	},
};

class Region extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			gridOppacity: 1,
			pageMode: 'insert',
			updateId: null,
			active: 0,
			title: '',
			titleState: '',
			registerCity: '',
			cityData: '',
			cityState: '',
			previewAvatar: null,
			uploadPhoto: null,
			oldImage: null,
			myId: '',
		};
	}

	//### Is it insert or Update...
	getParameterByName = (name, url) => {
		if (!url) url = window.location.href;
		name = name.replace(/[\[\]]/g, '\\$&');
		var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	};

	//TRY TO GET REGIONS......
	componentDidMount() {
		const foo = this.getParameterByName('item');
		if (foo !== '' && foo !== null && foo !== undefined) {
			this.setState({
				pageMode: 'edit',
				updateId: foo,
			});
			this.getPost(foo);
		}
		this.GetCities();
	}

	handleSubmit = (e) => {
		const myToken = Cookies.get('access_token');
		if (myToken === undefined) {
			this.props.history.push('/auth/login-page');
		} else {
			e.preventDefault();
			var action = this.state.pageMode;
			if (action === 'insert') {
				if (
					this.state.titleState === 'success' &&
					this.state.uploadPhoto !== null &&
					this.state.registerCity !== ''
				) {
					this.doInsert();
				} else {
					if (this.state.titleState !== 'success') {
						this.setState({ titleState: 'error' });
					}
					//e.preventDefault();
					alert('Please fill all fields');
				}
				//this.doInsert(event);
			} else {
				if (
					this.state.titleState === 'success' &&
					this.state.registerCity !== ''
				) {
					this.doUpdate();
				} else {
					if (this.state.titleState !== 'success') {
						this.setState({ titleState: 'error' });
					}
					//e.preventDefault();
					alert('Please fill all fields');
				}
				//this.doUpdate(event);
			}
		}
	};
	doUpdate = () => {
		this.setState({ gridOppacity: '0.2' });
		var myToken = Cookies.get('access_token');
		var city = this.state.registerCity;
		var regionName = this.state.title;
		var updateId = this.state.updateId;
		var uploadPhotoFile = this.state.uploadPhoto;
		var oldImage = this.state.oldImage;
		var createDate = new Date();
		var cityId = '';
		var regionImage = this.state.oldImage;
		//Check if Image changed.....

		const doSomePhotos = () => {
			var imageToCheck = '';
			if (oldImage !== '' && oldImage !== undefined) {
				var res1 = oldImage.replace('https://solelylocal.imgix.net/', '');
				var res2 = res1.replace('?fit=crop&w=840&q=80', '');
				if (res2 !== 'PortAlberni840.jpg') {
					imageToCheck = res2;
				}
			}
			if (imageToCheck !== '') {
				//##### DELETING IMAGE ###########
				try {
					var base_url = 'https://www.solelylocal.com/deleteImage.php';
					var fd = new FormData();
					fd.append('delete_file', imageToCheck);
					axios.post(base_url, fd).then((res) => {
						console.log(res);
						console.log('IMA STATUS....' + res.data.status);
						getCityId();
					});
				} catch (err) {
					//console.error(err);
					console.log(
						'There was an error deleting file from web server: ' + err
					);
					getCityId();
				}
				//###### END DELETING IMAGE ############################
			} else {
				getCityId();
			}
		};
		const getCityId = () => {
			console.log('GET CITY ID....');
			//#############   CITY DATA from DB #########################
			try {
				// set the url
				const myUrl = `${APIurl}tabs/categories.json?orderBy="title"&equalTo="${city}"`;
				axios.get(myUrl).then((res) => {
					//console.log(res.data);
					const myData = res.data;
					for (var key1 in myData) {
						for (var key2 in myData[key1]) {
							//console.log('display....' + myData[key1].id);
							cityId = myData[key1].id;
						}
					}
					if (uploadPhotoFile !== '' && uploadPhotoFile !== null) {
						uploadPhoto();
					} else {
						updateRegion();
					}
				});
			} catch (err) {
				console.log(err);
			}
		};
		const uploadPhoto = () => {
			//##### UPLOADING IMAGE ###########
			try {
				var base_url = 'https://www.solelylocal.com/uploadImage.php';
				var fd = new FormData();
				fd.append('avatar', uploadPhotoFile, 'region.jpg');
				axios.post(base_url, fd).then((res) => {
					console.log(res);
					if (res.data.status === 'success') {
						// finally image uploaded.....
						let fileConstruct =
							'https://solelylocal.imgix.net/' +
							res.data.fileName +
							'?fit=crop&w=840&q=80';
						regionImage = fileConstruct;
						console.log('to upload....' + regionImage);
					} else {
						// there was an error with file upload... revert to default...
						console.log('No error but no image either......');
					}
					updateRegion();
				});
			} catch (err) {
				//console.error(err);
				console.log(
					'There was an error uploading file to the web server: ' + err
				);
				updateRegion();
			}
			//###### END UPLOADING IMAGE ############################
		};
		const updateRegion = () => {
			//Make Post....
			console.log('Posting Region....');
			try {
				// set the url
				const myUrl = `${APIurl}regions/${updateId}.json?auth=${myToken}`;
				// request data object
				const data = {
					createDate: createDate,
					image: regionImage,
					tab: city,
					tabId: cityId,
					title: regionName,
				};
				// set the headers
				const config = {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				};
				axios.patch(myUrl, data, config).then((res) => {
					console.log('Added Post finaly!!!!' + res.data);
					//Make region active.....fieldset-reset
					this.props.history.push('/admin/regions');
					//history.push('/admin/regions');
				});
			} catch (err) {
				console.log('Error Adding Post!!!!' + err);
				this.props.history.push('/admin/regions');
				//history.push('/admin/regions');
			}
		};
		if (uploadPhotoFile !== '' && uploadPhotoFile !== null) {
			doSomePhotos();
		} else {
			getCityId();
		}
	};

	doInsert = () => {
		this.setState({ gridOppacity: '0.2' });
		var myToken = Cookies.get('access_token');
		//var history = useHistory();
		var city = this.state.registerCity;
		var regionName = this.state.title;
		var active = this.state.active;
		var uploadPhotoFile = this.state.uploadPhoto;
		var createDate = new Date();
		var cityId = '';
		var regionImage =
			'https://solelylocal.imgix.net/PortAlberni840.jpg?fit=crop&w=840&q=80';
		var myId = '';
		var genId = regionName.split(' ').join('_');
		var myRnd = Math.floor(Math.random() * 1001);
		myId = genId + '_' + myRnd;
		//Get City Data.....
		const getCityId = (city) => {
			console.log('GET CITY ID....');
			//#############   CITY DATA from DB #########################
			try {
				// set the url
				const myUrl = `${APIurl}tabs/categories.json?orderBy="title"&equalTo="${city}"`;
				axios.get(myUrl).then((res) => {
					//console.log(res.data);
					const myData = res.data;
					for (var key1 in myData) {
						for (var key2 in myData[key1]) {
							//console.log('display....' + myData[key1].id);
							cityId = myData[key1].id;
						}
					}
					uploadPhoto();
				});
			} catch (err) {
				console.log(err);
			}
		};
		const uploadPhoto = () => {
			//##### UPLOADING IMAGE ###########
			try {
				var base_url = 'https://www.solelylocal.com/uploadImage.php';
				var fd = new FormData();
				fd.append('avatar', uploadPhotoFile, 'region.jpg');
				axios.post(base_url, fd).then((res) => {
					console.log(res);
					if (res.data.status === 'success') {
						// finally image uploaded.....
						let fileConstruct =
							'https://solelylocal.imgix.net/' +
							res.data.fileName +
							'?fit=crop&w=840&q=80';
						regionImage = fileConstruct;
						console.log('to upload....' + regionImage);
					} else {
						// there was an error with file upload... revert to default...
						console.log('No error but no image either......');
					}
					UploadRegion();
				});
			} catch (err) {
				//console.error(err);
				console.log(
					'There was an error uploading file to the web server: ' + err
				);
				UploadRegion();
			}
			//###### END UPLOADING IMAGE ############################
		};
		const UploadRegion = () => {
			//Make Post....
			console.log('Posting Region....');
			try {
				// set the url
				const myUrl = `${APIurl}regions.json?auth=${myToken}`;
				// request data object
				const data = {
					active: active,
					createDate: createDate,
					id: myId,
					image: regionImage,
					tab: city,
					tabId: cityId,
					title: regionName,
				};
				// set the headers
				const config = {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				};
				axios.post(myUrl, data, config).then((res) => {
					console.log('Added Post finaly!!!!' + res.data);
					//Make region active.....fieldset-reset
					this.props.history.push('/admin/regions');
					//history.push('/admin/regions');
				});
			} catch (err) {
				console.log('Error Adding Post!!!!' + err);
				this.props.history.push('/admin/regions');
				//history.push('/admin/regions');
			}
		};
		getCityId(city);
	};

	getPost = (postId) => {
		const myToken = Cookies.get('access_token');
		if (myToken === undefined) {
			this.props.history.push('/auth/login-page');
		} else {
			try {
				// set the url
				const myUrl = `${APIurl}regions/${postId}.json`;
				axios.get(myUrl).then((res) => {
					console.log(res.data);
					const myData = res.data;
					this.setState({
						active: myData.active,
						title: myData.title,
						titleState: 'success',
						oldImage: myData.image,
						previewAvatar: myData.image,
						registerCity: myData.tab,
						myId: myData.id,
					});
				});
			} catch (err) {
				console.log(err);
				alert('something went wrong...');
			}
		}
	};
	GetCities = () => {
		const myToken = Cookies.get('access_token');
		if (myToken === undefined) {
			this.props.history.push('/auth/login-page');
		} else {
			try {
				// set the url
				const myUrl = `${APIurl}tabs.json`;
				const arrayData = [];

				axios.get(myUrl).then((res) => {
					//console.log(res.data);
					const myData = res.data;
					for (var key1 in myData) {
						for (var key2 in myData[key1]) {
							if ([key1] == 'categories') {
								//console.log(myData[key1][key2]);
								arrayData.push(
									<MenuItem key={key2} value={myData[key1][key2].title}>
										{myData[key1][key2].title}
									</MenuItem>
								);
							}
						}
					}
					this.setState({
						cityData: arrayData,
					});
				});
			} catch (err) {
				console.log(err);
				alert('something went wrong...');
			}
		}
	};
	handlePhoto = (event) => {
		event.preventDefault();
		let file = event.target.files[0];
		let previewFile = URL.createObjectURL(file);
		this.setState({
			previewAvatar: previewFile,
			uploadPhoto: file,
		});
	};

	sendState() {
		return this.state;
	}

	// function that verifies if a string has a given length or not
	verifyLength(value, length) {
		if (value.length >= length) {
			return true;
		}
		return false;
	}
	// function that verifies if value contains only numbers

	change(event, stateName, type, stateNameEqualTo) {
		switch (type) {
			case 'email':
				if (this.verifyEmail(event.target.value)) {
					this.setState({ [stateName + 'State']: 'success' });
				} else {
					this.setState({ [stateName + 'State']: 'error' });
				}
				break;
			case 'phone':
				if (this.verifyNumber(event.target.value)) {
					this.setState({ [stateName + 'State']: 'success' });
				} else {
					this.setState({ [stateName + 'State']: 'error' });
				}
				break;
			case 'web':
				if (this.verifyUrl(event.target.value)) {
					this.setState({ [stateName + 'State']: 'success' });
				} else {
					this.setState({ [stateName + 'State']: 'error' });
				}
				break;
			case 'length':
				if (this.verifyLength(event.target.value, stateNameEqualTo)) {
					this.setState({ [stateName + 'State']: 'success' });
				} else {
					this.setState({ [stateName + 'State']: 'error' });
				}
				break;
			default:
				break;
		}
		this.setState({ [stateName]: event.target.value });
	}
	handleSimple = (event) => {
		this.setState({
			registerCity: event.target.value,
		});
	};
	render() {
		const { classes } = this.props;
		return (
			<form className={classes.form} onSubmit={this.handleSubmit}>
				<GridContainer
					justify="center"
					style={{ opacity: this.state.gridOppacity }}
				>
					<GridItem xs={12} sm={12} md={8}>
						<Card>
							<CardHeader color="rose" icon>
								<CardIcon color="rose">
									<LocationOn />
								</CardIcon>
								<h4 className={classes.cardIconTitle}>Register Forms</h4>
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={12} sm={12}>
										<h4 className={classes.infoText}>Please Add Region!</h4>
									</GridItem>

									<GridItem xs={12} sm={12}>
										<CustomInput
											success={this.state.titleState === 'success'}
											error={this.state.titleState === 'error'}
											labelText={
												<span>
													Region Name <small>(required)</small>
												</span>
											}
											id="title"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: (event) =>
													this.change(event, 'title', 'length', 3),
												endAdornment: (
													<InputAdornment
														position="end"
														className={classes.inputAdornment}
													>
														<LocationOn
															className={classes.inputAdornmentIcon}
														/>
													</InputAdornment>
												),
												value: this.state.title,
											}}
										/>

										<FormControl
											fullWidth
											style={{ backgroundColor: '#f9f9f9', marginLeft: 0 }}
											className={classes.selectFormControl}
										>
											<InputLabel
												htmlFor="registerCity"
												className={classes.selectLabel}
											>
												Choose City
											</InputLabel>

											<Select
												MenuProps={{
													className: classes.selectMenu,
												}}
												classes={{
													select: classes.select,
												}}
												value={this.state.registerCity}
												onChange={this.handleSimple}
												inputProps={{
													name: 'registerCity',
													id: 'registerCity',
												}}
											>
												<MenuItem
													disabled
													classes={{
														root: classes.selectMenuItem,
													}}
												>
													Choose City
												</MenuItem>
												{this.state.cityData}
											</Select>
										</FormControl>
									</GridItem>
									<GridItem xs={12} sm={12}>
										<h5 className={classes.infoText}>Image:</h5>
									</GridItem>
								</GridContainer>

								<GridContainer
									justify="space-between"
									alignItems="center"
									direction="row"
								>
									<GridItem xs={12} sm={6} md={6} lg={3}>
										<div className={classes.center}>
											<input type="file" onChange={this.handlePhoto} />
										</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={6} lg={3}>
										<div className={classes.imagePreview}>
											<img
												src={this.state.previewAvatar}
												width="100"
												height="100"
											/>
										</div>
									</GridItem>
								</GridContainer>
								<Button
									type="submit"
									color="rose"
									className={classes.updateProfileButton}
								>
									Save
								</Button>
								<Clearfix />
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</form>
		);
	}
}

Region.propTypes = {
	classes: PropTypes.object,
};

export default withStyles(style)(Region);
