import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { APIurl, APIusersKey } from '../../variables/utils';

import auth from '../../auth';
import Cookies from 'js-cookie';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Router, Route, Redirect, useHistory } from 'react-router-dom';
// react component for creating dynamic tables
import ReactTable from 'react-table';
import MaterialTable from 'material-table';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
import Assignment from '@material-ui/icons/Assignment';
import Dvr from '@material-ui/icons/Dvr';
import Favorite from '@material-ui/icons/Favorite';
import Close from '@material-ui/icons/Close';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardPreview from 'components/Card/CardPreview.js';

import { dataTable } from 'variables/general.js';

import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js';

const styles = {
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
	},
};

const useStyles = makeStyles(styles);

export default function ReactTables() {
	const [myPostData, setmyPostData] = React.useState([]);
	const [columns, setColums] = React.useState([]);
	const [slatkiAlert, setslatkiAlert] = React.useState(null);
	const history = useHistory();
	const classes = useStyles();

	useEffect(() => {
		const getPosts = () => {
			const myToken = Cookies.get('access_token');
			if (myToken === undefined) {
				history.push('/auth/login-page');
			} else {
				try {
					var sortBy = require('array-sort-by');
					var myPostsUnsorted = [];
					const months = [
						'JAN',
						'FEB',
						'MAR',
						'APR',
						'MAY',
						'JUN',
						'JUL',
						'AUG',
						'SEP',
						'OCT',
						'NOV',
						'DEC',
					];
					// set the url
					const myUrl = `${APIurl}products.json?orderBy="approved"&equalTo=1`;
					//alert(myUrl);
					axios.get(myUrl).then((res) => {
						console.log(res.data);
						const myData = res.data;

						for (var key1 in myData) {
							var current_datetime = new Date(myData[key1].modifyDate);
							var formatted_date =
								current_datetime.getDate() +
								'-' +
								months[current_datetime.getMonth()] +
								'-' +
								current_datetime.getFullYear();
							var filteredPosts = new Object();
							filteredPosts.category = myData[key1].category;
							filteredPosts.businessName = myData[key1].businessName;
							filteredPosts.title = myData[key1].title;
							filteredPosts.listingPersonId = myData[key1].listingPersonId;
							filteredPosts.listingPerson = myData[key1].listingPerson;
							filteredPosts.createDate = formatted_date;
							filteredPosts.listingPersonTitle =
								myData[key1].listingPersonTitle;
							filteredPosts.approved = myData[key1].approved;
							filteredPosts.att = myData[key1].att;
							filteredPosts.postId = key1;
							filteredPosts.att = myData[key1].att;
							filteredPosts.city = myData[key1].city;
							filteredPosts.address = myData[key1].address;
							filteredPosts.email = myData[key1].email;
							filteredPosts.phone = myData[key1].phone;
							filteredPosts.webSite = myData[key1].webSite;
							filteredPosts.region = myData[key1].region;
							filteredPosts.price = myData[key1].price;
							filteredPosts.body = myData[key1].body;
							filteredPosts.stars = myData[key1].stars;
							function createMarkup() {
								return {
									__html: myData[key1].body,
								};
							}
							filteredPosts.bodyFormatted = (
								<div dangerouslySetInnerHTML={createMarkup()} />
							);
							filteredPosts.image = myData[key1].image;
							filteredPosts.image2 = myData[key1].image2;
							filteredPosts.image3 = myData[key1].image3;
							filteredPosts.image4 = myData[key1].image4;

							myPostsUnsorted.push(filteredPosts);
						}
						const myPostsSorted = sortBy(
							myPostsUnsorted,
							(s) => -new Date(s.createDate)
						);
						setmyPostData(myPostsSorted);
						//console.log('ima....POSTS:' + JSON.stringify(myPostsSorted));
						getUsers();
					});
				} catch (err) {
					console.log(err);
				}
			}
		};
		const getUsers = () => {
			const myToken = Cookies.get('access_token');
			if (myToken === undefined) {
				history.push('/auth/login-page');
			} else {
				try {
					// set the url
					const myUrl = `${APIurl}users.json?orderBy="active"&equalTo=1`;
					//alert(myUrl);
					axios.get(myUrl).then((res) => {
						console.log(res.data);
						const myUserData = res.data;
						var userResult = new Object();
						for (var key1 in myUserData) {
							userResult[myUserData[key1].userUid] =
								myUserData[key1].firstName + ' ' + myUserData[key1].lastName;
						}

						console.log('ima....USERS:' + JSON.stringify(userResult));
						setMyColums(userResult);
					});
				} catch (err) {
					console.log(err);
				}
			}
		};
		const setMyColums = (RecUsers) => {
			console.log('TRACING....USERS in Columns:' + JSON.stringify(RecUsers));
			setColums([
				{ title: 'Category', field: 'category', editable: 'never' },
				{ title: 'Business', field: 'businessName', editable: 'never' },
				{ title: 'Title', field: 'title', editable: 'never' },
				//{ title: 'Posted by', field: 'listingPersonId', editable: 'never' },
				{
					title: 'Posted by',
					field: 'listingPersonId',
					/*
					lookup: {
						DaChZYtatmVUXskSSzg9GTMsh4J3: 'Peter Andri',
						A1AtLoKhnwctu7Wwxtv85sLwDGj2: 'Christine Wood',
						E1NE6Ed8xIVxGMiYFXmaS9XtSvX2: 'Will Smith',
					},
					*/
					lookup: RecUsers,
				},

				{ title: 'Created', field: 'createDate', editable: 'never' },
				{
					title: 'Size',
					field: 'att',
					lookup: { '': 'Regular', horizontal: 'Horizontal', full: 'Large' },
				},
				{
					title: 'Stars',
					field: 'stars',
					lookup: { '': '0', 5: '5', 4: '4', 3: '3', 2: '2', 1: '1' },
				},
				{ title: 'Approved', field: 'approved', type: 'boolean' },
			]);
		};
		getPosts();
	}, []);
	const reGetPosts = () => {
		try {
			var sortBy = require('array-sort-by');
			var myPostsUnsorted = [];
			const months = [
				'JAN',
				'FEB',
				'MAR',
				'APR',
				'MAY',
				'JUN',
				'JUL',
				'AUG',
				'SEP',
				'OCT',
				'NOV',
				'DEC',
			];
			// set the url
			const myUrl = `${APIurl}products.json?orderBy="approved"&equalTo=1`;
			//alert(myUrl);
			axios.get(myUrl).then((res) => {
				console.log(res.data);
				const myData = res.data;

				for (var key1 in myData) {
					var current_datetime = new Date(myData[key1].modifyDate);
					var formatted_date =
						current_datetime.getDate() +
						'-' +
						months[current_datetime.getMonth()] +
						'-' +
						current_datetime.getFullYear();
					var filteredPosts = new Object();
					filteredPosts.category = myData[key1].category;
					filteredPosts.businessName = myData[key1].businessName;
					filteredPosts.title = myData[key1].title;
					filteredPosts.listingPersonId = myData[key1].listingPersonId;
					filteredPosts.listingPerson = myData[key1].listingPerson;
					filteredPosts.createDate = formatted_date;
					filteredPosts.listingPersonTitle = myData[key1].listingPersonTitle;
					filteredPosts.approved = myData[key1].approved;
					filteredPosts.att = myData[key1].att;
					filteredPosts.postId = key1;
					filteredPosts.att = myData[key1].att;
					filteredPosts.city = myData[key1].city;
					filteredPosts.address = myData[key1].address;
					filteredPosts.email = myData[key1].email;
					filteredPosts.phone = myData[key1].phone;
					filteredPosts.webSite = myData[key1].webSite;
					filteredPosts.region = myData[key1].region;
					filteredPosts.price = myData[key1].price;
					filteredPosts.body = myData[key1].body;
					filteredPosts.stars = myData[key1].stars;
					function createMarkup() {
						return {
							__html: myData[key1].body,
						};
					}
					filteredPosts.bodyFormatted = (
						<div dangerouslySetInnerHTML={createMarkup()} />
					);
					filteredPosts.image = myData[key1].image;
					filteredPosts.image2 = myData[key1].image2;
					filteredPosts.image3 = myData[key1].image3;
					filteredPosts.image4 = myData[key1].image4;

					myPostsUnsorted.push(filteredPosts);
				}
				const myPostsSorted = sortBy(
					myPostsUnsorted,
					(s) => -new Date(s.createDate)
				);
				setmyPostData(myPostsSorted);
				//console.log('ima....' + JSON.stringify(myPostsSorted));
			});
		} catch (err) {
			console.log(err);
		}
	};
	const reDoRegions = () => {
		var myPostsApproved = [];
		var uniqMyPostsApproved = [];
		try {
			// set the url
			const myUrl = `${APIurl}products.json?orderBy="approved"&equalTo=1`;
			//alert(myUrl);
			axios.get(myUrl).then((res) => {
				//console.log(res.data);
				const myData = res.data;
				for (var key1 in myData) {
					myPostsApproved.push(myData[key1].region);
				}
				uniqMyPostsApproved = [...new Set(myPostsApproved)];
				console.log(uniqMyPostsApproved);
				reDoRegions2(uniqMyPostsApproved);
			});
		} catch (err) {
			console.log(err);
			alert('something went wrong...');
		}
	};

	const reDoRegions2 = (posts) => {
		const myToken = Cookies.get('access_token');
		try {
			// set the url
			const myUrl = `${APIurl}regions.json`;
			//alert(myUrl);
			axios.get(myUrl).then((res) => {
				//console.log(res.data);
				const myData = res.data;
				for (var key1 in myData) {
					var currRec = key1;
					if (posts.includes(myData[key1].title)) {
						try {
							// set the url
							const myUrl = `${APIurl}regions/${currRec}.json?auth=${myToken}`;
							// request data object
							const upData = {};
							upData.active = 1;

							// set the headers
							const config = {
								headers: {
									'Content-Type': 'application/x-www-form-urlencoded',
								},
							};

							axios.patch(myUrl, upData, config).then((res) => {
								console.log('Updated Region to TRUE ...');
							});
						} catch (err) {
							console.log('Error Updating Post!!!!' + err);
						}
					} else {
						try {
							// set the url
							const myUrl = `${APIurl}regions/${currRec}.json?auth=${myToken}`;
							// request data object
							const upData = {};
							upData.active = 0;

							// set the headers
							const config = {
								headers: {
									'Content-Type': 'application/x-www-form-urlencoded',
								},
							};

							axios.patch(myUrl, upData, config).then((res) => {
								console.log('Updated Region to FALSE...');
							});
						} catch (err) {
							console.log('Error Updating Post!!!!' + err);
						}
					}
				}
				reGetPosts();
			});
		} catch (err) {
			console.log(err);
			alert('something went wrong...');
		}
	};

	const [Tcolumns, setTColums] = useState([
		{ title: 'Category', field: 'category', editable: 'never' },
		{ title: 'Business', field: 'businessName', editable: 'never' },
		{ title: 'Title', field: 'title', editable: 'never' },
		//{ title: 'Poster', field: 'listingPerson', editable: 'never' },
		{ title: 'Created', field: 'createDate', editable: 'never' },
		{
			title: 'Size',
			field: 'att',
			lookup: { '': 'Regular', horizontal: 'Horizontal', full: 'Large' },
		},
		{
			title: 'Stars',
			field: 'stars',
			lookup: { '': '0', 5: '5', 4: '4', 3: '3', 2: '2', 1: '1' },
		},
		{ title: 'Approved', field: 'approved', type: 'boolean' },
	]);

	const getUserById = (newData, oldData) => {
		const myToken = Cookies.get('access_token');
		var newListingPersonId = newData.listingPersonId;
		if (myToken === undefined) {
			history.push('/auth/login-page');
		} else {
			try {
				// set the url
				const myUrl = `${APIurl}users.json?orderBy="userUid"&equalTo="${newListingPersonId}"`;
				//alert(myUrl);
				axios.get(myUrl).then((res) => {
					console.log(res.data);
					const myUserData = res.data;
					var userResult = new Object();
					for (var key1 in myUserData) {
						userResult.listingPerson =
							myUserData[key1].firstName + ' ' + myUserData[key1].lastName;
						userResult.listingPersonAvatar = myUserData[key1].photoUrl;
						userResult.listingPersonId = myUserData[key1].userUid;

						doUpdate(
							newData,
							oldData,
							myUserData[key1].firstName + ' ' + myUserData[key1].lastName,
							myUserData[key1].photoUrl
						);
					}

					//console.log('ima....USERS:' + JSON.stringify(myUserData));
					//doUpdate(newData, oldData, userResult);
				});
			} catch (err) {
				console.log(err);
			}
		}
	};
	const doUpdate = (newData, oldData, userDataName, userDataAvatar) => {
		const myToken = Cookies.get('access_token');
		//console.log(newData);
		//console.log(oldData);
		if (newData.approved) {
			var newApproved = 1;
		} else {
			var newApproved = 0;
		}
		var newListingPerson = userDataName;
		var newListingPersonAvatar = userDataAvatar;
		var newAtt = newData.att;
		var newStars = newData.stars;
		var newListingPersonId = newData.listingPersonId;
		var recToUpdate = newData.postId;
		var modifyDate = new Date();

		if (myToken === undefined) {
			history.push('/auth/login-page');
		} else {
			try {
				// set the url
				const myUrl = `${APIurl}products/${recToUpdate}.json?auth=${myToken}`;
				// request data object
				const upData = {};
				upData.approved = newApproved;
				upData.att = newAtt;
				upData.stars = newStars;
				upData.listingPerson = newListingPerson;
				upData.listingPersonAvatar = newListingPersonAvatar;
				upData.listingPersonId = newListingPersonId;
				upData.modifyDate = modifyDate;

				// set the headers
				const config = {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				};

				axios.patch(myUrl, upData, config).then((res) => {
					console.log('Updated Post finaly!!!!' + JSON.stringify(res.data));
					reDoRegions();
					reGetPosts();
				});
			} catch (err) {
				console.log('Error Updating Post!!!!' + err);
				reGetPosts();
			}
		}
	};
	const doDelete = (delData) => {
		const myToken = Cookies.get('access_token');
		var recToUpdate = delData.postId;
		if (myToken === undefined) {
			history.push('/auth/login-page');
		} else {
			try {
				// set the url
				const myUrl = `${APIurl}products/${recToUpdate}.json?auth=${myToken}`;
				// request data object
				axios.delete(myUrl).then((res) => {
					console.log('Deleted Post finaly!!!!' + res.data);
					reDoRegions();
					reGetPosts();
				});
			} catch (err) {
				console.log('Error Adding Post!!!!' + err);
				reGetPosts();
			}
		}
	};

	const openSesame = (myData) => {
		setslatkiAlert(
			<SweetAlert
				style={{ display: 'block', marginTop: '-350px' }}
				title=""
				onConfirm={() => hideAlert()}
				onCancel={() => hideAlert()}
				confirmBtnCssClass={classes.button + ' ' + classes.success}
			>
				<Card profile>
					<CardPreview profile>
						<img src={myData.image} alt="..." />
						<img src={myData.image2} alt="..." />
						<img src={myData.image3} alt="..." />
						<img src={myData.image4} alt="..." />
					</CardPreview>
				</Card>
				<Card>
					<CardBody>
						On: {myData.createDate}
						<br />
						<strong>Business:{myData.businessName}</strong>
						<br />
						By: {myData.listingPerson}: {myData.listingPersonTitle}
						<br />
						Address: {myData.address}, {myData.city}, {myData.region}
						<br />
						Telephone: {myData.phone}, {myData.email}
						<br />
						{myData.webSite}
					</CardBody>
				</Card>
				<Card>
					<CardBody>
						{myData.title}
						<br />
						<strong>{myData.price}</strong>
						<br />
						{myData.bodyFormatted}
						<br />
					</CardBody>
				</Card>
			</SweetAlert>
		);
	};
	const hideAlert = () => {
		setslatkiAlert(null);
	};
	return (
		<div>
			{slatkiAlert}
			<GridContainer>
				<GridItem xs={12}>
					<Card>
						<CardHeader color="primary" icon>
							<CardIcon color="primary">
								<Assignment />
							</CardIcon>
							<h4 className={classes.cardIconTitle}>Live Posts</h4>
						</CardHeader>
						<CardBody>
							<MaterialTable
								title="Please Delete or Unapprove Posts"
								columns={columns}
								data={myPostData}
								editable={{
									onRowUpdate: (newData, oldData) =>
										new Promise((resolve, reject) => {
											setTimeout(() => {
												//const dataUpdate = [...data];
												//const index = oldData.tableData.id;
												//dataUpdate[index] = newData;
												//setData([...dataUpdate]);
												getUserById(newData, oldData);
												resolve();
											}, 1500);
										}),
									onRowDelete: (oldData) =>
										new Promise((resolve, reject) => {
											setTimeout(() => {
												//const dataDelete = [...data];
												//const index = oldData.tableData.id;
												//dataDelete.splice(index, 1);
												//setData([...dataDelete]);
												doDelete(oldData);
												resolve();
											}, 1000);
										}),
								}}
								options={{
									headerStyle: {
										backgroundColor: '#01579b',
										color: '#FFF',
									},
									rowStyle: {
										fontSize: -2,
									},
								}}
								onRowClick={(event, rowData, togglePanel) =>
									openSesame(rowData)
								}
							/>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		</div>
	);
}
