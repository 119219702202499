import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { APIurl, APIusersKey } from '../../variables/utils';

import auth from '../../auth';
import Cookies from 'js-cookie';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Router, Route, Redirect, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Close from '@material-ui/icons/Close';

// @material-ui/icons
import PermIdentity from '@material-ui/icons/PermIdentity';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Clearfix from 'components/Clearfix/Clearfix.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardAvatar from 'components/Card/CardAvatar.js';

import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js';

import avatar from 'assets/img/faces/marc.jpg';

const useStyles = makeStyles(styles);

export default function UserProfile() {
	// register form
	const [registerEmail, setregisterEmail] = React.useState('');
	const [registerEmailState, setregisterEmailState] = React.useState('');

	const [registerConfirmEmail, setregisterConfirmEmail] = React.useState('');
	const [
		registerConfirmEmailState,
		setregisterConfirmEmailState,
	] = React.useState('');

	const [registerPassword, setregisterPassword] = React.useState('');
	const [registerPasswordState, setregisterPasswordState] = React.useState('');

	const [registerConfirmPassword, setregisterConfirmPassword] = React.useState(
		''
	);
	const [
		registerConfirmPasswordState,
		setregisterConfirmPasswordState,
	] = React.useState('');

	const [registerUserName, setregisterUserName] = React.useState('');
	const [registerUserNameState, setregisterUserNameState] = React.useState('');

	const [registerMessage, setregisterMessage] = React.useState('');
	const [registerMessageState, setregisterMessageState] = React.useState('');

	const [registerUID, setregisterUID] = React.useState('');
	const [registerUserId, setregisterUserId] = React.useState('');

	const refImage = React.createRef();
	const history = useHistory();

	const classes = useStyles();

	// function that returns true if value is email, false otherwise
	const verifyEmail = (value) => {
		var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (emailRex.test(value)) {
			return true;
		}
		return false;
	};
	// function that verifies if a string has a given length or not
	const verifyLength = (value, length) => {
		if (value.length >= length) {
			return true;
		}
		return false;
	};
	// function that verifies if value contains only numbers
	const verifyNumber = (value) => {
		var numberRex = new RegExp('^[0-9]+$');
		if (numberRex.test(value)) {
			return true;
		}
		return false;
	};
	// verifies if value is a valid URL
	const verifyUrl = (value) => {
		try {
			new URL(value);
			return true;
		} catch (_) {
			return false;
		}
	};

	//## Get some user info here from DB.....
	useEffect(() => {
		const getUserDetails = (userId) => {
			try {
				// set the url
				const myUrl = `${APIurl}users.json?orderBy="userUid"&equalTo="${userId}"`;

				axios.get(myUrl).then((res) => {
					console.log(res.data);
					const myData = res.data;
					for (var key1 in myData) {
						for (var key2 in myData[key1]) {
							//console.log(myData[key1]);
							setregisterEmail(myData[key1].email);
							setregisterUserName(myData[key1].displayName);
							setregisterUID(myData[key1].userUid);
							setregisterUserId([key1]);
						}
					}
				});
			} catch (err) {
				console.log(err);
				alert('something went wrong...');
			}
		};

		//#### get user from token...
		const GetUserId = () => {
			const myToken = Cookies.get('access_token');
			const myID = Cookies.get('access_id');
			if (myToken === undefined) {
				history.push('/auth/login-page');
			} else {
				getUserDetails(myID);
			}
		};
		GetUserId();
	}, []);
	const getUserDetails2 = (userId) => {
		try {
			// set the url
			const myUrl = `${APIurl}users.json?orderBy="userUid"&equalTo="${userId}"`;

			axios.get(myUrl).then((res) => {
				console.log(res.data);
				const myData = res.data;
				for (var key1 in myData) {
					for (var key2 in myData[key1]) {
						//console.log(myData[key1]);
						setregisterEmail(myData[key1].email);
						setregisterUserName(myData[key1].displayName);
						setregisterUID(myData[key1].userUid);
						setregisterUserId([key1]);
					}
				}
			});
		} catch (err) {
			console.log(err);
			alert('something went wrong...');
		}
	};
	function handleSubmit(event) {
		event.preventDefault();
		const myToken = Cookies.get('access_token');
		if (myToken === undefined) {
			history.push('/auth/login-page');
		} else {
			var valid = false;
			var submittedUsername = registerUserName;
			var submittedEmail = registerEmail;
			var submittedConfirmEmail = registerConfirmEmail;
			var submittedPassword = registerPassword;
			var submittedConfirmPassword = registerConfirmPassword;
			var submittedUserId = registerUserId;

			if (
				submittedUsername !== '' &&
				submittedEmail !== '' &&
				submittedConfirmEmail !== '' &&
				submittedPassword !== '' &&
				submittedConfirmPassword !== ''
			) {
				if (
					submittedPassword.length > 6 &&
					submittedConfirmPassword.length > 6 &&
					submittedPassword === submittedConfirmPassword &&
					submittedEmail === submittedConfirmEmail
				) {
					valid = true;
				} else {
					setregisterMessageState('error');
					setregisterMessage(
						'There was an error updating your credentials (your confirm password or email is not matching)!'
					);
				}
			} else {
				setregisterMessageState('error');
				setregisterMessage(
					'There was an error updating your credentials (all fields must be filled in)!'
				);
			}
			if (valid) {
				// try to Update user....
				console.log('Try to Update....');

				// try to refresh token here.........
				const reSignInUser = async (refToken) => {
					try {
						// set the url
						const url = `https://securetoken.googleapis.com/v1/token?key=${APIusersKey}`;
						// request data object
						const data = {
							grant_type: 'refresh_token',
							refresh_token: refToken,
						};

						// set the headers
						const config = {
							headers: {
								'Content-Type': 'application/json',
							},
						};

						const res = await axios.post(url, data, config);
						console.log(res.data);
						if (
							res.data.access_token !== '' &&
							res.data.refresh_token !== '' &&
							res.data.user_id !== '' &&
							res.data.expires_in !== ''
						) {
							try {
								const expires = (res.data.expiresIn || 60 * 60) * 1000;
								//const expires = 10000;
								const inOneHour = new Date(new Date().getTime() + expires);

								// you will have the exact same setters in your Login page/app too
								Cookies.set('access_token', res.data.access_token, {
									expires: inOneHour,
								});
								Cookies.set('access_id', res.data.user_id, {
									expires: inOneHour,
								});
								Cookies.set('refresh_token', res.data.refresh_token);
								updateUserCredentials();
							} catch (error) {
								console.log(error);
								setregisterMessageState('error');
								setregisterMessage(
									'There was an error updating your credentials (refreshing tokens)!'
								);
							}
						} else {
							setregisterMessageState('error');
							setregisterMessage(
								'There was an error updating your credentials (refreshing tokens)!'
							);
						}
					} catch (err) {
						console.log(err);
						setregisterMessageState('error');
						setregisterMessage(
							'There was an error updating your credentials (refreshing tokens)!'
						);
					}
				};

				const updateUserCredentials = async () => {
					try {
						const myToken = Cookies.get('access_token');
						//Re-validate Token first....

						// set the url
						const url = `https://identitytoolkit.googleapis.com/v1/accounts:update?key=${APIusersKey}`;
						// request data object
						const data = {
							idToken: myToken,
							email: submittedEmail,
							displayName: submittedUsername,
							password: submittedPassword,
							returnSecureToken: true,
						};
						// set the headers
						const config = {
							headers: {
								'Content-Type': 'application/json',
							},
						};
						const res = await axios.post(url, data, config);
						console.log(res.data);
						if (res.data.refreshToken !== '') {
							try {
								Cookies.set('refresh_token', res.data.refreshToken);
								updateUser();
							} catch (error) {
								console.log(error);
								auth.logout();
								history.push('/auth/login-page');
								setregisterMessageState('error');
								setregisterMessage(
									'There was an error updating your credentials (Credentials to old, please re-login)!'
								);
							}
						} else {
							auth.logout();
							history.push('/auth/login-page');
							setregisterMessageState('error');
							setregisterMessage(
								'There was an error updating your credentials (Credentials to old, please re-login)!'
							);
						}
					} catch (err) {
						console.log('error From create user.....' + err);
						auth.logout();
						history.push('/auth/login-page');
						setregisterMessageState('error');
						setregisterMessage(
							'There was an error updating your credentials (Credentials to old, please re-login)!'
						);
						//getUserDetails2(submittedUserId);
					}
				};

				const updateUser = async () => {
					try {
						const myToken = Cookies.get('access_token');
						// set the url
						const url = `${APIurl}users/${submittedUserId}.json?auth=${myToken}`;
						// request data object
						const data = {
							modifyDate: new Date(),
							email: submittedEmail,
							displayName: submittedUsername,
						};

						// set the headers
						const config = {
							headers: {
								'Content-Type': 'application/x-www-form-urlencoded',
							},
						};
						const res = await axios.patch(url, data, config);
						console.log('result: ' + JSON.stringify(res));
						setregisterMessageState('success');
						setregisterMessage(
							'You have successfully updated your credentials!'
						);
						getUserDetails2(submittedUserId);
					} catch (err) {
						console.log('error From create user.....' + err);
						setregisterMessageState('error');
						setregisterMessage(
							'There was an error updating your credentials (updating user data)!'
						);
						getUserDetails2(submittedUserId);
					}
				};

				const GetUserRefreshToken = () => {
					const myRefreshToken = Cookies.get('refresh_token');
					//const myID = Cookies.get('access_id');
					if (myRefreshToken === undefined) {
						history.push('/auth/login-page');
					} else {
						reSignInUser(myRefreshToken);
						//getUserDetails(myID);
					}
				};
				GetUserRefreshToken();
			} else {
				setregisterMessageState('error');
				setregisterMessage(
					'There was an error updating your credentials (your confirm password or email is not matching)!'
				);
			}
		}
	}

	return (
		<div>
			<form className={classes.form} onSubmit={handleSubmit}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={10}>
						<Card>
							<CardHeader color="rose" icon>
								<CardIcon color="rose">
									<PermIdentity />
								</CardIcon>
								<h4 className={classes.cardIconTitle}>
									Update Credentials -{' '}
									<small>Change your email, password and username</small>
								</h4>
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={12} sm={12} md={6}>
										<CustomInput
											labelText="Username *"
											success={registerUserNameState === 'success'}
											error={registerUserNameState === 'error'}
											id="registerusername"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: (event) => {
													if (verifyLength(event.target.value, 3)) {
														setregisterUserNameState('success');
													} else {
														setregisterUserNameState('error');
													}
													setregisterUserName(event.target.value);
												},
												value: registerUserName,
												endAdornment:
													registerUserNameState === 'error' ? (
														<InputAdornment position="end">
															<Close className={classes.danger} />
														</InputAdornment>
													) : (
														undefined
													),
											}}
										/>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={12} sm={12} md={6}>
										<CustomInput
											labelText="New Email *"
											success={registerEmailState === 'success'}
											error={registerEmailState === 'error'}
											id="registeremail"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: (event) => {
													if (verifyEmail(event.target.value)) {
														setregisterEmailState('success');
													} else {
														setregisterEmailState('error');
													}
													setregisterEmail(event.target.value);
												},
												value: registerEmail,
												endAdornment:
													registerEmailState === 'error' ? (
														<InputAdornment position="end">
															<Close className={classes.danger} />
														</InputAdornment>
													) : (
														undefined
													),
											}}
										/>
									</GridItem>
									<GridItem xs={12} sm={12} md={6}>
										<CustomInput
											labelText="Confirm New Email *"
											success={registerConfirmEmailState === 'success'}
											error={registerConfirmEmailState === 'error'}
											id="registerconfirmemail"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: (event) => {
													if (registerEmail === event.target.value) {
														setregisterConfirmEmailState('success');
													} else {
														setregisterConfirmEmailState('error');
													}
													setregisterConfirmEmail(event.target.value);
												},
												endAdornment:
													registerConfirmEmailState === 'error' ? (
														<InputAdornment position="end">
															<Close className={classes.danger} />
														</InputAdornment>
													) : (
														undefined
													),
											}}
										/>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={12} sm={12} md={6}>
										<CustomInput
											labelText="New Password *"
											success={registerPasswordState === 'success'}
											error={registerPasswordState === 'error'}
											id="registerpassword"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: (event) => {
													if (verifyLength(event.target.value, 7)) {
														setregisterPasswordState('success');
													} else {
														setregisterPasswordState('error');
													}
													setregisterPassword(event.target.value);
												},
												type: 'password',
												autoComplete: 'off',
												endAdornment:
													registerPasswordState === 'error' ? (
														<InputAdornment position="end">
															<Close className={classes.danger} />
														</InputAdornment>
													) : (
														undefined
													),
											}}
										/>
									</GridItem>
									<GridItem xs={12} sm={12} md={6}>
										<CustomInput
											labelText="Confirm New Password *"
											success={registerConfirmPasswordState === 'success'}
											error={registerConfirmPasswordState === 'error'}
											id="registerconfirmpassword"
											formControlProps={{
												fullWidth: true,
											}}
											inputProps={{
												onChange: (event) => {
													if (registerPassword === event.target.value) {
														setregisterConfirmPasswordState('success');
													} else {
														setregisterConfirmPasswordState('error');
													}
													setregisterConfirmPassword(event.target.value);
												},
												type: 'password',
												autoComplete: 'off',
												endAdornment:
													registerConfirmPasswordState === 'error' ? (
														<InputAdornment position="end">
															<Close className={classes.danger} />
														</InputAdornment>
													) : (
														undefined
													),
											}}
										/>
									</GridItem>
								</GridContainer>
								<GridContainer>
									<GridItem xs={12} sm={12} md={12}>
										{registerMessageState === 'success' ? (
											<h5 className={classes.mySuccess}>{registerMessage}</h5>
										) : (
											<h5 className={classes.myError}>{registerMessage}</h5>
										)}
									</GridItem>
								</GridContainer>
								<div className={classes.formCategory}>
									<small>*</small> Required fields
								</div>
								<Button
									type="submit"
									color="rose"
									className={classes.updateProfileButton}
								>
									Update Credentials
								</Button>
								<Clearfix />
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</form>
		</div>
	);
}
