//######### STAGING
export const APIurl = 'https://solelylocalmobile.firebaseio.com/';
export const APIusersKey = 'AIzaSyBMirwWwfDOUSN02l-z4bF9DhmgCmOAD0U';
export const externalURL = 'https://www.solelylocal.com/staging';
export const subTitle = 'STAGING';
//######### LIVE
//export const APIurl = 'https://solelylocalcovid.firebaseio.com/';
//export const APIusersKey = 'AIzaSyA7l2dD4nbZTKQ8xaq7iho4nhVPYXMqbQA';
//export const externalURL = 'https://www.solelylocal.com/post';
//export const subTitle = '';
