import React from 'react';
import Cookies from 'js-cookie';

class Auth {
	constructor() {
		this.authenticated = false;
	}

	login() {
		//login user here.....
		this.authenticated = true;
	}

	logout() {
		Cookies.remove('refresh_token');
		Cookies.remove('access_token');
		this.authenticated = false;
	}

	isAuthenticated() {
		//const getAccessToken = () => Cookies.get('access_token');
		//const getRefreshToken = () => Cookies.get('refresh_token');
		//this.authenticated = () => getAccessToken();
		const getAccessToken = Cookies.get('access_token');
		const getRefreshToken = Cookies.get('refresh_token');
		if (
			getAccessToken !== '' &&
			getAccessToken !== undefined &&
			getRefreshToken !== '' &&
			getRefreshToken !== undefined
		) {
			this.authenticated = true;
			//console.log('Token is:....' + getAccessToken);
			//console.log('Refresh is:....' + getRefreshToken);
		}
		console.log('Authenticated is:....' + this.authenticated);
		return this.authenticated;
	}
}

export default new Auth();
