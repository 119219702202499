import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { APIurl, APIusersKey } from '../../variables/utils';

import auth from '../../auth';
import Cookies from 'js-cookie';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Router, Route, Redirect, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';

// @material-ui/icons
import Timeline from '@material-ui/icons/Timeline';
import Code from '@material-ui/icons/Code';
import Group from '@material-ui/icons/Group';
import Face from '@material-ui/icons/Face';
import Email from '@material-ui/icons/Email';
// import AccountCircleOutlined from "@material-ui/icons/AccountCircleOutlined";
// import AccountBoxOutlined from "@material-ui/icons/AccountBoxOutlined";
import Contacts from '@material-ui/icons/Contacts';
import Phone from '@material-ui/icons/Phone';
import LocationOn from '@material-ui/icons/LocationOn';
import RssFeed from '@material-ui/icons/RssFeed';
import Check from '@material-ui/icons/Check';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import InfoArea from 'components/InfoArea/InfoArea.js';
import ImageUpload from 'components/CustomUpload/ImageUpload.js';
import PictureUpload from 'components/CustomUpload/PictureUpload.js';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';

import styles from 'assets/jss/material-dashboard-pro-react/views/registerPageStyle';

const useStyles = makeStyles(styles);

export default function RegisterPage() {
	const [checked, setChecked] = React.useState([]);
	const [registeredCitySelect, setregisteredCitySelect] = React.useState('');
	// register form
	const [registerEmail, setregisterEmail] = React.useState('');
	const [registerEmailState, setregisterEmailState] = React.useState('');
	const [registerPassword, setregisterPassword] = React.useState('');
	const [registerPasswordState, setregisterPasswordState] = React.useState('');
	const [registerUsername, setregisterUsername] = React.useState('');
	const [registerUsernameState, setregisterUsernameState] = React.useState('');
	const [registerFirstName, setregisterFirstName] = React.useState('');
	const [registerFirstNameState, setregisterFirstNameState] = React.useState(
		''
	);
	const [registerLastName, setregisterLastName] = React.useState('');
	const [registerLastNameState, setregisterLastNameState] = React.useState('');
	const [registerAddress, setregisterAddress] = React.useState('');
	const [registerAddressState, setregisterAddressState] = React.useState('');
	const [registerTelephone, setregisterTelephone] = React.useState('');
	const [registerTelephoneState, setregisterTelephoneState] = React.useState(
		''
	);
	const [registerWeb, setregisterWeb] = React.useState('');
	const [registerWebState, setregisterWebState] = React.useState('');

	const [cityData, setcityData] = React.useState([]);

	const [registerCity, setregisterCity] = React.useState('');
	const [registerCityId, setregisterCityId] = React.useState('');
	const [registerCityState, setregisterCityState] = React.useState('');
	const [registerAvatar, setrregisterAvatar] = React.useState(null);
	const [alert, setAlert] = React.useState(null);
	const refImage = React.createRef();
	const history = useHistory();
	const classes = useStyles();

	const handleToggle = (value) => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}
		setChecked(newChecked);
	};
	const handleSimple = (event) => {
		setregisterCity(event.target.value);
	};
	const handleAvatar = (event) => {
		event.preventDefault();
		let file = event.target.files[0];
		setrregisterAvatar(file);
	};

	// function that returns true if value is email, false otherwise
	const verifyEmail = (value) => {
		var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (emailRex.test(value)) {
			return true;
		}
		return false;
	};
	// function that verifies if a string has a given length or not
	const verifyLength = (value, length) => {
		if (value.length >= length) {
			return true;
		}
		return false;
	};
	// function that verifies if value contains only numbers
	const verifyNumber = (value) => {
		var numberRex = new RegExp('^[0-9]+$');
		if (numberRex.test(value)) {
			return true;
		}
		return false;
	};
	// verifies if value is a valid URL
	const verifyUrl = (value) => {
		try {
			new URL(value);
			return true;
		} catch (_) {
			return false;
		}
	};
	const registerClick = () => {
		if (registerEmailState === '') {
			setregisterEmailState('error');
		}
		if (registerPasswordState === '') {
			setregisterPasswordState('error');
		}
	};
	// this is alert for validation....
	const warningAlert = () => {
		setAlert(
			<SweetAlert
				warning
				style={{ backgroundColor: '#e91e63' }}
				confirmBtnText="Try Again!"
				confirmBtnBsStyle="warning"
				btnSize="lg"
				title="Please Verify your Email and Password!"
				onConfirm={() => hideAlert()}
				onCancel={() => hideAlert()}
			>
				Something went wrong....
			</SweetAlert>
		);
	};
	const hideAlert = () => {
		setAlert(null);
	};
	// END this is alert for validation....
	// ######### HANDLE SUBMIT ################
	function handleSubmit(event) {
		event.preventDefault();

		var emailtest = /\S+@\S+\.\S+/;
		var valid = false;
		var submittedEmail = registerEmail;
		var submittedPassword = registerPassword;
		var submittedFirstname = registerFirstName;
		var submittedLastname = registerLastName;
		var submittedUsername = registerUsername;
		var submittedAddress = registerAddress;
		var submittedTelephone = registerTelephone;
		var submittedWeb = registerWeb;
		var submittedCity = registerCity;
		var submittedAvatar = registerAvatar;
		var myAvatar =
			'https://solelylocal.imgix.net/avatar.jpg?fit=crop&w=840&q=80';
		/*
		console.log(
			'submittedEmail:' +
				submittedEmail +
				'submittedPassword:' +
				submittedPassword +
				'submittedFirstname:' +
				submittedFirstname +
				'submittedLastname:' +
				submittedLastname +
				'submittedUsername:' +
				submittedUsername +
				'submittedAddress:' +
				submittedAddress +
				'submittedTelephone:' +
				submittedTelephone +
				'submittedWeb:' +
				submittedWeb +
				'submittedCity:' +
				submittedCity
    );
    */

		if (
			emailtest.test(submittedEmail) &&
			submittedEmail !== '' &&
			submittedPassword !== '' &&
			submittedUsername !== '' &&
			submittedFirstname !== '' &&
			submittedLastname !== ''
		) {
			valid = true;
		}
		if (valid) {
			// try to login user....
			console.log('Try to login....');
			const updateUser = async (myUid, myToken) => {
				try {
					// set the url
					const url = `${APIurl}users.json?auth=${myToken}`;
					// request data object
					const data = {
						userUid: myUid,
						displayName: submittedUsername,
						photoUrl: myAvatar,
						userType: 'General User',
						about: '',
						active: 1,
						userLevel: 2,
						createdOn: new Date(),
						modifyDate: new Date(),
						firstName: submittedFirstname,
						lastName: submittedLastname,
						company: '',
						position: '',
						address: submittedAddress,
						city: submittedCity,
						region: '',
						email: submittedEmail,
						telephone: submittedTelephone,
						web: submittedWeb,
					};

					// set the headers
					const config = {
						headers: {
							'Content-Type': 'application/x-www-form-urlencoded',
						},
					};
					const res = await axios.post(url, data, config);
					console.log('result: ' + res);
					//alert('.....PASSSS....');
					history.push('/admin/dashboard');
				} catch (err) {
					console.log('error From create user.....' + err);
					//alert('.....EROR....');
					history.push('/admin/dashboard');
				}
			};

			const signInUser = async () => {
				try {
					// set the url
					const url = `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${APIusersKey}`;
					// request data object
					const data = {
						email: submittedEmail,
						password: submittedPassword,
						returnSecureToken: true,
					};

					// set the headers
					const config = {
						headers: {
							'Content-Type': 'application/json',
						},
					};

					const res = await axios.post(url, data, config);
					console.log(res.data);

					if (
						res.data.idToken !== '' &&
						res.data.refreshToken !== '' &&
						res.data.expiresIn !== '' &&
						res.data.localId !== ''
					) {
						var newlyCreatedUserId = res.data.localId;
						var newlyCreatedUserToken = res.data.idToken;
						try {
							const expires = (res.data.expiresIn || 60 * 60) * 1000;
							const inOneHour = new Date(new Date().getTime() + expires);

							// you will have the exact same setters in your Login page/app too
							Cookies.set('access_token', res.data.idToken, {
								expires: inOneHour,
							});
							Cookies.set('access_id', res.data.localId, {
								expires: inOneHour,
							});
							Cookies.set('refresh_token', res.data.refreshToken);
							auth.login();
							//##### AUTHORISED USER.... NOW UPLOAD IMAGE TO SERVER ########################
							if (
								submittedAvatar !== '' &&
								submittedAvatar !== null &&
								submittedAvatar !== 'undefined'
							) {
								//##### UPLOADING IMAGE ###########
								try {
									// set the uri
									var uploadUri = submittedAvatar;
									var base_url = 'https://www.solelylocal.com/uploadImage.php';
									var fd = new FormData();
									fd.append('avatar', submittedAvatar, 'avatar.jpg');
									axios.post(base_url, fd).then((res) => {
										console.log(res);
										if (res.data.status === 'success') {
											// finally image uploaded.....
											let fileConstruct =
												'https://solelylocal.imgix.net/' +
												res.data.fileName +
												'?fit=crop&w=840&q=80';

											myAvatar = fileConstruct;
											console.log('to upload....' + myAvatar);
											updateUser(newlyCreatedUserId, newlyCreatedUserToken);
										} else {
											// there was an error with file upload... revert to default...
											myAvatar =
												'https://solelylocal.imgix.net/avatar.jpg?fit=crop&w=840&q=80';
											console.log('No error but no image either......');
											updateUser(newlyCreatedUserId, newlyCreatedUserToken);
										}
									});
								} catch (err) {
									//console.error(err);
									console.log(
										'There was an error uploading file to the web server: ' +
											err
									);
									// default image.... proceed
									myAvatar =
										'https://solelylocal.imgix.net/avatar.jpg?fit=crop&w=840&q=80';
									updateUser(newlyCreatedUserId, newlyCreatedUserToken);
								}
								//###### END UPLOADING IMAGE ############################
							} else {
								//##### NO IMAGE TO UPLOAD#####//#endregionmyAvatar =
								myAvatar =
									'https://solelylocal.imgix.net/avatar.jpg?fit=crop&w=840&q=80';
								updateUser(newlyCreatedUserId, newlyCreatedUserToken);
							}

							//history.push('/admin/dashboard');
						} catch (error) {
							warningAlert();
							setregisterPassword('');
						}
					}
				} catch (err) {
					//console.error(err);
					warningAlert();
					setregisterPassword('');
					console.log(err);
				}
			};
			signInUser();
		} else {
			warningAlert();
			setregisterPassword('');
		}
		console.log('valid is:  ' + valid);
	}

	useEffect(() => {
		const GetCities = () => {
			try {
				// set the url
				const myUrl = `${APIurl}tabs.json`;
				const arrayData = [];

				axios.get(myUrl).then((res) => {
					console.log(res.data);
					const myData = res.data;
					for (var key1 in myData) {
						for (var key2 in myData[key1]) {
							if ([key1] == 'categories') {
								console.log(myData[key1][key2]);
								arrayData.push(
									<MenuItem
										key={key2}
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected,
										}}
										value={myData[key1][key2].title}
									>
										{myData[key1][key2].title}
									</MenuItem>
								);
							}
						}
					}
					setcityData(arrayData);
				});
			} catch (err) {
				console.log(err);
				alert('something went wrong...');
			}
		};
		GetCities();
	}, []);

	return (
		<div className={classes.container}>
			{alert}
			<GridContainer justify="center">
				<GridItem xs={12} sm={12} md={10}>
					<form className={classes.form} onSubmit={handleSubmit}>
						<Card className={classes.cardSignup}>
							<h2 className={classes.cardTitle}>Register</h2>
							<CardBody>
								<GridContainer justify="center">
									<GridItem xs={12} sm={8} md={5}>
										<div className={classes.center}>
											<Button justIcon round color="twitter">
												<i className="fab fa-twitter" />
											</Button>
											{` `}
											<Button justIcon round color="dribbble">
												<i className="fab fa-dribbble" />
											</Button>
											{` `}
											<Button justIcon round color="facebook">
												<i className="fab fa-facebook-f" />
											</Button>
											{` `}
											<h4 className={classes.socialTitle}>or be classical</h4>
										</div>

										<CustomInput
											success={registerUsernameState === 'success'}
											error={registerUsernameState === 'error'}
											id="registerusername"
											formControlProps={{
												fullWidth: true,
												className: classes.customFormControlClasses,
											}}
											inputProps={{
												startAdornment: (
													<InputAdornment
														position="start"
														className={classes.inputAdornment}
													>
														<Face className={classes.inputAdornmentIcon} />
													</InputAdornment>
												),
												onChange: (event) => {
													if (verifyLength(event.target.value, 5)) {
														setregisterUsernameState('success');
													} else {
														setregisterUsernameState('error');
													}
													setregisterUsername(event.target.value);
												},
												placeholder: '* Username...',
											}}
										/>
										<CustomInput
											success={registerEmailState === 'success'}
											error={registerEmailState === 'error'}
											id="registeremail"
											formControlProps={{
												fullWidth: true,
												className: classes.customFormControlClasses,
											}}
											inputProps={{
												startAdornment: (
													<InputAdornment
														position="start"
														className={classes.inputAdornment}
													>
														<Email className={classes.inputAdornmentIcon} />
													</InputAdornment>
												),
												onChange: (event) => {
													if (verifyEmail(event.target.value)) {
														setregisterEmailState('success');
													} else {
														setregisterEmailState('error');
													}
													setregisterEmail(event.target.value);
												},
												type: 'email',
												placeholder: '* Email...',
											}}
										/>
										<CustomInput
											success={registerPasswordState === 'success'}
											error={registerPasswordState === 'error'}
											id="registerpassword"
											formControlProps={{
												fullWidth: true,
												className: classes.customFormControlClasses,
											}}
											inputProps={{
												startAdornment: (
													<InputAdornment
														position="start"
														className={classes.inputAdornment}
													>
														<Icon className={classes.inputAdornmentIcon}>
															lock_outline
														</Icon>
													</InputAdornment>
												),
												onChange: (event) => {
													if (verifyLength(event.target.value, 7)) {
														setregisterPasswordState('success');
													} else {
														setregisterPasswordState('error');
													}
													setregisterPassword(event.target.value);
												},
												type: 'password',
												autoComplete: 'off',
												placeholder: '* Password...',
											}}
										/>
										<CustomInput
											success={registerFirstNameState === 'success'}
											error={registerFirstNameState === 'error'}
											id="registerfirstname"
											formControlProps={{
												fullWidth: true,
												className: classes.customFormControlClasses,
											}}
											inputProps={{
												startAdornment: (
													<InputAdornment
														position="start"
														className={classes.inputAdornment}
													>
														<Contacts className={classes.inputAdornmentIcon} />
													</InputAdornment>
												),
												onChange: (event) => {
													if (verifyLength(event.target.value, 2)) {
														setregisterFirstNameState('success');
													} else {
														setregisterFirstNameState('error');
													}
													setregisterFirstName(event.target.value);
												},
												placeholder: '* First Name...',
											}}
										/>
										<CustomInput
											success={registerLastNameState === 'success'}
											error={registerLastNameState === 'error'}
											id="registerlastname"
											formControlProps={{
												fullWidth: true,
												className: classes.customFormControlClasses,
											}}
											inputProps={{
												startAdornment: (
													<InputAdornment
														position="start"
														className={classes.inputAdornment}
													>
														<Group className={classes.inputAdornmentIcon} />
													</InputAdornment>
												),
												onChange: (event) => {
													if (verifyLength(event.target.value, 2)) {
														setregisterLastNameState('success');
													} else {
														setregisterLastNameState('error');
													}
													setregisterLastName(event.target.value);
												},
												placeholder: '* Last Name...',
											}}
										/>
									</GridItem>
									<GridItem xs={12} sm={8} md={5}>
										<div className={classes.center}>
											<img
												src="https://solelylocal.imgix.net/avatar.jpg"
												width="76"
												height="76"
											/>
											<input type="file" onChange={handleAvatar} />
										</div>
										<CustomInput
											success={registerAddressState === 'success'}
											error={registerAddressState === 'error'}
											id="registeraddress"
											formControlProps={{
												fullWidth: true,
												className: classes.customFormControlClasses,
											}}
											inputProps={{
												startAdornment: (
													<InputAdornment
														position="start"
														className={classes.inputAdornment}
													>
														<LocationOn
															className={classes.inputAdornmentIcon}
														/>
													</InputAdornment>
												),
												onChange: (event) => {
													if (verifyLength(event.target.value, 0)) {
														setregisterAddressState('success');
													} else {
														setregisterAddressState('error');
													}
													setregisterAddress(event.target.value);
												},
												placeholder: 'Address...',
											}}
										/>
										<FormControl
											fullWidth
											style={{ backgroundColor: '#f9f9f9', marginLeft: 12 }}
											className={classes.selectFormControl}
										>
											<InputLabel
												htmlFor="registerCity"
												className={classes.selectLabel}
											>
												Choose City
											</InputLabel>

											<Select
												MenuProps={{
													className: classes.selectMenu,
												}}
												classes={{
													select: classes.select,
												}}
												value={registerCity}
												onChange={handleSimple}
												inputProps={{
													name: 'registerCity',
													id: 'registerCity',
												}}
											>
												<MenuItem
													disabled
													classes={{
														root: classes.selectMenuItem,
													}}
												>
													Choose City
												</MenuItem>
												{cityData}
											</Select>
										</FormControl>
										<CustomInput
											success={registerTelephoneState === 'success'}
											error={registerTelephoneState === 'error'}
											id="registertelephone"
											formControlProps={{
												fullWidth: true,
												className: classes.customFormControlClasses,
											}}
											inputProps={{
												startAdornment: (
													<InputAdornment
														position="start"
														className={classes.inputAdornment}
													>
														<Phone className={classes.inputAdornmentIcon} />
													</InputAdornment>
												),
												onChange: (event) => {
													if (verifyLength(event.target.value, 0)) {
														setregisterTelephoneState('success');
													} else {
														setregisterTelephoneState('error');
													}
													setregisterTelephone(event.target.value);
												},
												placeholder: 'Telephone...',
											}}
										/>
										<CustomInput
											success={registerWebState === 'success'}
											error={registerWebState === 'error'}
											id="registerweb"
											formControlProps={{
												fullWidth: true,
												className: classes.customFormControlClasses,
											}}
											inputProps={{
												startAdornment: (
													<InputAdornment
														position="start"
														className={classes.inputAdornment}
													>
														<RssFeed className={classes.inputAdornmentIcon} />
													</InputAdornment>
												),
												onChange: (event) => {
													if (verifyLength(event.target.value, 0)) {
														setregisterWebState('success');
													} else {
														setregisterWebState('error');
													}
													setregisterWeb(event.target.value);
												},
												placeholder: 'Web...',
											}}
										/>

										<FormControlLabel
											classes={{
												root: classes.checkboxLabelControl,
												label: classes.checkboxLabel,
											}}
											control={
												<Checkbox
													tabIndex={-1}
													onClick={() => handleToggle(1)}
													checkedIcon={
														<Check className={classes.checkedIcon} />
													}
													icon={<Check className={classes.uncheckedIcon} />}
													classes={{
														checked: classes.checked,
														root: classes.checkRoot,
													}}
												/>
											}
											label={
												<span>
													I agree to the{' '}
													<a href="#pablo">terms and conditions</a>.
												</span>
											}
										/>
										<div className={classes.center}>
											<Button type="submit" round color="primary">
												Get started
											</Button>
										</div>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</form>
				</GridItem>
			</GridContainer>
		</div>
	);
}
