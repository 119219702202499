import React from 'react';
import axios from 'axios';
import { APIurl, APIusersKey } from '../../../variables/utils';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';

import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import CustomInput from 'components/CustomInput/CustomInput.js';
import InputAdornment from '@material-ui/core/InputAdornment';
import Receipt from '@material-ui/icons/Receipt';
import Book from '@material-ui/icons/Book';
import Event from '@material-ui/icons/Event';

import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle.js';
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js';

const style = {
	infoText: {
		fontWeight: '300',
		margin: '10px 0 30px',
		textAlign: 'center',
	},
	inputAdornmentIcon: {
		color: '#555',
	},
	choiche: {
		textAlign: 'center',
		cursor: 'pointer',
		marginTop: '20px',
	},
	textBox: {
		margin: '5px 0 20px',
		padding: '1px 0 300px',
		border: '0.5px solid black',
	},
	...customSelectStyle,
	...customCheckboxRadioSwitch,
};

class Step2 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			simpleSelect: '',
			posttitle: '',
			posttitleState: '',
			postintro: '',
			postintroState: '',
			postbody: '',
			postbodyState: '',
			editorState: EditorState.createEmpty(),
		};
	}

	//### Is it insert or Update...
	getParameterByName = (name, url) => {
		if (!url) url = window.location.href;
		name = name.replace(/[\[\]]/g, '\\$&');
		var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	};
	//TRY TO GET REGIONS......
	componentDidMount() {
		const foo = this.getParameterByName('item');
		if (foo !== '' && foo !== null && foo !== undefined) {
			this.getPost(foo);
		}
	}

	getPost = (postId) => {
		try {
			// set the url
			const myUrl = `${APIurl}products/${postId}.json`;

			axios.get(myUrl).then((res) => {
				console.log(res.data);
				const myData = res.data;
				this.setState({
					posttitle: myData.title,
					posttitleState: 'success',
					postintro: myData.price,
					postintroState: 'success',
					postbody: myData.body,
					postbodyState: 'success',
				});
				const blocksFromHtml = htmlToDraft(myData.body);
				const { contentBlocks, entityMap } = blocksFromHtml;
				const contentState = ContentState.createFromBlockArray(
					contentBlocks,
					entityMap
				);
				this.setState({
					editorState: EditorState.createWithContent(contentState),
				});
				//const editorState = EditorState.createWithContent(contentState);
			});
		} catch (err) {
			console.log(err);
			alert('something went wrong...');
		}
	};

	onEditorStateChange: Function = (editorState) => {
		this.setState({
			editorState,
		});
	};
	sendState() {
		return this.state;
	}
	// function that verifies if a string has a given length or not
	verifyLength(value, length) {
		if (value.length >= length) {
			return true;
		}
		return false;
	}
	change(event, stateName, type, stateNameEqualTo) {
		switch (type) {
			case 'length':
				if (this.verifyLength(event.target.value, stateNameEqualTo)) {
					this.setState({ [stateName + 'State']: 'success' });
				} else {
					this.setState({ [stateName + 'State']: 'error' });
				}
				break;
			default:
				break;
		}
		this.setState({ [stateName]: event.target.value });
	}

	handleChange = (name) => (event) => {
		this.setState({ [name]: event.target.checked });
	};
	isValidated() {
		if (
			this.state.posttitleState === 'success' &&
			this.state.postintroState === 'success' &&
			this.state.editorState !== ''
		) {
			return true;
		} else {
			if (this.state.posttitleState !== 'success') {
				this.setState({ posttitleState: 'error' });
			}
			if (this.state.postintroState !== 'success') {
				this.setState({ postintroState: 'error' });
			}
		}
		return false;
	}
	render() {
		const { classes } = this.props;
		const { editorState } = this.state;
		return (
			<div>
				<h4 className={classes.infoText}>Now we are ready to add your post!</h4>
				<GridContainer justify="center">
					<GridItem xs={12} sm={12}>
						<CustomInput
							success={this.state.posttitleState === 'success'}
							error={this.state.posttitleState === 'error'}
							labelText={
								<span>
									Post Title <small>(required)</small>
								</span>
							}
							id="posttitle"
							formControlProps={{
								fullWidth: true,
							}}
							inputProps={{
								onChange: (event) =>
									this.change(event, 'posttitle', 'length', 3),
								endAdornment: (
									<InputAdornment
										position="end"
										className={classes.inputAdornment}
									>
										<Event className={classes.inputAdornmentIcon} />
									</InputAdornment>
								),
								value: this.state.posttitle,
							}}
						/>
						<CustomInput
							success={this.state.postintroState === 'success'}
							error={this.state.postintroState === 'error'}
							labelText={
								<span>
									Intro Text (not more than 50 characters){' '}
									<small>(required)</small>
								</span>
							}
							id="postintro"
							formControlProps={{
								fullWidth: true,
							}}
							inputProps={{
								onChange: (event) =>
									this.change(event, 'postintro', 'length', 3),
								endAdornment: (
									<InputAdornment
										position="end"
										className={classes.inputAdornment}
									>
										<Book className={classes.inputAdornmentIcon} />
									</InputAdornment>
								),
								value: this.state.postintro,
							}}
						/>
					</GridItem>
					<GridItem xs={12} sm={12}>
						<h5 className={classes.infoText}>Please add your post!</h5>
						<Editor
							editorState={editorState}
							toolbarClassName="toolbarClassName"
							wrapperClassName="wrapperClassName"
							editorClassName={classes.textBox}
							onEditorStateChange={this.onEditorStateChange}
							toolbar={{
								options: [
									'inline',
									'blockType',
									'fontSize',
									'list',
									'textAlign',
									'link',
								],
							}}
						/>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

Step2.propTypes = {
	classes: PropTypes.object,
};

export default withStyles(style)(Step2);
